import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import history from "../Shared/Redux/history";
import { submitFailed } from "../Shared/Forms/FormActions";
import utils from "../Shared/Utils/utils";
import { getMock } from "./mock";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_LAB_TESTS_REQUEST = "GET_LAB_TESTS_REQUEST";
export const GET_LAB_TESTS_SUCCESS = "GET_LAB_TESTS_SUCCESS";
export const GET_LAB_TESTS_FAILURE = "GET_LAB_TESTS_FAILURE";

export const GET_TENANT_LAB_TESTS_REQUEST = "GET_TENANT_LAB_TESTS_REQUEST";
export const GET_TENANT_LAB_TESTS_SUCCESS = "GET_TENANT_LAB_TESTS_SUCCESS";
export const GET_TENANT_LAB_TESTS_FAILURE = "GET_TENANT_LAB_TESTS_FAILURE";

export const SAVE_TENANT_LAB_TEST_REQUEST = "SAVE_TENANT_LAB_TEST_REQUEST";
export const SAVE_TENANT_LAB_TEST_SUCCESS = "SAVE_TENANT_LAB_TEST_SUCCESS";
export const SAVE_TENANT_LAB_TEST_FAILURE = "SAVE_TENANT_LAB_TEST_FAILURE";

export const GET_LAB_TEST_INFO_REQUEST = "GET_LAB_TEST_INFO_REQUEST";
export const GET_LAB_TEST_INFO_SUCCESS = "GET_LAB_TEST_INFO_SUCCESS";
export const GET_LAB_TEST_INFO_FAILURE = "GET_LAB_TEST_INFO_FAILURE";

export const NEW_LAB_TESTS_REQUEST = "NEW_LAB_TESTS_REQUEST";
export const NEW_LAB_TESTS_SUCCESS = "NEW_LAB_TESTS_SUCCESS";
export const NEW_LAB_TESTS_FAILURE = "NEW_LAB_TESTS_FAILURE";

export const UPDATE_LAB_TESTS_REQUEST = "UPDATE_LAB_TESTS_REQUEST";
export const UPDATE_LAB_TESTS_SUCCESS = "UPDATE_LAB_TESTS_SUCCESS";
export const UPDATE_LAB_TESTS_FAILURE = "UPDATE_LAB_TESTS_FAILURE";

//=========Thunks===========

export const getLabTests = (tenant, type, endpoint) => async (dispatch) => {
  // const access_token = localStorage.getItem("access_token");
  // const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_LAB_TESTS_REQUEST, testType: type });

  setTimeout(() => {
    dispatch({
      type: GET_LAB_TESTS_SUCCESS,
      payload: getMock[type],
      testType: type,
    });
  });

  // return axios({
  // 	method: "GET",
  // 	url: `${API_ROOT}/diagnostic-builder/${endpoint}/${tenant.id}`,
  // 	withCredentials: true,
  // 	crossorigin: true,
  // 	headers: {
  // 		"Access-Control-Allow-Origin": "*",
  // 		"Content-Type": "application/json;charset=UTF-8",
  // 		Accept: "application/json",
  // 		Authorization: "Bearer " + access_token,
  // 		Id_Token: id_token,
  // 	},
  // })
  // 	.then((response) => {
  // 		dispatch({
  // 			type: GET_LAB_TESTS_SUCCESS,
  // 			payload: response.data,
  // 			testType: type,
  // 		});
  // 	})
  // 	.catch((error) => {
  // 		console.log("ERROR", error);
  // 		dispatch({
  // 			type: GET_LAB_TESTS_FAILURE,
  // 			error: error.response.data.message,
  // 			testType: type,
  // 		});
  // 		dispatch(
  // 			showNotification(
  // 				error.response.data.message ||
  // 					"Oops! Something went wrong. Please try again.",
  // 				"error"
  // 			)
  // 		);
  // 	});
};

export const getPaginatedLabTests = (
  tenant,
  type,
  endpoint,
  pageNumber,
  pageSize,
  sort,
  direction,
  term,
  filter
) => async (dispatch) => {
  // const access_token = localStorage.getItem("access_token");
  // const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_LAB_TESTS_REQUEST, testType: type });

  // setTimeout(() => {
  // 	dispatch({
  // 		type: GET_LAB_TESTS_SUCCESS,
  // 		payload: getPaginatedMock[type],
  // 		testType: type,
  // 		pageNumber: pageNumber,
  // 	});
  // });

  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_LAB_TESTS_REQUEST, testType: type });

  let payload = {
    pageNumber,
    pageSize,
    term: term || "",
    ...filter,
  };

  if (sort) {
    payload[sort] = true;
    payload.orderAsc = direction === "asc";
  }

  return axios({
    // method: "POST",
    method: "GET",
    // url: `${API_ROOT}/diagnostic-builder/${endpoint}/Search`,
    url: `${API_ROOT}/diagnostic-builder/${endpoint}`,
    withCredentials: true,
    crossorigin: true,
    data: payload,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_LAB_TESTS_SUCCESS,
        payload: response.data,
        testType: type,
        pageNumber: pageNumber,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_LAB_TESTS_FAILURE,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getTenantLabTests = (
  tenant,
  type,
  endpoint,
  listName
  // pageNumber,
  // pageSize,
  // sort,
  // direction,
  // term,
  // filter
) => async (dispatch) => {
  dispatch({ type: GET_TENANT_LAB_TESTS_REQUEST, testType: type });

  // setTimeout(() => {
  // 	dispatch({
  // 		type: GET_TENANT_LAB_TESTS_SUCCESS,
  // 		payload: getPaginatedMock[type],
  // 		testType: type,
  // 		// pageNumber: pageNumber,
  // 	});
  // });

  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({
    type: GET_TENANT_LAB_TESTS_REQUEST,
    testType: type,
  });

  // let payload = {
  // 	pageNumber,
  // 	pageSize,
  // 	term: term || "",
  // 	...filter,
  // };

  // if (sort) {
  // 	payload[sort] = true;
  // 	payload.orderAsc = direction === "asc";
  // }

  return axios({
    // method: "POST",
    method: "GET",
    // url: `${API_ROOT}/diagnostic-builder/${endpoint}/Search`,
    url: `${API_ROOT}/diagnostic-builder/${endpoint}`,
    withCredentials: true,
    crossorigin: true,
    // data: payload,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((masterResponse) => {
      axios({
        method: "GET",
        url: `${API_ROOT}/diagnostic-builder/${endpoint}Tenant/${tenant}`,
        withCredentials: true,
        crossorigin: true,
        // data: payload,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          Id_Token: id_token,
        },
      })
        .then((response) => {
          console.log("[ACTION] List Name", listName);
          console.log("[ACTION] List", response.data[listName]);

          dispatch({
            type: GET_TENANT_LAB_TESTS_SUCCESS,
            payload:
              response.data && response.data[listName]
                ? masterResponse.data.filter((i) =>
                    response.data[listName].includes(i._id)
                  )
                : [],
            testType: type,
          });
        })
        .catch((error) => {
          console.log("ERROR", error);
          dispatch({
            type: GET_TENANT_LAB_TESTS_FAILURE,
            tenantId: tenant,
            error: error.response.data.message,
            testType: type,
          });
          dispatch(
            showNotification(
              error.response.data.message ||
                "Oops! Something went wrong. Please try again.",
              "error"
            )
          );
        });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_TENANT_LAB_TESTS_FAILURE,
        tenantId: tenant,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });

  // return axios({
  // 	method: "GET",
  // 	url: `${API_ROOT}/diagnostic-builder/${endpoint}Tenant/${tenant}`,
  // 	withCredentials: true,
  // 	crossorigin: true,
  // 	data: payload,
  // 	headers: {
  // 		"Access-Control-Allow-Origin": "*",
  // 		"Content-Type": "application/json;charset=UTF-8",
  // 		Accept: "application/json",
  // 		Authorization: "Bearer " + access_token,
  // 		Id_Token: id_token,
  // 	},
  // })
  // 	.then((response) => {
  // 		dispatch({
  // 			type: GET_TENANT_LAB_TESTS_SUCCESS,
  // 			payload: response.data,
  // 			testType: type,
  // 			pageNumber: pageNumber,
  // 		});
  // 	})
  // 	.catch((error) => {
  // 		console.log("ERROR", error);
  // 		dispatch({
  // 			type: GET_TENANT_LAB_TESTS_FAILURE,
  // 			tenantId: tenant,
  // 			error: error.response.data.message,
  // 			testType: type,
  // 		});
  // 		dispatch(
  // 			showNotification(
  // 				error.response.data.message ||
  // 					"Oops! Something went wrong. Please try again.",
  // 				"error"
  // 			)
  // 		);
  // 	});
};

export const saveTenantLabTests = (
  tenant,
  type,
  listName,
  endpoint,
  labTestIds
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: SAVE_TENANT_LAB_TEST_REQUEST, testType: type });

  return axios({
    method: "POST",
    url: `${API_ROOT}/diagnostic-builder/${endpoint}Tenant/addupdate/${tenant}`,
    withCredentials: true,
    crossorigin: true,
    data: {
      tenantId: tenant,
      [listName]: labTestIds,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SAVE_TENANT_LAB_TEST_SUCCESS,
        payload: response.data,
        testType: type,
      });
      dispatch(showNotification("List updated successfully", "success"));
      dispatch(getTenantLabTests(tenant, type, endpoint, listName));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: SAVE_TENANT_LAB_TEST_FAILURE,
        tenantId: tenant,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getLabTest = (tenant, type, endpoint, id) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_LAB_TEST_INFO_REQUEST, testType: type });

  return axios({
    method: "GET",
    url: `${API_ROOT}/diagnostic-builder/${endpoint}/${tenant.id}/${id}`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_LAB_TEST_INFO_SUCCESS,
        payload: response.data,
        testType: type,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_LAB_TEST_INFO_FAILURE,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const newLabTest = (tenant, type, endpoint, data) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: NEW_LAB_TESTS_REQUEST, testType: type });

  return axios({
    method: "POST",
    url: `${API_ROOT}/diagnostic-builder/${endpoint}/${tenant.id}`,
    withCredentials: true,
    crossorigin: true,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: NEW_LAB_TESTS_SUCCESS,
        payload: response.data,
        testType: type,
      });

      dispatch(showNotification("Successfully Created", "success"));
      history.push(`/${tenant.id}/medical-center/${utils.camelToSnake(type)}`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: NEW_LAB_TESTS_FAILURE,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateLabTest = (tenant, type, endpoint, data) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPDATE_LAB_TESTS_REQUEST, testType: type });

  return axios({
    method: "PUT",
    url: `${API_ROOT}/diagnostic-builder/${endpoint}/${tenant.id}`,
    withCredentials: true,
    crossorigin: true,
    params: {
      tenantid: tenant.id,
    },
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_LAB_TESTS_SUCCESS,
        payload: response.data,
        testType: type,
      });

      dispatch(showNotification("Successfully Created", "success"));
      history.push(`/${tenant.id}/medical-center/${utils.camelToSnake(type)}`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: UPDATE_LAB_TESTS_FAILURE,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
