import * as types from "./SharedActions";

//reducer for shared actions
export const shared = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANTS_REQUEST:
      return { ...state, allTenantsLoading: true };

    case types.GET_TENANTS_SUCCESS:
      return {
        ...state,
        allTenants: action.payload,
        allTenantsLoading: false,
      };

    case types.SET_CURRENT_TENANT:
      return {
        ...state,
        currentTenant: action.tenant,
      };

    case types.UPDATE_CURRENT_TENANT:
      return {
        ...state,
        currentTenant: {
          ...state.currentTenant,
          color: action.color,
          logoURL: action.logo,
          title: action.name,
        },
      };

    default:
      return state;
  }
};
