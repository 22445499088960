export const getMock = {
  testOrderable: [
    {
      loinc: null,
      orderableName: "QuestCovid19",
      shortDescription:
        "This is required for diagnostic test cassette intro widgets",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ebd241e44b4557ecc808eff",
      specimenTypeId: "5ee1df3919e897caaccf08c3",
      specimenType: {
        format: "Capillary Blood",
        isActive: true,
        _id: "5ee1df3919e897caaccf08c3",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:37:29.637Z",
        modified: "2020-06-11T07:37:29.637Z",
      },
      testFormat: {
        format: "Test Kit",
        formatId: "test-kit",
        isActive: true,
        _id: "5ebd241e44b4557ecc808eff",
        modifier: "System",
        creator: "System",
        created: "2020-05-14T10:57:34.196Z",
        modified: "2020-07-14T16:17:47.746Z",
      },
      uploadTimeoutInSeconds: 120.5,
      vendorTestID: "QuestCovid57",
      price: 50,
      statesApplicable: ["CA", "LA", "NY", "AL"],
      keywords: ["covid19"],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: ["5edf9cd0e597ba000198ef4a", "5edf9d16e597ba000198ef7f"],
      introWidgets: [
        {
          order: 1,
          networkID: "5ec6326ae691f7542d19055a",
          title: "Use lancet",
          html:
            '<p><span style="font-size:12.0pt;\nfont-family:&quot;Helvetica&quot;,sans-serif">Use lancet for finger stick, then gently squeeze one droplet of blood into the hole as shown</span></p>',
          imageURL:
            "https://safe-content-cache.s3-us-west-2.amazonaws.com/dev/intro-widgets/5edf9cd0e597ba000198ef4a_637273097766854026.png",
          videoURL: null,
          mediaType: "image",
          externalLinkURL: null,
          status: 1,
          channelIDs: null,
          _id: "5edf9cd0e597ba000198ef4a",
          modifier: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          creator: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          created: "2020-06-09T14:29:36.035Z",
          modified: "2020-06-09T14:33:30.051Z",
        },
        {
          order: 2,
          networkID: "5ec6326ae691f7542d19055a",
          title: "Squeeze blood",
          html:
            '<p><span style="font-size:12.0pt;\nfont-family:&quot;Helvetica&quot;,sans-serif">Gently squeeze a one droplet of blood into the hole shown below.</span></p>',
          imageURL:
            "https://safe-content-cache.s3-us-west-2.amazonaws.com/dev/intro-widgets/5edf9d16e597ba000198ef7f_637273098462699696.png",
          videoURL: null,
          mediaType: "image",
          externalLinkURL: null,
          status: 1,
          channelIDs: null,
          _id: "5edf9d16e597ba000198ef7f",
          modifier: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          creator: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          created: "2020-06-09T14:30:46.222Z",
          modified: "2020-06-09T14:30:46.222Z",
        },
      ],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ecd15ec95a5210da4c7a6e5",
      modifier: "System",
      creator: "System",
      created: "2020-05-26T13:13:16.941Z",
      modified: "2020-05-26T13:13:16.941Z",
    },
    {
      loinc: null,
      orderableName:
        "SARS CORONAVIRUS WITH CoV-2 RNA, QUALITATIVE REAL-TIME RT-PCR",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "39444",
      price: 120,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee211b6fcb4e894309192a9",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:12:54.239Z",
      modified: "2020-06-11T11:12:54.239Z",
    },
    {
      loinc: null,
      orderableName:
        "SARS-CoV-2 SEROLOGY (COVID-19) ANTIBODY (IgG), IMMUNOASSAY",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df6b19e897caaccf08c4",
      specimenType: {
        format: "SERUM",
        isActive: true,
        _id: "5ee1df6b19e897caaccf08c4",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:19.209Z",
        modified: "2020-06-11T07:38:19.209Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "39504",
      price: 120,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee2122dfcb4e894309192aa",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:14:53.024Z",
      modified: "2020-06-11T11:14:53.024Z",
    },
    {
      loinc: null,
      orderableName: "Covid-19 Antibody",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ee1e1b419e897caaccf08ce",
      vendorDetail: {
        name: "LabCorp",
        vendorKey: "LABCORP",
        address: {
          address1: "Burlington",
          address2: " North Carolina, United States",
          city: "Burlington",
          state: "North Carolina",
          zip: "27215",
        },
        contactDetail: {
          firstName: "LabCorp",
          lastName: "LabCorp",
          phoneNumber: "",
          faxNumber: "",
          email: "",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ee1e1b419e897caaccf08ce",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:48:04.589Z",
        modified: "2020-06-25T13:23:50.509Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1dfb919e897caaccf08c7",
      specimenType: {
        format: "Nasopharyngeal",
        isActive: true,
        _id: "5ee1dfb919e897caaccf08c7",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:39:37.206Z",
        modified: "2020-06-11T07:39:37.206Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "139900",
      price: 100,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: {
        cptCode: "0098U",
        cptDescription:
          '"Detection of respiratory infectious agent by multiplex reverse transcription and multiplex amplified probe technique for adenovirus',
        modifierCode: "A2",
        modifierDescription: "A2 - Dressing for two wounds",
        price: 100,
        cmsFee: 100,
        taxable: false,
      },
      association: {
        conditions: null,
        procedures: null,
        prescriptionMedications: null,
        otcMedications: null,
      },
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee2147dfcb4e894309192ad",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:24:45.494Z",
      modified: "2020-07-20T13:18:16.76Z",
    },
    {
      loinc: "94563-4",
      orderableName: "Covid-19-Nasopharyngeal",
      shortDescription: "testCovid",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23f844b4557ecc808efe",
      vendorDetail: {
        name: "Molecular Testing Labs",
        vendorKey: "MTL",
        address: {
          address1: "Molecular Testing Labs",
          address2: "14401 SE 1st St.",
          city: " Vancouver",
          state: "WS",
          zip: "98684",
        },
        contactDetail: {
          firstName: "Molecular Testing",
          lastName: "Labs",
          phoneNumber: "855-400-6002",
          faxNumber: "",
          email: "https://moleculartestinglabs.com/",
        },
        isRequisitionSupported: false,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23f844b4557ecc808efe",
        modifier: "System",
        creator: "jaskaran@safehealth.me",
        created: "2020-05-14T06:52:33.443Z",
        modified: "2020-06-25T13:23:36.811Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "MTLTest",
      price: 90,
      statesApplicable: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "95803",
        cptDescription: '"Actigraphy testing',
        modifierCode: "CD",
        modifierDescription:
          "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
        price: 90,
        cmsFee: 50,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "",
            description: "",
          },
        ],
        procedures: [
          {
            code: "",
            description: "",
          },
        ],
        prescriptionMedications: [
          {
            code: "",
            description: "",
          },
        ],
        otcMedications: [
          {
            code: "",
            description: "",
          },
        ],
      },
      isActive: false,
      introWidgetIds: null,
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: "days",
      frequencyValue: 10,
      processingTimeUnit: "minutes",
      processingTimeValue: 10,
      _id: "5f02ffb72ffc6d00016fd51c",
      modifier: "System",
      creator: "System",
      created: "2020-07-06T10:40:55.665Z",
      modified: "2020-07-20T13:18:42.576Z",
    },
    {
      loinc: "94559-2",
      orderableName: "SARS CoV-2 RNA, TMA",
      shortDescription: "Mayo Covid Test",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5f0f042371cb1b4810b92517",
      vendorDetail: {
        name: "Mayo Clinic",
        vendorKey: "MAYO_CLINIC",
        address: {
          address1: "200 First St. SW",
          address2: "Plaza Dr",
          city: "Rochester",
          state: "Minnesota",
          zip: "55905",
        },
        contactDetail: {
          firstName: "Mayo",
          lastName: "Clinic",
          phoneNumber: "+1 800-533-1710",
          faxNumber: "",
          email: "https://test.mayocliniclabs.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: false,
        isActive: true,
        _id: "5f0f042371cb1b4810b92517",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "SCOVH",
      price: 90,
      statesApplicable: ["LA", "IL"],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "95803",
        cptDescription: '"Actigraphy testing',
        modifierCode: "CD",
        modifierDescription:
          "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
        price: 90,
        cmsFee: 50,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "",
            description: "",
          },
        ],
        procedures: [
          {
            code: "",
            description: "",
          },
        ],
        prescriptionMedications: [
          {
            code: "",
            description: "",
          },
        ],
        otcMedications: [
          {
            code: "",
            description: "",
          },
        ],
      },
      isActive: false,
      introWidgetIds: null,
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5f0f41ff71cb1b4810b92518",
      modifier: "System",
      creator: "System",
      created: "2020-07-06T10:40:55.665Z",
      modified: "2020-07-14T13:25:58.073Z",
    },
  ],
  testPanels: [
    {
      shortName: "Adei Test 3",
      longName: "Adei Test 3 Description",
      shortDescription: "Short Desc",
      longDescription: "Long Desc",
      labTestIds: ["5ee2122dfcb4e894309192aa"],
      labTests: [
        {
          loinc: null,
          orderableName:
            "SARS-CoV-2 SEROLOGY (COVID-19) ANTIBODY (IgG), IMMUNOASSAY",
          shortDescription: "Covid-19 test",
          longDescription: "Covid-19 test",
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df6b19e897caaccf08c4",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "39504",
          price: 120,
          statesApplicable: [""],
          keywords: [""],
          images: [""],
          articles: [""],
          videos: [""],
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: [""],
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ee2122dfcb4e894309192aa",
          modifier: "System",
          creator: "System",
          created: "2020-06-11T11:14:53.024Z",
          modified: "2020-06-11T11:14:53.024Z",
        },
      ],
      keywords: ["short", "long", "desc", "test", "adei"],
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "27752",
        cptDescription:
          '"Closed reduction of fracture of shaft of tibia without fracture of fibula',
        modifierCode: "P5",
        modifierDescription:
          "A moribund patient who is not expected to survive without the operation",
        price: 11,
        cmsFee: 11,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "W34.111S",
            description: '"ACCIDENTAL MALFUNCTION OF PAINTBALL GUN',
          },
          {
            code: "X37.0",
            description: "HURRICANE",
          },
        ],
        procedures: [
          {
            code: "97155",
            description:
              '"Adaptive behavior treatment with protocol modification administered by qualified health care professional',
          },
          {
            code: "99450",
            description: '"Basic life insurance examination',
          },
        ],
        prescriptionMedications: [
          {
            code: "64578-0109",
            description: "59.1 mL in 1 BOTTLE, DROPPER (64578-0109-1) ",
          },
        ],
        otcMedications: null,
      },
      isDefault: false,
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      isActive: true,
      _id: "5ef2221d7aadc30001a0c47d",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:39:09.42Z",
      modified: "2020-06-24T14:16:07.78Z",
    },
    {
      shortName: "Adei's Test 2",
      longName: "Adei's Test 2 Desc",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5eed000fafa02600017d59bd", "5eedcb1cafa02600017d59bf"],
      labTests: [
        {
          loinc: "88191-2",
          orderableName: "3333",
          shortDescription: "Short Desk!",
          longDescription: "Long Desk!",
          labTestId: "5eecda3dafa02600017d59bc",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df9e19e897caaccf08c6",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "333333",
          price: 11,
          statesApplicable: ["AL", "AK", "AS"],
          keywords: ["test", "123"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96138",
            cptDescription:
              '"Administration and scoring of psychological tests by technician',
            modifierCode: "PI",
            modifierDescription:
              "Positron emission tomography (pet) or pet/computed tomography (ct) to inform the initial treatment strategy of tumors that are biopsy proven or strongly suspected of being cancerous based on other diagnostic testing",
            price: 11,
            cmsFee: 11,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "G89.2",
                description: '"CHRONIC PAIN',
              },
            ],
            procedures: [
              {
                code: "66987",
                description:
                  '"Complex 1-stage extracapsular cataract removal by irrigation and aspiration',
              },
            ],
            prescriptionMedications: [
              {
                code: "0268-1165",
                description: "10 mL in 1 VIAL, MULTI-DOSE (0268-1165-10) ",
              },
            ],
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5eed000fafa02600017d59bd",
          modifier: "System",
          creator: "System",
          created: "2020-06-19T18:12:31.414Z",
          modified: "2020-07-14T13:24:14.903Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ef21ae17aadc30001a0c47c",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:08:17.305Z",
      modified: "2020-06-23T15:08:17.305Z",
    },
    {
      shortName: "Adei's Test Panel",
      longName: "Adei's Test Panel description",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5eed000fafa02600017d59bd", "5eedaf4c02dd69a0d81085ae"],
      labTests: [
        {
          loinc: "88191-2",
          orderableName: "3333",
          shortDescription: "Short Desk!",
          longDescription: "Long Desk!",
          labTestId: "5eecda3dafa02600017d59bc",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df9e19e897caaccf08c6",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "333333",
          price: 11,
          statesApplicable: ["AL", "AK", "AS"],
          keywords: ["test", "123"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96138",
            cptDescription:
              '"Administration and scoring of psychological tests by technician',
            modifierCode: "PI",
            modifierDescription:
              "Positron emission tomography (pet) or pet/computed tomography (ct) to inform the initial treatment strategy of tumors that are biopsy proven or strongly suspected of being cancerous based on other diagnostic testing",
            price: 11,
            cmsFee: 11,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "G89.2",
                description: '"CHRONIC PAIN',
              },
            ],
            procedures: [
              {
                code: "66987",
                description:
                  '"Complex 1-stage extracapsular cataract removal by irrigation and aspiration',
              },
            ],
            prescriptionMedications: [
              {
                code: "0268-1165",
                description: "10 mL in 1 VIAL, MULTI-DOSE (0268-1165-10) ",
              },
            ],
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5eed000fafa02600017d59bd",
          modifier: "System",
          creator: "System",
          created: "2020-06-19T18:12:31.414Z",
          modified: "2020-07-14T13:24:14.903Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ef21a337aadc30001a0c47b",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:05:23.718Z",
      modified: "2020-06-23T15:05:23.718Z",
    },
    {
      shortName: "Covid-Diabetes",
      longName: "Covid and Diabetes joint test",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd252544b4557ecc808f02", "5ec408957cc9c9000170e5a2"],
      labTests: [
        {
          loinc: null,
          orderableName: "QuestCovid19_test",
          shortDescription: null,
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ebd241e44b4557ecc808eff",
          specimenTypeId: null,
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 120.5,
          vendorTestID: "QuestCovid57",
          price: 52,
          statesApplicable: ["CA", "LA", "NY", "AL"],
          keywords: ["covid19"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ebd252544b4557ecc808f02",
          modifier: "System",
          creator: "",
          created: "2020-05-14T12:12:37.18Z",
          modified: "2020-05-20T11:28:36.925Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ec52eb85f79f70001e7423e",
      modifier: "System",
      creator: "System",
      created: "2020-05-20T13:20:56.197Z",
      modified: "2020-05-20T13:20:56.197Z",
    },
    {
      shortName: "My Custom Test Panel",
      longName: "My Custom Test Panel's Description",
      shortDescription: "Short Description for the Diagnostic Profile",
      longDescription: "Long Description for the Diagnostic Profile",
      labTestIds: ["5eedcb1cafa02600017d59bf", "5f02ffb72ffc6d00016fd51c"],
      labTests: [
        {
          loinc: "94563-4",
          orderableName: "Covid-19-Nasopharyngeal",
          shortDescription: "testCovid",
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df8419e897caaccf08c5",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "MTLTest",
          price: 90,
          statesApplicable: [
            "AL",
            "AK",
            "AS",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "FM",
            "FL",
            "GA",
            "GU",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MH",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "MP",
            "OH",
            "OK",
            "OR",
            "PW",
            "PA",
            "PR",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VI",
            "VA",
            "WA",
            "WV",
            "WI",
            "WY",
          ],
          keywords: null,
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "95803",
            cptDescription: '"Actigraphy testing',
            modifierCode: "CD",
            modifierDescription:
              "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
            price: 90,
            cmsFee: 50,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "",
                description: "",
              },
            ],
            procedures: [
              {
                code: "",
                description: "",
              },
            ],
            prescriptionMedications: [
              {
                code: "",
                description: "",
              },
            ],
            otcMedications: [
              {
                code: "",
                description: "",
              },
            ],
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: "days",
          frequencyValue: 10,
          processingTimeUnit: "minutes",
          processingTimeValue: 10,
          _id: "5f02ffb72ffc6d00016fd51c",
          modifier: "System",
          creator: "System",
          created: "2020-07-06T10:40:55.665Z",
          modified: "2020-07-20T13:18:42.576Z",
        },
      ],
      keywords: ["diagnostic", "profile"],
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "20604",
        cptDescription:
          '"Aspiration and injection of bursa of finger with ultrasound guidance',
        modifierCode: "BR",
        modifierDescription:
          "BR - The beneficiary has been informed of the purchase and rental options and has elected to rent the item",
        price: 13,
        cmsFee: 13,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "T71.191A",
            description: "ASPX D/T MECH THRT TO BREATHE D/T OT COZ ACC INI",
          },
        ],
        procedures: [
          {
            code: "17110",
            description: '"Chemosurgery of benign lesions',
          },
        ],
        prescriptionMedications: [
          {
            code: "0067-2000",
            description:
              "1 BOTTLE in 1 CARTON (0067-2000-10)  > 10 TABLET, FILM COATED in 1 BOTTLE",
          },
        ],
        otcMedications: [
          {
            code: "0067-2001",
            description:
              "1 BOTTLE in 1 CARTON (0067-2001-05)  > 100 TABLET, FILM COATED in 1 BOTTLE",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: "5ebd23f844b4557ecc808efe",
      isActive: true,
      _id: "5f08573f3ca2bc000137d11e",
      modifier: "System",
      creator: "System",
      created: "2020-07-10T11:55:43.07Z",
      modified: "2020-07-10T13:51:35.571Z",
    },
    {
      shortName: "QuestCovidPanel",
      longName: "Panel for Quest Covid-19 testing",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd252544b4557ecc808f02"],
      labTests: [
        {
          loinc: null,
          orderableName: "QuestCovid19_test",
          shortDescription: null,
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ebd241e44b4557ecc808eff",
          specimenTypeId: null,
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 120.5,
          vendorTestID: "QuestCovid57",
          price: 52,
          statesApplicable: ["CA", "LA", "NY", "AL"],
          keywords: ["covid19"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ebd252544b4557ecc808f02",
          modifier: "System",
          creator: "",
          created: "2020-05-14T12:12:37.18Z",
          modified: "2020-05-20T11:28:36.925Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ebd25a344b4557ecc808f03",
      modifier: "System",
      creator: "System",
      created: "2020-05-14T11:04:03.746Z",
      modified: "2020-05-19T08:26:00.789Z",
    },
    {
      shortName: "QuestMensHealthPanel",
      longName: "Panel for Mens health exam tests",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd30c6b507bf7df8230c4d", "5ebd3105b507bf7df8230c4e"],
      labTests: [],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ebd315ab507bf7df8230c4f",
      modifier: "System",
      creator: "System",
      created: "2020-05-14T11:54:02.865Z",
      modified: "2020-05-14T11:54:02.865Z",
    },
    {
      shortName: "TestVendor Panel",
      longName: "Panel for the TestVendor Lab",
      shortDescription: "Short desc for TestVendor Panel",
      longDescription: "Long desc for TestVendor Panel",
      labTestIds: ["5ef361082ffc6d00016fd51a"],
      labTests: [
        {
          loinc: "33463-1",
          orderableName: "SARS-CoV-2 RNA (COVID-19), QUALITATIVE NAA",
          shortDescription: "KLK",
          longDescription: "PPOPOIOIOPO",
          labTestId: "5ebd67c97c86d00001551d5d",
          labTest: null,
          vendorDetailId: "5ec37b5c319b9b02380fd724",
          vendorDetail: null,
          testFormatId: "5ebd242a44b4557ecc808f00",
          specimenTypeId: "5ee1dfb919e897caaccf08c7",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "39448",
          price: 55,
          statesApplicable: ["KY", "LA", "ME"],
          keywords: null,
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96136",
            cptDescription:
              '"Administration and scoring of neuropsychological tests by qualified health care professional',
            modifierCode: "CE",
            modifierDescription:
              "Amcc test has been ordered by an esrd facility or mcp physician that is a composite rate test but is beyond the normal frequency covered under the rate and is separately reimbursable based on medical necessity",
            price: 55,
            cmsFee: 55,
            taxable: true,
          },
          association: {
            conditions: null,
            procedures: null,
            prescriptionMedications: null,
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ef361082ffc6d00016fd51a",
          modifier: "System",
          creator: "System",
          created: "2020-06-24T14:19:52.035Z",
          modified: "2020-07-14T13:25:48.761Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "96138",
        cptDescription:
          '"Administration and scoring of neuropsychological tests by technician',
        modifierCode: "CE",
        modifierDescription:
          "Amcc test has been ordered by an esrd facility or mcp physician that is a composite rate test but is beyond the normal frequency covered under the rate and is separately reimbursable based on medical necessity",
        price: 11,
        cmsFee: 2,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "S30.813S",
            description: '"ABRASION OF SCROTUM AND TESTES',
          },
        ],
        procedures: [
          {
            code: "96137",
            description:
              '"Administration and scoring of neuropsychological tests by qualified health care professional',
          },
        ],
        prescriptionMedications: [
          {
            code: "66343-043",
            description: "50 mL in 1 BOTTLE (66343-043-50) ",
          },
        ],
        otcMedications: [
          {
            code: "63029-600",
            description: "24 POWDER in 1 BOX (63029-600-01) ",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: "5ec37b5c319b9b02380fd724",
      isActive: true,
      _id: "5ef361dc2ffc6d00016fd51b",
      modifier: "System",
      creator: "System",
      created: "2020-06-24T14:23:24.112Z",
      modified: "2020-06-25T13:45:30.432Z",
    },
    {
      shortName: "string",
      longName: "string",
      shortDescription: "string",
      longDescription: "string",
      labTestIds: ["string"],
      labTests: [],
      keywords: ["string"],
      images: ["string"],
      articles: ["string"],
      videos: ["string"],
      billingDetail: {
        cptCode: "string",
        cptDescription: "string",
        modifierCode: "string",
        modifierDescription: "string",
        price: 0,
        cmsFee: 0,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "string",
            description: "string",
          },
        ],
        procedures: [
          {
            code: "string",
            description: "string",
          },
        ],
        prescriptionMedications: [
          {
            code: "string",
            description: "string",
          },
        ],
        otcMedications: [
          {
            code: "string",
            description: "string",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "string",
      modifier: "System",
      creator: "System",
      created: "2020-06-19T12:31:07.409Z",
      modified: "2020-06-19T12:31:07.409Z",
    },
  ],
};

export const getPaginatedMock = {
  testOrderable: [
    {
      loinc: null,
      orderableName: "QuestCovid19",
      shortDescription:
        "This is required for diagnostic test cassette intro widgets",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ebd241e44b4557ecc808eff",
      specimenTypeId: "5ee1df3919e897caaccf08c3",
      specimenType: {
        format: "Capillary Blood",
        isActive: true,
        _id: "5ee1df3919e897caaccf08c3",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:37:29.637Z",
        modified: "2020-06-11T07:37:29.637Z",
      },
      testFormat: {
        format: "Test Kit",
        formatId: "test-kit",
        isActive: true,
        _id: "5ebd241e44b4557ecc808eff",
        modifier: "System",
        creator: "System",
        created: "2020-05-14T10:57:34.196Z",
        modified: "2020-07-14T16:17:47.746Z",
      },
      uploadTimeoutInSeconds: 120.5,
      vendorTestID: "QuestCovid57",
      price: 50,
      statesApplicable: ["CA", "LA", "NY", "AL"],
      keywords: ["covid19"],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: ["5edf9cd0e597ba000198ef4a", "5edf9d16e597ba000198ef7f"],
      introWidgets: [
        {
          order: 1,
          networkID: "5ec6326ae691f7542d19055a",
          title: "Use lancet",
          html:
            '<p><span style="font-size:12.0pt;\nfont-family:&quot;Helvetica&quot;,sans-serif">Use lancet for finger stick, then gently squeeze one droplet of blood into the hole as shown</span></p>',
          imageURL:
            "https://safe-content-cache.s3-us-west-2.amazonaws.com/dev/intro-widgets/5edf9cd0e597ba000198ef4a_637273097766854026.png",
          videoURL: null,
          mediaType: "image",
          externalLinkURL: null,
          status: 1,
          channelIDs: null,
          _id: "5edf9cd0e597ba000198ef4a",
          modifier: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          creator: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          created: "2020-06-09T14:29:36.035Z",
          modified: "2020-06-09T14:33:30.051Z",
        },
        {
          order: 2,
          networkID: "5ec6326ae691f7542d19055a",
          title: "Squeeze blood",
          html:
            '<p><span style="font-size:12.0pt;\nfont-family:&quot;Helvetica&quot;,sans-serif">Gently squeeze a one droplet of blood into the hole shown below.</span></p>',
          imageURL:
            "https://safe-content-cache.s3-us-west-2.amazonaws.com/dev/intro-widgets/5edf9d16e597ba000198ef7f_637273098462699696.png",
          videoURL: null,
          mediaType: "image",
          externalLinkURL: null,
          status: 1,
          channelIDs: null,
          _id: "5edf9d16e597ba000198ef7f",
          modifier: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          creator: "27a73966-313f-450e-81f5-946eeb8a0b3a",
          created: "2020-06-09T14:30:46.222Z",
          modified: "2020-06-09T14:30:46.222Z",
        },
      ],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ecd15ec95a5210da4c7a6e5",
      modifier: "System",
      creator: "System",
      created: "2020-05-26T13:13:16.941Z",
      modified: "2020-05-26T13:13:16.941Z",
    },
    {
      loinc: null,
      orderableName:
        "SARS CORONAVIRUS WITH CoV-2 RNA, QUALITATIVE REAL-TIME RT-PCR",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "39444",
      price: 120,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee211b6fcb4e894309192a9",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:12:54.239Z",
      modified: "2020-06-11T11:12:54.239Z",
    },
    {
      loinc: null,
      orderableName:
        "SARS-CoV-2 SEROLOGY (COVID-19) ANTIBODY (IgG), IMMUNOASSAY",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      vendorDetail: {
        name: "Quest Diagnostics",
        vendorKey: "QUEST_LABS",
        address: {
          address1: "500",
          address2: "Plaza Dr",
          city: "Secaucus",
          state: "NJ",
          zip: "07094",
        },
        contactDetail: {
          firstName: "Quest",
          lastName: "Diagnostics ",
          phoneNumber: "+1 800-222-0446",
          faxNumber: "",
          email: "https://www.questdiagnostics.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23d344b4557ecc808efd",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df6b19e897caaccf08c4",
      specimenType: {
        format: "SERUM",
        isActive: true,
        _id: "5ee1df6b19e897caaccf08c4",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:19.209Z",
        modified: "2020-06-11T07:38:19.209Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "39504",
      price: 120,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: null,
      association: null,
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee2122dfcb4e894309192aa",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:14:53.024Z",
      modified: "2020-06-11T11:14:53.024Z",
    },
    {
      loinc: null,
      orderableName: "Covid-19 Antibody",
      shortDescription: "Covid-19 test",
      longDescription: "Covid-19 test",
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ee1e1b419e897caaccf08ce",
      vendorDetail: {
        name: "LabCorp",
        vendorKey: "LABCORP",
        address: {
          address1: "Burlington",
          address2: " North Carolina, United States",
          city: "Burlington",
          state: "North Carolina",
          zip: "27215",
        },
        contactDetail: {
          firstName: "LabCorp",
          lastName: "LabCorp",
          phoneNumber: "",
          faxNumber: "",
          email: "",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ee1e1b419e897caaccf08ce",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:48:04.589Z",
        modified: "2020-06-25T13:23:50.509Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1dfb919e897caaccf08c7",
      specimenType: {
        format: "Nasopharyngeal",
        isActive: true,
        _id: "5ee1dfb919e897caaccf08c7",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:39:37.206Z",
        modified: "2020-06-11T07:39:37.206Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "139900",
      price: 100,
      statesApplicable: [""],
      keywords: [""],
      images: [""],
      articles: [""],
      videos: [""],
      billingDetail: {
        cptCode: "0098U",
        cptDescription:
          '"Detection of respiratory infectious agent by multiplex reverse transcription and multiplex amplified probe technique for adenovirus',
        modifierCode: "A2",
        modifierDescription: "A2 - Dressing for two wounds",
        price: 100,
        cmsFee: 100,
        taxable: false,
      },
      association: {
        conditions: null,
        procedures: null,
        prescriptionMedications: null,
        otcMedications: null,
      },
      isActive: true,
      introWidgetIds: [""],
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5ee2147dfcb4e894309192ad",
      modifier: "System",
      creator: "System",
      created: "2020-06-11T11:24:45.494Z",
      modified: "2020-07-20T13:18:16.76Z",
    },
    {
      loinc: "94563-4",
      orderableName: "Covid-19-Nasopharyngeal",
      shortDescription: "testCovid",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5ebd23f844b4557ecc808efe",
      vendorDetail: {
        name: "Molecular Testing Labs",
        vendorKey: "MTL",
        address: {
          address1: "Molecular Testing Labs",
          address2: "14401 SE 1st St.",
          city: " Vancouver",
          state: "WS",
          zip: "98684",
        },
        contactDetail: {
          firstName: "Molecular Testing",
          lastName: "Labs",
          phoneNumber: "855-400-6002",
          faxNumber: "",
          email: "https://moleculartestinglabs.com/",
        },
        isRequisitionSupported: false,
        isResultDocumentSupported: true,
        isActive: true,
        _id: "5ebd23f844b4557ecc808efe",
        modifier: "System",
        creator: "jaskaran@safehealth.me",
        created: "2020-05-14T06:52:33.443Z",
        modified: "2020-06-25T13:23:36.811Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "MTLTest",
      price: 90,
      statesApplicable: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "95803",
        cptDescription: '"Actigraphy testing',
        modifierCode: "CD",
        modifierDescription:
          "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
        price: 90,
        cmsFee: 50,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "",
            description: "",
          },
        ],
        procedures: [
          {
            code: "",
            description: "",
          },
        ],
        prescriptionMedications: [
          {
            code: "",
            description: "",
          },
        ],
        otcMedications: [
          {
            code: "",
            description: "",
          },
        ],
      },
      isActive: false,
      introWidgetIds: null,
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: "days",
      frequencyValue: 10,
      processingTimeUnit: "minutes",
      processingTimeValue: 10,
      _id: "5f02ffb72ffc6d00016fd51c",
      modifier: "System",
      creator: "System",
      created: "2020-07-06T10:40:55.665Z",
      modified: "2020-07-20T13:18:42.576Z",
    },
    {
      loinc: "94559-2",
      orderableName: "SARS CoV-2 RNA, TMA",
      shortDescription: "Mayo Covid Test",
      longDescription: null,
      labTestId: "5ebd245c44b4557ecc808f01",
      labTest: {
        loinc: "94563-4",
        shortName: "Covid19",
        longName: "Covid19",
        shortDescription: "Covid-19",
        longDescription:
          "Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).[10] It was first identified in December 2019 in Wuhan, China, and has since spread globally, resulting in allllln ongoing pandemic.",
        testFormatId: null,
        specimenTypeId: "5ee1df8419e897caaccf08c5",
        safeTestId: "57",
        keywords: ["covid", "covid-19", "covid19"],
        images: null,
        articles: ["5ebebc8365acf400012da1b4"],
        videos: ["5ea9b5e1edc00300011b6d04"],
        billingDetail: {
          cptCode: "87631",
          cptDescription:
            "Respiratory virus detection by nucleic acid using amplified probe technique",
          modifierCode: "A1",
          modifierDescription: "A1 - Dressing for one wound",
          price: 100,
          cmsFee: 100,
          taxable: true,
        },
        association: {
          conditions: [
            {
              code: "",
              description: "",
            },
          ],
          procedures: [
            {
              code: "",
              description: "",
            },
          ],
          prescriptionMedications: [
            {
              code: "",
              description: "",
            },
          ],
          otcMedications: [
            {
              code: "",
              description: "",
            },
          ],
        },
        isActive: true,
        kitRegistrationRequired: false,
        kitManufacturer: null,
        instructions: null,
        customTestName: null,
        testImageAssetHeight: 0,
        testImageAssetWidth: 0,
        frequencyUnit: "days",
        frequencyValue: 10,
        processingTimeUnit: "days",
        processingTimeValue: 10,
        _id: "5ebd245c44b4557ecc808f01",
        modifier: "System",
        creator: null,
        created: "0001-01-01T00:00:00Z",
        modified: "2020-07-20T13:21:00.462Z",
      },
      vendorDetailId: "5f0f042371cb1b4810b92517",
      vendorDetail: {
        name: "Mayo Clinic",
        vendorKey: "MAYO_CLINIC",
        address: {
          address1: "200 First St. SW",
          address2: "Plaza Dr",
          city: "Rochester",
          state: "Minnesota",
          zip: "55905",
        },
        contactDetail: {
          firstName: "Mayo",
          lastName: "Clinic",
          phoneNumber: "+1 800-533-1710",
          faxNumber: "",
          email: "https://test.mayocliniclabs.com/",
        },
        isRequisitionSupported: true,
        isResultDocumentSupported: false,
        isActive: true,
        _id: "5f0f042371cb1b4810b92517",
        modifier: "System",
        creator: "",
        created: "2020-05-19T06:25:22.829Z",
        modified: "2020-06-30T11:27:03.753Z",
      },
      testFormatId: "5ee1e06b19e897caaccf08cc",
      specimenTypeId: "5ee1df8419e897caaccf08c5",
      specimenType: {
        format: "NASOPHARYNGEAL SWAB",
        isActive: true,
        _id: "5ee1df8419e897caaccf08c5",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:38:44.272Z",
        modified: "2020-06-11T07:38:44.272Z",
      },
      testFormat: {
        format: "Facility Testing",
        formatId: "facility-testing",
        isActive: true,
        _id: "5ee1e06b19e897caaccf08cc",
        modifier: "System",
        creator: "System",
        created: "2020-06-11T07:42:35.069Z",
        modified: "2020-07-14T15:53:09.291Z",
      },
      uploadTimeoutInSeconds: 0,
      vendorTestID: "SCOVH",
      price: 90,
      statesApplicable: ["LA", "IL"],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "95803",
        cptDescription: '"Actigraphy testing',
        modifierCode: "CD",
        modifierDescription:
          "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
        price: 90,
        cmsFee: 50,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "",
            description: "",
          },
        ],
        procedures: [
          {
            code: "",
            description: "",
          },
        ],
        prescriptionMedications: [
          {
            code: "",
            description: "",
          },
        ],
        otcMedications: [
          {
            code: "",
            description: "",
          },
        ],
      },
      isActive: false,
      introWidgetIds: null,
      introWidgets: [],
      kitRegistrationRequired: false,
      kitManufacturer: null,
      instructions: null,
      customTestName: null,
      testImageAssetHeight: 0,
      testImageAssetWidth: 0,
      frequencyUnit: null,
      frequencyValue: 0,
      processingTimeUnit: null,
      processingTimeValue: 0,
      _id: "5f0f41ff71cb1b4810b92518",
      modifier: "System",
      creator: "System",
      created: "2020-07-06T10:40:55.665Z",
      modified: "2020-07-14T13:25:58.073Z",
    },
  ],
  testPanels: [
    {
      shortName: "Adei Test 3",
      longName: "Adei Test 3 Description",
      shortDescription: "Short Desc",
      longDescription: "Long Desc",
      labTestIds: ["5ee2122dfcb4e894309192aa"],
      labTests: [
        {
          loinc: null,
          orderableName:
            "SARS-CoV-2 SEROLOGY (COVID-19) ANTIBODY (IgG), IMMUNOASSAY",
          shortDescription: "Covid-19 test",
          longDescription: "Covid-19 test",
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df6b19e897caaccf08c4",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "39504",
          price: 120,
          statesApplicable: [""],
          keywords: [""],
          images: [""],
          articles: [""],
          videos: [""],
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: [""],
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ee2122dfcb4e894309192aa",
          modifier: "System",
          creator: "System",
          created: "2020-06-11T11:14:53.024Z",
          modified: "2020-06-11T11:14:53.024Z",
        },
      ],
      keywords: ["short", "long", "desc", "test", "adei"],
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "27752",
        cptDescription:
          '"Closed reduction of fracture of shaft of tibia without fracture of fibula',
        modifierCode: "P5",
        modifierDescription:
          "A moribund patient who is not expected to survive without the operation",
        price: 11,
        cmsFee: 11,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "W34.111S",
            description: '"ACCIDENTAL MALFUNCTION OF PAINTBALL GUN',
          },
          {
            code: "X37.0",
            description: "HURRICANE",
          },
        ],
        procedures: [
          {
            code: "97155",
            description:
              '"Adaptive behavior treatment with protocol modification administered by qualified health care professional',
          },
          {
            code: "99450",
            description: '"Basic life insurance examination',
          },
        ],
        prescriptionMedications: [
          {
            code: "64578-0109",
            description: "59.1 mL in 1 BOTTLE, DROPPER (64578-0109-1) ",
          },
        ],
        otcMedications: null,
      },
      isDefault: false,
      vendorDetailId: "5ebd23d344b4557ecc808efd",
      isActive: true,
      _id: "5ef2221d7aadc30001a0c47d",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:39:09.42Z",
      modified: "2020-06-24T14:16:07.78Z",
    },
    {
      shortName: "Adei's Test 2",
      longName: "Adei's Test 2 Desc",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5eed000fafa02600017d59bd", "5eedcb1cafa02600017d59bf"],
      labTests: [
        {
          loinc: "88191-2",
          orderableName: "3333",
          shortDescription: "Short Desk!",
          longDescription: "Long Desk!",
          labTestId: "5eecda3dafa02600017d59bc",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df9e19e897caaccf08c6",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "333333",
          price: 11,
          statesApplicable: ["AL", "AK", "AS"],
          keywords: ["test", "123"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96138",
            cptDescription:
              '"Administration and scoring of psychological tests by technician',
            modifierCode: "PI",
            modifierDescription:
              "Positron emission tomography (pet) or pet/computed tomography (ct) to inform the initial treatment strategy of tumors that are biopsy proven or strongly suspected of being cancerous based on other diagnostic testing",
            price: 11,
            cmsFee: 11,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "G89.2",
                description: '"CHRONIC PAIN',
              },
            ],
            procedures: [
              {
                code: "66987",
                description:
                  '"Complex 1-stage extracapsular cataract removal by irrigation and aspiration',
              },
            ],
            prescriptionMedications: [
              {
                code: "0268-1165",
                description: "10 mL in 1 VIAL, MULTI-DOSE (0268-1165-10) ",
              },
            ],
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5eed000fafa02600017d59bd",
          modifier: "System",
          creator: "System",
          created: "2020-06-19T18:12:31.414Z",
          modified: "2020-07-14T13:24:14.903Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ef21ae17aadc30001a0c47c",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:08:17.305Z",
      modified: "2020-06-23T15:08:17.305Z",
    },
    {
      shortName: "Adei's Test Panel",
      longName: "Adei's Test Panel description",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5eed000fafa02600017d59bd", "5eedaf4c02dd69a0d81085ae"],
      labTests: [
        {
          loinc: "88191-2",
          orderableName: "3333",
          shortDescription: "Short Desk!",
          longDescription: "Long Desk!",
          labTestId: "5eecda3dafa02600017d59bc",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df9e19e897caaccf08c6",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "333333",
          price: 11,
          statesApplicable: ["AL", "AK", "AS"],
          keywords: ["test", "123"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96138",
            cptDescription:
              '"Administration and scoring of psychological tests by technician',
            modifierCode: "PI",
            modifierDescription:
              "Positron emission tomography (pet) or pet/computed tomography (ct) to inform the initial treatment strategy of tumors that are biopsy proven or strongly suspected of being cancerous based on other diagnostic testing",
            price: 11,
            cmsFee: 11,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "G89.2",
                description: '"CHRONIC PAIN',
              },
            ],
            procedures: [
              {
                code: "66987",
                description:
                  '"Complex 1-stage extracapsular cataract removal by irrigation and aspiration',
              },
            ],
            prescriptionMedications: [
              {
                code: "0268-1165",
                description: "10 mL in 1 VIAL, MULTI-DOSE (0268-1165-10) ",
              },
            ],
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5eed000fafa02600017d59bd",
          modifier: "System",
          creator: "System",
          created: "2020-06-19T18:12:31.414Z",
          modified: "2020-07-14T13:24:14.903Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ef21a337aadc30001a0c47b",
      modifier: "System",
      creator: "System",
      created: "2020-06-23T15:05:23.718Z",
      modified: "2020-06-23T15:05:23.718Z",
    },
    {
      shortName: "Covid-Diabetes",
      longName: "Covid and Diabetes joint test",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd252544b4557ecc808f02", "5ec408957cc9c9000170e5a2"],
      labTests: [
        {
          loinc: null,
          orderableName: "QuestCovid19_test",
          shortDescription: null,
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ebd241e44b4557ecc808eff",
          specimenTypeId: null,
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 120.5,
          vendorTestID: "QuestCovid57",
          price: 52,
          statesApplicable: ["CA", "LA", "NY", "AL"],
          keywords: ["covid19"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ebd252544b4557ecc808f02",
          modifier: "System",
          creator: "",
          created: "2020-05-14T12:12:37.18Z",
          modified: "2020-05-20T11:28:36.925Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ec52eb85f79f70001e7423e",
      modifier: "System",
      creator: "System",
      created: "2020-05-20T13:20:56.197Z",
      modified: "2020-05-20T13:20:56.197Z",
    },
    {
      shortName: "My Custom Test Panel",
      longName: "My Custom Test Panel's Description",
      shortDescription: "Short Description for the Diagnostic Profile",
      longDescription: "Long Description for the Diagnostic Profile",
      labTestIds: ["5eedcb1cafa02600017d59bf", "5f02ffb72ffc6d00016fd51c"],
      labTests: [
        {
          loinc: "94563-4",
          orderableName: "Covid-19-Nasopharyngeal",
          shortDescription: "testCovid",
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23f844b4557ecc808efe",
          vendorDetail: null,
          testFormatId: "5ee1e06b19e897caaccf08cc",
          specimenTypeId: "5ee1df8419e897caaccf08c5",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "MTLTest",
          price: 90,
          statesApplicable: [
            "AL",
            "AK",
            "AS",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "FM",
            "FL",
            "GA",
            "GU",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MH",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "MP",
            "OH",
            "OK",
            "OR",
            "PW",
            "PA",
            "PR",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VI",
            "VA",
            "WA",
            "WV",
            "WI",
            "WY",
          ],
          keywords: null,
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "95803",
            cptDescription: '"Actigraphy testing',
            modifierCode: "CD",
            modifierDescription:
              "CD - Amcc test has been ordered by an esrd facility or mcp physician that is part of the composite rate and is not separately billable",
            price: 90,
            cmsFee: 50,
            taxable: true,
          },
          association: {
            conditions: [
              {
                code: "",
                description: "",
              },
            ],
            procedures: [
              {
                code: "",
                description: "",
              },
            ],
            prescriptionMedications: [
              {
                code: "",
                description: "",
              },
            ],
            otcMedications: [
              {
                code: "",
                description: "",
              },
            ],
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: "days",
          frequencyValue: 10,
          processingTimeUnit: "minutes",
          processingTimeValue: 10,
          _id: "5f02ffb72ffc6d00016fd51c",
          modifier: "System",
          creator: "System",
          created: "2020-07-06T10:40:55.665Z",
          modified: "2020-07-20T13:18:42.576Z",
        },
      ],
      keywords: ["diagnostic", "profile"],
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "20604",
        cptDescription:
          '"Aspiration and injection of bursa of finger with ultrasound guidance',
        modifierCode: "BR",
        modifierDescription:
          "BR - The beneficiary has been informed of the purchase and rental options and has elected to rent the item",
        price: 13,
        cmsFee: 13,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "T71.191A",
            description: "ASPX D/T MECH THRT TO BREATHE D/T OT COZ ACC INI",
          },
        ],
        procedures: [
          {
            code: "17110",
            description: '"Chemosurgery of benign lesions',
          },
        ],
        prescriptionMedications: [
          {
            code: "0067-2000",
            description:
              "1 BOTTLE in 1 CARTON (0067-2000-10)  > 10 TABLET, FILM COATED in 1 BOTTLE",
          },
        ],
        otcMedications: [
          {
            code: "0067-2001",
            description:
              "1 BOTTLE in 1 CARTON (0067-2001-05)  > 100 TABLET, FILM COATED in 1 BOTTLE",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: "5ebd23f844b4557ecc808efe",
      isActive: true,
      _id: "5f08573f3ca2bc000137d11e",
      modifier: "System",
      creator: "System",
      created: "2020-07-10T11:55:43.07Z",
      modified: "2020-07-10T13:51:35.571Z",
    },
    {
      shortName: "QuestCovidPanel",
      longName: "Panel for Quest Covid-19 testing",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd252544b4557ecc808f02"],
      labTests: [
        {
          loinc: null,
          orderableName: "QuestCovid19_test",
          shortDescription: null,
          longDescription: null,
          labTestId: "5ebd245c44b4557ecc808f01",
          labTest: null,
          vendorDetailId: "5ebd23d344b4557ecc808efd",
          vendorDetail: null,
          testFormatId: "5ebd241e44b4557ecc808eff",
          specimenTypeId: null,
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 120.5,
          vendorTestID: "QuestCovid57",
          price: 52,
          statesApplicable: ["CA", "LA", "NY", "AL"],
          keywords: ["covid19"],
          images: null,
          articles: null,
          videos: null,
          billingDetail: null,
          association: null,
          isActive: true,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ebd252544b4557ecc808f02",
          modifier: "System",
          creator: "",
          created: "2020-05-14T12:12:37.18Z",
          modified: "2020-05-20T11:28:36.925Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ebd25a344b4557ecc808f03",
      modifier: "System",
      creator: "System",
      created: "2020-05-14T11:04:03.746Z",
      modified: "2020-05-19T08:26:00.789Z",
    },
    {
      shortName: "QuestMensHealthPanel",
      longName: "Panel for Mens health exam tests",
      shortDescription: null,
      longDescription: null,
      labTestIds: ["5ebd30c6b507bf7df8230c4d", "5ebd3105b507bf7df8230c4e"],
      labTests: [],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: null,
      association: null,
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "5ebd315ab507bf7df8230c4f",
      modifier: "System",
      creator: "System",
      created: "2020-05-14T11:54:02.865Z",
      modified: "2020-05-14T11:54:02.865Z",
    },
    {
      shortName: "TestVendor Panel",
      longName: "Panel for the TestVendor Lab",
      shortDescription: "Short desc for TestVendor Panel",
      longDescription: "Long desc for TestVendor Panel",
      labTestIds: ["5ef361082ffc6d00016fd51a"],
      labTests: [
        {
          loinc: "33463-1",
          orderableName: "SARS-CoV-2 RNA (COVID-19), QUALITATIVE NAA",
          shortDescription: "KLK",
          longDescription: "PPOPOIOIOPO",
          labTestId: "5ebd67c97c86d00001551d5d",
          labTest: null,
          vendorDetailId: "5ec37b5c319b9b02380fd724",
          vendorDetail: null,
          testFormatId: "5ebd242a44b4557ecc808f00",
          specimenTypeId: "5ee1dfb919e897caaccf08c7",
          specimenType: null,
          testFormat: null,
          uploadTimeoutInSeconds: 0,
          vendorTestID: "39448",
          price: 55,
          statesApplicable: ["KY", "LA", "ME"],
          keywords: null,
          images: null,
          articles: null,
          videos: null,
          billingDetail: {
            cptCode: "96136",
            cptDescription:
              '"Administration and scoring of neuropsychological tests by qualified health care professional',
            modifierCode: "CE",
            modifierDescription:
              "Amcc test has been ordered by an esrd facility or mcp physician that is a composite rate test but is beyond the normal frequency covered under the rate and is separately reimbursable based on medical necessity",
            price: 55,
            cmsFee: 55,
            taxable: true,
          },
          association: {
            conditions: null,
            procedures: null,
            prescriptionMedications: null,
            otcMedications: null,
          },
          isActive: false,
          introWidgetIds: null,
          introWidgets: null,
          kitRegistrationRequired: false,
          kitManufacturer: null,
          instructions: null,
          customTestName: null,
          testImageAssetHeight: 0,
          testImageAssetWidth: 0,
          frequencyUnit: null,
          frequencyValue: 0,
          processingTimeUnit: null,
          processingTimeValue: 0,
          _id: "5ef361082ffc6d00016fd51a",
          modifier: "System",
          creator: "System",
          created: "2020-06-24T14:19:52.035Z",
          modified: "2020-07-14T13:25:48.761Z",
        },
      ],
      keywords: null,
      images: null,
      articles: null,
      videos: null,
      billingDetail: {
        cptCode: "96138",
        cptDescription:
          '"Administration and scoring of neuropsychological tests by technician',
        modifierCode: "CE",
        modifierDescription:
          "Amcc test has been ordered by an esrd facility or mcp physician that is a composite rate test but is beyond the normal frequency covered under the rate and is separately reimbursable based on medical necessity",
        price: 11,
        cmsFee: 2,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "S30.813S",
            description: '"ABRASION OF SCROTUM AND TESTES',
          },
        ],
        procedures: [
          {
            code: "96137",
            description:
              '"Administration and scoring of neuropsychological tests by qualified health care professional',
          },
        ],
        prescriptionMedications: [
          {
            code: "66343-043",
            description: "50 mL in 1 BOTTLE (66343-043-50) ",
          },
        ],
        otcMedications: [
          {
            code: "63029-600",
            description: "24 POWDER in 1 BOX (63029-600-01) ",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: "5ec37b5c319b9b02380fd724",
      isActive: true,
      _id: "5ef361dc2ffc6d00016fd51b",
      modifier: "System",
      creator: "System",
      created: "2020-06-24T14:23:24.112Z",
      modified: "2020-06-25T13:45:30.432Z",
    },
    {
      shortName: "string",
      longName: "string",
      shortDescription: "string",
      longDescription: "string",
      labTestIds: ["string"],
      labTests: [],
      keywords: ["string"],
      images: ["string"],
      articles: ["string"],
      videos: ["string"],
      billingDetail: {
        cptCode: "string",
        cptDescription: "string",
        modifierCode: "string",
        modifierDescription: "string",
        price: 0,
        cmsFee: 0,
        taxable: true,
      },
      association: {
        conditions: [
          {
            code: "string",
            description: "string",
          },
        ],
        procedures: [
          {
            code: "string",
            description: "string",
          },
        ],
        prescriptionMedications: [
          {
            code: "string",
            description: "string",
          },
        ],
        otcMedications: [
          {
            code: "string",
            description: "string",
          },
        ],
      },
      isDefault: false,
      vendorDetailId: null,
      isActive: true,
      _id: "string",
      modifier: "System",
      creator: "System",
      created: "2020-06-19T12:31:07.409Z",
      modified: "2020-06-19T12:31:07.409Z",
    },
  ],
};

export const getSelectedMock = {
  testOrderable: getPaginatedMock.testOrderable.map((r) => r._id).slice(1, 2),
  testPanels: getPaginatedMock.testPanels.map((r) => r._id).slice(0, 3),
};
