import * as types from "./UsersActions";

//reducer for shared actions
export const users = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_USERS_REQUEST:
      return {
        ...state,
        tenantUsersLoading: true,
      };

    case types.GET_TENANT_USERS_SUCCESS:
      return {
        ...state,
        tenantUsers: action.payload,
        tenantUsersLoading: false,
      };

    case types.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        userDetailsLoading: true,
      };

    case types.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        userDetailsLoading: false,
      };

    case types.GET_USER_REQUEST:
      return {
        ...state,
        userDetailsLoading: true,
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userDetailsLoading: false,
        generatingNhi: false,
      };

    case types.GET_USER_PIC_REQUEST:
      return {
        ...state,
        userPictureLoading: true,
      };

    case types.GET_USER_PIC_SUCCESS:
      return {
        ...state,
        profilePicture: action.payload,
        userPictureLoading: false,
      };

    case types.GET_OTHER_USER_PIC_REQUEST:
      return {
        ...state,
        userDetailsLoading: true,
      };

    case types.GET_OTHER_USER_PIC_SUCCESS:
      return {
        ...state,
        otherUser: action.payload.userId,
        otherUserProfilePic: action.payload.img,
        userDetailsLoading: false,
      };

    case types.GET_OTHER_USER_QR_REQUEST:
      return {
        ...state,
        userQRLoading: true,
        otherUserQR: null,
      };

    case types.GET_OTHER_USER_QR_SUCCESS:
      return {
        ...state,
        otherUser: action.payload.userId,
        otherUserQR: action.payload.img,
        userQRLoading: false,
      };

    case types.UPLOAD_USER_PIC_REQUEST:
      return {
        ...state,
      };

    case types.UPLOAD_USER_PIC_SUCCESS:
      return {
        ...state,
        profilePicture: action.payload,
      };

    case types.GET_USER_Q_REQUEST:
      return {
        ...state,
        tenantUsers: action.payload,
        userQuestionnaireLoading: true,
      };

    case types.GET_USER_Q_SUCCESS:
      return {
        ...state,
        userQuestionnaire: action.payload,
        userQuestionnaireLoading: false,
      };

    case types.GET_CURRENT_USER_PERMISSION_REQUEST:
      return {
        ...state,
        currentUserPermissionsLoading: true,
      };

    case types.GET_CURRENT_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        currentUserPermissions: action.payload,
        currentUserPermissionsLoading: false,
      };

    case types.GET_USER_PERMISSION_REQUEST:
      return {
        ...state,
        userPermissionsLoading: true,
      };

    case types.GET_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        userPermissions: action.payload,
        userPermissionsLoading: false,
      };

    case types.GET_USER_MESSAGES_REQUEST:
      return {
        ...state,
        userMessagesLoading: true,
      };

    case types.GET_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        userMessages: action.payload,
        userMessagesLoading: false,
      };

    case types.GET_USER_ACCOUNT_PERMISSION_REQUEST:
      return {
        ...state,
        userAccountPermissionLoading: true,
      };

    case types.GET_USER_ACCOUNT_PERMISSION_SUCCESS:
      return {
        ...state,
        userAccountPermission: action.payload,
        userAccountPermissionLoading: false,
      };

    case types.RESET_USERS:
      return {
        ...state,
        tenantUsers: {},
      };

    case types.RESET_BULK_UPLOAD_ERROR:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          error: null,
        },
      };

    case types.BULK_UPLOAD_USERS_REQUEST:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          submitting: true,
          error: null,
        },
      };

    case types.BULK_UPLOAD_USERS_PREVIEW:
      return {
        ...state,
        previewImport: action.payload,
      };

    case types.RESET_UPLOAD_USERS_PREVIEW:
      return {
        ...state,
        previewImport: null,
      };

    case types.BULK_UPLOAD_USERS_SUCCESS:
      const newImports =
        (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      newImports.push(action.payload);
      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports: newImports,
          submitting: false,
          error: null,
        },
      };

    case types.BULK_UPLOAD_USERS_FAILURE:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          submitting: false,
          error: action.error,
        },
      };

    case types.BULK_UPLOAD_INFO_SUCCESS:
      const imports =
        (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      const importIndex = imports.findIndex((i) => i.id === action.payload.id);

      if (importIndex > -1) {
        imports.splice(importIndex, 1);
      }
      imports.push(action.payload);

      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports,
        },
      };

    case types.BULK_USER_UPLOADS_INFO_SUCCESS:
      let existingImports =
        (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      let existingImportIds = existingImports.map((i) => i.id);
      let updatedImports =
        (action.payload &&
          action.payload.items &&
          action.payload.items.filter(
            (i) =>
              existingImportIds.includes(i.id) ||
              (i.uploadedBy === action.userId && i.status === "IN_PROGRESS")
          )) ||
        [];

      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports: updatedImports,
        },
      };

    case types.GENERATE_USER_NHI:
      return {
        ...state,
        generatingNhi: true,
      };

    default:
      return state;
  }
};
