import * as types from "./AccountActions";

//reducer for shared actions
export const account = (state = [], action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        details: action.payload,
        loading: false,
      };

    case types.GET_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.UPDATE_ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case types.UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        updating: false,
        details: action.payload,
      };
    case types.UPDATE_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.error,
      };

    default:
      return state;
  }
};
