import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import history from "../Shared/Redux/history";

// redux
import { bindActionCreators } from "redux";
import * as sharedActions from "../Shared/SharedActions";
import * as alertActions from "../Alerts/AlertActions";
import * as userActions from "../Users/UsersActions";
import * as settingsActions from "../Settings/SettingsActions";
import * as networkActions from "../Networks/NetworksActions";
import * as accountActions from "../Account/AccountActions";
import * as virtualConsultActions from "../VirtualConsult/VirtualConsultActions";
import { connect } from "react-redux";

//component imports
import NavBarStyles from "../../assets/jss/components/NavBarStyles";
import logo from "../../assets/img/logo.svg";
// import logo from "../../assets/img/protectwell_logo.svg";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import AlertMenu from "./AlertMenu";
import AccessControl from "../Shared/AccessControl";
import TenantDropdown from "./TenantDropdown";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

export const Navbar = (props) => {
  const {
    classes,
    currentTenant,
    currentNetwork,
    currentNetworkLoading,
    sharedActions,
    allTenantsLoading,
    allTenants,
    tenantAlerts,
    alertActions,
    userActions,
    settingsActions,
    networkActions,
    profilePicture,
    currentUser,
    tenantImports,
    accountActions,
    account,
    virtualConsultActions,
  } = props;

  const [currentTenantId, setCurrentTenantId] = useState("");
  const [unreadAlert, setUnreadAlert] = useState(false);
  const { isAuthenticated, logout, user } = useAuth0();

  //url for homepage
  const homePage = `/${currentTenantId}`;

  useEffect(() => {
    if (user) {
      userActions.getCurrentUser(user.email);
      sharedActions.getMyTenants();
      accountActions.getAccountDetails();
    }
  }, [sharedActions, user, userActions]);

  // check if profile picture, if no picture get for navbar image
  useEffect(() => {
    if (user && !profilePicture) {
      userActions.getUserPic(user.email, true);
    }
  }, [user, profilePicture]);

  useEffect(() => {
    if (
      currentTenant &&
      currentTenant.id &&
      !currentNetworkLoading &&
      (!currentNetwork || currentTenant.id !== currentNetwork.tenantID)
    ) {
      // Need to get network info
      networkActions.getNetworkByTenantId(currentTenant);
    }
  }, [currentTenant, currentNetwork, networkActions, currentNetworkLoading]);

  // update favicon when network changes
  useEffect(() => {
    if (
      currentNetwork &&
      !currentNetworkLoading &&
      currentTenant &&
      currentTenant.id === currentNetwork.tenantID
    ) {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      if (currentNetwork && currentNetwork.favicon) {
        link.href = currentNetwork.favicon;
      } else {
        link.href =
          "data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAF0lEQVRIx2NgGAWjYBSMglEwCkbBSAcACBAAAeaR9cIAAAAASUVORK5CYII=";
      }
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [currentNetwork]);

  useEffect(() => {
    let url = window.location.href;
    let urlTenant = url.split("/")[3];
    if (
      urlTenant &&
      !currentTenant &&
      allTenants &&
      allTenants.total > 0 &&
      user
    ) {
      for (let i = 0; i < allTenants.items.length; i++) {
        if (allTenants.items[i].id === urlTenant) {
          sharedActions.setCurrentTenant(allTenants.items[i]);
          getInitialData(allTenants.items[i].id);
          break;
        } else if (i === allTenants.items.length - 1) {
          sharedActions.setCurrentTenant("None");
        }
      }
    } else if (allTenants && allTenants.total > 0 && !currentTenant && user) {
      // sort all tenants array in alphabetical order
      let array = [...allTenants.items];
      let sorted = array.sort((a, b) => {
        let tenantA = a.title.toUpperCase();
        let tenantB = b.title.toUpperCase();
        return tenantA < tenantB ? -1 : tenantA > tenantB ? 1 : 0;
      });
      for (let i = 0; i < allTenants.items.length; i++) {
        // check if root tenant selected, if so set as current tenant
        if (allTenants.items[i].root === true) {
          sorted.splice(i, 1);
          sorted.unshift(allTenants.items[i]);
          break;
        }
      }
      sharedActions.setCurrentTenant(sorted[0]);
      getInitialData(sorted[0].id);
      history.push(`/${sorted[0].id}`);
    } else if (
      currentTenant &&
      currentTenant !== "None" &&
      !currentTenantId &&
      user
    ) {
      getInitialData(currentTenant.id);
      history.push(`/${currentTenant.id}`);
    } else if (allTenants && allTenants.total === 0) {
      sharedActions.setCurrentTenant("None");
      history.push(`/`);
    }
  }, [
    alertActions,
    allTenants,
    currentTenant,
    currentTenantId,
    settingsActions,
    sharedActions,
    user,
    userActions,
  ]);

  // getting all initial data on load of application, or tenant change
  const getInitialData = (tenant) => {
    virtualConsultActions.getUserSegments(tenant);
    settingsActions.getTenantSettings(tenant);
    userActions.getUserPermissions(tenant, user && user.name, true);
    userActions.getUserAccountPermission();
    setCurrentTenantId(tenant);
    getAlerts(tenant);
  };

  // on alert update, check for any unread alerts to trigger alert badge
  useEffect(() => {
    tenantAlerts &&
      tenantAlerts.items.map((alert) => {
        if (!alert.read) {
          setUnreadAlert(true);
        }
        return null;
      });
  }, [tenantAlerts]);

  const getAlerts = useCallback(
    (currentTenantId) => {
      if (currentTenantId) {
        alertActions.getAlerts(
          currentTenantId,
          "menuAlerts",
          0,
          window.outerWidth < 961 ? 6 : 10,
          "",
          "time",
          "DESC"
        );
      }
    },
    [alertActions]
  );

  useEffect(() => {
    if (currentTenant && currentTenant.id && user && user.email) {
      const interval = setInterval(() => {
        getAlerts(currentTenant.id);
        userActions.getUserBulks(
          currentTenant,
          user.email,
          0,
          10,
          "started",
          "DESC"
        );
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [currentTenant, userActions, user, getAlerts]);

  // change current tenant
  const handleChangeTenant = (value) => {
    setCurrentTenantId(value);
    allTenants.items.map((tenant) => {
      if (tenant.id === value) {
        // reset some redux content to not carry over into newly selected tenant
        userActions.resetUsers();
        settingsActions.resetSettings();
        sharedActions.setCurrentTenant(tenant);
        getInitialData(tenant.id);
      }
      return null;
    });
    history.push(`/${value}`);
  };

  const handleReadAlerts = () => {
    let pageSize;
    if (window.outerWidth < 961) {
      pageSize = 6;
    } else {
      pageSize = 10;
    }
    alertActions.readAllAlerts(currentTenantId, pageSize);
    setUnreadAlert(false);
  };

  if (!isAuthenticated || !currentTenant || !currentUser) {
    return null;
  }
  return (
    <AppBar className={classes.navBar} position="fixed">
      <Toolbar>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container spacing={4} alignItems={"center"}>
              <Grid item>
                <Link to={homePage}>
                  <img
                    className={classes.logo}
                    src={(currentTenant && currentTenant.logoURL) || logo}
                    alt="Tenant Logo"
                  />
                </Link>
              </Grid>
              <Grid item className={classes.tenantGrid} xs={8}>
                <TenantDropdown
                  currentTenant={currentTenant}
                  handleChangeTenant={handleChangeTenant}
                  allTenantsLoading={allTenantsLoading}
                  allTenants={allTenants}
                  account={account}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.navigationLinks} item>
            <div className={classes.sectionDesktop}>
              <AccessControl
                allowedPermissions={["ADMINISTRATOR"]}
                renderNoAccess={() => <div></div>}
              >
                <AlertMenu
                  alerts={tenantAlerts}
                  imports={tenantImports}
                  currentTenant={currentTenant && currentTenant.id}
                  handleReadAlerts={handleReadAlerts}
                  unreadAlert={unreadAlert}
                />
              </AccessControl>
              <DesktopMenu
                currentTenant={currentTenant}
                user={currentUser}
                logout={() => logout()}
              />
            </div>
            <div className={classes.sectionMobile}>
              <AccessControl
                allowedPermissions={["ADMINISTRATOR"]}
                renderNoAccess={() => <div></div>}
              >
                <AlertMenu
                  alerts={tenantAlerts}
                  imports={tenantImports}
                  currentTenant={currentTenant && currentTenant.id}
                  handleReadAlerts={handleReadAlerts}
                  unreadAlert={unreadAlert}
                />
              </AccessControl>
              <MobileMenu
                user={currentUser}
                currentTenant={currentTenant}
                handleChangeTenant={handleChangeTenant}
                allTenantsLoading={allTenantsLoading}
                allTenants={allTenants}
                logout={() => logout()}
              />
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    allTenantsLoading: state.shared.allTenantsLoading,
    allTenants: state.shared.allTenants,
    currentTenant: state.shared.currentTenant,
    tenantAlerts: state.alerts.menuAlerts,
    tenantImports: state.users && state.users.bulk && state.users.bulk.imports,
    profilePicture: state.users.profilePicture,
    currentUser: state.users.currentUser,
    currentNetwork: state.network.tenantNetwork,
    currentNetworkLoading: state.network.tenantNetworkLoading,
    account: state.account && state.account.details,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    networkActions: bindActionCreators(networkActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
    virtualConsultActions: bindActionCreators(virtualConsultActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NavBarStyles)(Navbar));
