import * as types from "./PermissionActions";

//reducer for shared actions
export const accountPermissions = (state = [], action) => {
  switch (action.type) {
    case types.GET_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };

    case types.GET_PERMISSION_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.SET_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        submitting: true,
        submitError: null,
      };

    case types.SET_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case types.SET_PERMISSION_LIST_FAILURE:
      return {
        ...state,
        submitting: false,
        submitError: action.error,
      };

    case types.SET_PERMISSION_LIST_RESET:
      return {
        ...state,
        submitting: false,
        submitError: null,
      };

    case types.REMOVE_PERMISSION_REQUEST:
      return {
        ...state,
        removing: true,
        removeError: null,
      };
    case types.REMOVE_PERMISSION_SUCCESS:
      return {
        ...state,
        removing: false,
      };
    case types.REMOVE_PERMISSION_FAILURE:
      return {
        ...state,
        removing: false,
        removeError: action.error,
      };

    default:
      return state;
  }
};
