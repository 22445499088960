import React from "react";
import PropTypes from "prop-types";

//redux additions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as confirmActions from "../Confirmation/ConfirmationActions";
import * as settingsActions from "../Settings/SettingsActions";
import * as userActions from "../Users/UsersActions";
import * as contentActions from "../Content/ContentActions";
import * as virtualConsultActions from "../VirtualConsult/VirtualConsultActions";
import * as dashboardActions from "../Dashboard/DashboardActions";
import * as networksActions from "../Networks/NetworksActions.js";

//material-ui components
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

//actions taken upon confirmation/cancellation of an action
export const ConfirmationDialog = (props) => {
  const {
    confirmation,
    actions,
    settingsActions,
    userActions,
    contentActions,
    virtualConsultActions,
    dashboardActions,
    networksActions,
  } = props;

  //cancelling an action event response
  const handleCancel = (event) => {
    if (event) {
      event.preventDefault();
    }
    actions.hideConfirmation();
  };

  //confirming an action event response
  const handleOk = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (confirmation.confirmationType === "tierDelete") {
      settingsActions.deleteTenantTier(confirmation.tenant, confirmation.id);
    } else if (confirmation.confirmationType === "userDeleteAccount") {
      userActions.deleteUserAccount(confirmation.id, confirmation.tenant);
    } else if (confirmation.confirmationType === "userRemoveTenant") {
      userActions.deleteUserTenant(confirmation.id, confirmation.tenant);
    } else if (confirmation.confirmationType === "faqDelete") {
      settingsActions.updateFaq(
        confirmation.tenant,
        confirmation.id,
        confirmation.confirmationType
      );
    } else if (confirmation.confirmationType === "verbiageDelete") {
      settingsActions.updateVerbiage(
        confirmation.tenant,
        confirmation.id,
        confirmation.confirmationType
      );
    } else if (confirmation.confirmationType === "contentDelete") {
      contentActions.deleteContent(
        confirmation.contentType,
        confirmation.tenant,
        confirmation.id
      );
    } else if (confirmation.confirmationType === "fontDelete") {
      settingsActions.deleteFont(confirmation.tenant, confirmation.id);
    } else if (confirmation.confirmationType === "segmentDelete") {
      virtualConsultActions.deleteUserSegment(
        confirmation.tenant.id,
        confirmation.id
      );
    } else if (confirmation.confirmationType === "updateMetadataFilters") {
      dashboardActions.updateMetadataFiltersList(confirmation.id);
    } else if (confirmation.confirmationType === "metaDataKeyDelete") {
      networksActions.deleteMetaDataKey(
        confirmation.tenant.id,
        confirmation.id
      );
    }

    actions.hideConfirmation();
  };

  return (
    <Dialog
      open={confirmation.visible}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        {confirmation.title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{confirmation.message}</Typography>
      </DialogContent>
      <DialogActions>
        <div>
          {confirmation.confirmationType !== "info" && (
            <Button
              onClick={handleCancel}
              color="primary"
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
          )}
          <Button variant={"contained"} onClick={handleOk} color="primary">
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  confirmation: PropTypes.object,
};

ConfirmationDialog.defaultProps = {
  confirmation: {
    title: null,
    message: null,
    confirmationType: null,
    visible: false,
  },
};

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
});

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(confirmActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    contentActions: bindActionCreators(contentActions, dispatch),
    virtualConsultActions: bindActionCreators(virtualConsultActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    networksActions: bindActionCreators(networksActions, dispatch),
  };
}

//middleware
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConfirmationDialog));
