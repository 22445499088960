import * as types from "./RecordRequestActions";

//reducer for shared actions
export const recordRequest = (state = [], action) => {
  switch (action.type) {
    case types.GET_RECORD_REQUESTS:
      return {
        ...state,
        allRecordRequestsLoading: true,
      };

    case types.GET_RECORD_REQUESTS_SUCCESS:
      return {
        ...state,
        allRecordRequests: action.payload,
        allRecordRequestsLoading: false,
      };

    case types.GET_RECORD_REQUEST:
      return {
        ...state,
        recordRequestLoading: true,
      };

    case types.GET_RECORD_REQUEST_SUCCESS:
      return {
        ...state,
        recordRequest: action.payload,
        recordRequestLoading: false,
      };

    case types.GET_CCD:
      return {
        ...state,
        ccdLoading: true,
      };

    case types.GET_CCD_SUCCESS:
      return {
        ...state,
        ccd: action.payload,
        ccdLoading: false,
      };

    default:
      return state;
  }
};

export const providerList = (state = [], action) => {
  switch (action.type) {
    case types.SEARCH_PROVIDER_REQUEST:
      return {
        ...state,
        providerSearchLoading: true,
      };

    case types.SEARCH_PROVIDER_SUCCESS:
      return {
        ...state,
        providerList: action.payload,
        providerSearchLoading: false,
      };

    default:
      return state;
  }
};
