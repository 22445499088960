import axios from "axios";
import { stateCodeList } from "../Shared/Forms/helpers/stateCountryHelper";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANT_POPULATION_REQUEST = "GET_TENANT_POPULATION_REQUEST";
export const GET_TENANT_POPULATION_SUCCESS = "GET_TENANT_POPULATION_SUCCESS";
export const GET_TENANT_POPULATION_FAILURE = "GET_TENANT_POPULATION_FAILURE";

export const GET_CLEARANCE_STATUS_REQUEST = "GET_CLEARANCE_STATUS_REQUEST";
export const GET_CLEARANCE_STATUS_SUCCESS = "GET_CLEARANCE_STATUS_SUCCESS";
export const GET_CLEARANCE_STATUS_FAILURE = "GET_CLEARANCE_STATUS_FAILURE";

export const GET_CLEARANCE_STATUS_LAST_MONTH_REQUEST =
  "GET_CLEARANCE_STATUS_LAST_MONTH_REQUEST";
export const GET_CLEARANCE_STATUS_LAST_MONTH_SUCCESS =
  "GET_CLEARANCE_STATUS_LAST_MONTH_SUCCESS";
export const GET_CLEARANCE_STATUS_LAST_MONTH_FAILURE =
  "GET_CLEARANCE_STATUS_LAST_MONTH_FAILURE";

export const GET_PATIENT_TEST_STATUS_REQUEST =
  "GET_PATIENT_TEST_STATUS_REQUEST";
export const GET_PATIENT_TEST_STATUS_SUCCESS =
  "GET_PATIENT_TEST_STATUS_SUCCESS";
export const GET_PATIENT_TEST_STATUS_FAILURE =
  "GET_PATIENT_TEST_STATUS_FAILURE";

export const GET_QUESTIONNAIRE_COMPLETED_REQUEST =
  "GET_QUESTIONNAIRE_COMPLETED_REQUEST";
export const GET_QUESTIONNAIRE_COMPLETED_SUCCESS =
  "GET_QUESTIONNAIRE_COMPLETED_SUCCESS";
export const GET_QUESTIONNAIRE_COMPLETED_FAILURE =
  "GET_QUESTIONNAIRE_COMPLETED_FAILURE";

export const GET_MAP_INFO_REQUEST = "GET_MAP_INFO_REQUEST";
export const GET_MAP_INFO_SUCCESS = "GET_MAP_INFO_SUCCESS";
export const GET_MAP_INFO_FAILURE = "GET_MAP_INFO_FAILURE";

export const GET_SYMPTOMS_REQUEST = "GET_SYMPTOMS_REQUEST";
export const GET_SYMPTOMS_SUCCESS = "GET_SYMPTOMS_SUCCESS";
export const GET_SYMPTOMS_FAILURE = "GET_SYMPTOMS_FAILURE";

export const GET_PATIENT_TEST_STATUS_MONTH_REQUEST =
  "GET_PATIENT_TEST_STATUS_MONTH_REQUEST";
export const GET_PATIENT_TEST_STATUS_MONTH_SUCCESS =
  "GET_PATIENT_TEST_STATUS_MONTH_SUCCESS";
export const GET_PATIENT_TEST_STATUS_MONTH_FAILURE =
  "GET_PATIENT_TEST_STATUS_MONTH_FAILURE";

export const GET_USER_SUBMISSIONS_REQUEST = "GET_USER_SUBMISSIONS_REQUEST";
export const GET_USER_SUBMISSIONS_SUCCESS = "GET_USER_SUBMISSIONS_SUCCESS";
export const GET_USER_SUBMISSIONS_FAILURE = "GET_USER_SUBMISSIONS_FAILURE";

export const UPDATE_METADATA_FILTERS_LIST = "UPDATE_METADATA_FILTERS_LIST";

//=========Thunks===========

export const getPopulation = (tenant, metadataFilters) => async (dispatch) => {
  dispatch({ type: GET_TENANT_POPULATION_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/population${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_POPULATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_TENANT_POPULATION_FAILURE,
        error: error.response.data,
      });
    });
};

export const getStateInfo = (tenant, metadataFilters) => async (dispatch) => {
  dispatch({ type: GET_MAP_INFO_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  const requests = [];

  requests.push(
    axios({
      method: "GET",
      url: `${API_ROOT}/admin/dashboard/${tenant}/clearance-status/by-state${q}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token,
      },
    })
  );

  requests.push(
    axios({
      method: "GET",
      url: `${API_ROOT}/admin/dashboard/${tenant}/patient-test-status/by-state${q}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token,
      },
    })
  );

  try {
    const response = await Promise.all(requests);

    const aggregated = response[0].data.reduce((accum, value) => {
      const stateCode = value.state;
      const stateNumbCode =
        stateCodeList[stateCode] &&
        `${stateCodeList[stateCode]["Number Code"]}`;
      if (stateNumbCode) {
        const index = accum.findIndex((s) => s.id === stateNumbCode);
        if (index > -1) {
          accum[index] = {
            ...accum[index],
            [value.option]: value.count,
            total: accum[index].total + value.count,
          };
        } else {
          accum.push({
            id: stateNumbCode,
            [value.option]: value.count,
            total: value.count,
          });
        }
      }
      return accum;
    }, []);

    response[1].data.forEach((value) => {
      const stateCode = value.state;
      const stateNumbCode =
        stateCodeList[stateCode] &&
        `${stateCodeList[stateCode]["Number Code"]}`;
      if (stateNumbCode) {
        const index = aggregated.findIndex((s) => s.id === stateNumbCode);
        if (index > -1) {
          aggregated[index] = {
            ...aggregated[index],
            [value.result]: value.count,
          };
        } else {
          aggregated.push({
            id: stateNumbCode,
            [value.result]: value.count,
          });
        }
      }
    });

    dispatch({ type: GET_MAP_INFO_SUCCESS, payload: aggregated });
  } catch (error) {
    dispatch({ type: GET_MAP_INFO_FAILURE, error: error });
  }

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/population${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_POPULATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_TENANT_POPULATION_FAILURE,
        error: error.response ? error.response.data : error,
      });
    });
};

export const getClearanceStatus = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_CLEARANCE_STATUS_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/clearance-status${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CLEARANCE_STATUS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CLEARANCE_STATUS_FAILURE,
        error: error.response.data,
      });
    });
};

export const getPatientTestStatus = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_PATIENT_TEST_STATUS_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/patient-test-status${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PATIENT_TEST_STATUS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PATIENT_TEST_STATUS_FAILURE,
        error: error.response ? error.response.data : error,
      });
    });
};

export const getQuestionnaireCompleted = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_QUESTIONNAIRE_COMPLETED_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/questionnaire-completed${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_QUESTIONNAIRE_COMPLETED_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_QUESTIONNAIRE_COMPLETED_FAILURE,
        error: error.response.data,
      });
    });
};

export const getSymptoms = (tenant, metadataFilters) => async (dispatch) => {
  dispatch({ type: GET_SYMPTOMS_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/symptoms/last-month${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_SYMPTOMS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SYMPTOMS_FAILURE,
        error: error.response ? error.response.data : error,
      });
    });
};

export const getPatientTestStatusMonth = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_PATIENT_TEST_STATUS_MONTH_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/patient-test-status/last-month${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PATIENT_TEST_STATUS_MONTH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PATIENT_TEST_STATUS_MONTH_FAILURE,
        error: error.response ? error.response.data : error,
      });
    });
};

export const getClearanceStatusLastMonth = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_CLEARANCE_STATUS_LAST_MONTH_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/clearance-status/last-month${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CLEARANCE_STATUS_LAST_MONTH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CLEARANCE_STATUS_LAST_MONTH_FAILURE,
        error: error.response.data,
      });
    });
};

export const getUserSubmissions = (tenant, metadataFilters) => async (
  dispatch
) => {
  dispatch({ type: GET_USER_SUBMISSIONS_REQUEST });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const q =
    metadataFilters && Object.keys(metadataFilters).length
      ? `?q=${encodeURI(JSON.stringify(metadataFilters))}`
      : "";

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/submissions/last-month${q}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SUBMISSIONS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_SUBMISSIONS_FAILURE,
        error: error.response.data,
      });
    });
};

export const getTenantMetadataKeys = (tenant) => async (dispatch) => {
  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/metadata-keys`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      return response.data.sort();
    })
    .catch((error) => {
      console.log(error.response.data);
      return null;
    });
};

export const getTenantMetadataValues = (tenant, metadataKey) => async (
  dispatch
) => {
  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/metadata-values/${metadataKey}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      return response.data.sort();
    })
    .catch((error) => {
      console.log(error.response.data);
      return null;
    });
};

export const updateMetadataFiltersList = (filtersList) => async (dispatch) => {
  dispatch({
    type: UPDATE_METADATA_FILTERS_LIST,
    // payload: filtersList.slice(0),
    payload: Object.assign({}, filtersList),
  });
};
