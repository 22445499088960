import * as types from "./MedicalCenterActions";

//reducer for shared actions
export const tenantLabTests = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_LAB_TESTS_REQUEST:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          loading: true,
          error: null,
        },
      };

    case types.GET_TENANT_LAB_TESTS_SUCCESS:
      if (action.pageNumber) {
        return {
          ...state,
          [action.testType]: {
            list: {
              ...action.payload,
              pageNumber: action.pageNumber,
            },
            loading: false,
          },
        };
      }
      return {
        ...state,
        [action.testType]: {
          list: action.payload,
          loading: false,
        },
      };
    case types.GET_TENANT_LAB_TESTS_FAILURE:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          error: action.error,
          loading: false,
        },
      };

    case types.UPDATE_LAB_TESTS_REQUEST:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          submitting: true,
        },
      };

    case types.NEW_LAB_TESTS_SUCCESS:
    case types.NEW_LAB_TESTS_FAILURE:
    case types.UPDATE_LAB_TESTS_SUCCESS:
    case types.UPDATE_LAB_TESTS_FAILURE:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          submitting: false,
        },
      };

    default:
      return state;
  }
};
