// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import AccessControl from "./AccessControl";
import NoAccessView from "../../views/NoAccessView";

const PrivateRoute = ({ component: Component, path, allowed, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  // get current pathname to check with access control if page shown/hidden before rendering
  let url = window.location.pathname;
  let currentPageArray = url.split("/").slice(2);
  let currentPage;
  // check if url is extension of route for create/details/import. if so, block if root is hidden
  if (
    currentPageArray.includes("create") ||
    currentPageArray.includes("details") ||
    currentPageArray.includes("import")
  ) {
    currentPage = currentPageArray[0];
  } else {
    currentPage = currentPageArray.join("/");
  }

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props) =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return (
    <AccessControl
      allowedPermissions={allowed || ["ADMINISTRATOR"]}
      currentPage={currentPage}
      renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
    >
      <Route path={path} render={render} {...rest} />
    </AccessControl>
  );
};

export default PrivateRoute;
