import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import { getMyTenants, setCurrentTenant } from "../Shared/SharedActions";
import history from "../Shared/Redux/history";
import FileDownload from "js-file-download";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

// ***************** QUESTIONNAIRE OUTCOMES TYPES ***************** //
export const GET_TENANT_SETTINGS_REQUEST = "GET_TENANT_SETTINGS_REQUEST";
export const GET_TENANT_SETTINGS_SUCCESS = "GET_TENANT_SETTINGS_SUCCESS";

// ***************** TEMPLATES TYPES ***************** //
export const GET_ALL_TENANT_TEMPLATES_REQUEST =
  "GET_ALL_TENANT_TEMPLATES_REQUEST";
export const GET_ALL_TENANT_TEMPLATES_SUCCESS =
  "GET_ALL_TENANT_TEMPLATES_SUCCESS";
export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_REQUEST = "SAVE_TEMPLATE_REQUEST";
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_ERROR = "SAVE_TEMPLATE_ERROR";
export const GET_TEMPLATE_SETTINGS_REQUEST = "GET_TEMPLATE_SETTINGS_REQUEST";
export const GET_TEMPLATE_SETTINGS_SUCCESS = "GET_TEMPLATE_SETTINGS_SUCCESS";
export const SAVE_TEMPLATE_SETTINGS_REQUEST = "SAVE_TEMPLATE_SETTINGS_REQUEST";
export const SAVE_TEMPLATE_SETTINGS_SUCCESS = "SAVE_TEMPLATE_SETTINGS_SUCCESS";
export const SAVE_TEMPLATE_SETTINGS_ERROR = "SAVE_TEMPLATE_SETTINGS_ERROR";
export const TEST_TEMPLATE_REQUEST = "TEST_TEMPLATE_REQUEST";
export const TEST_TEMPLATE_SUCCESS = "TEST_TEMPLATE_SUCCESS";
export const TEST_TEMPLATE_FAILURE = "TEST_TEMPLATE_FAILURE";

// ***************** FAQ TYPES ***************** //
export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const SUBMIT_FAQ_CHANGE_REQUEST = "SUBMIT_FAQ_CHANGE_REQUEST";
export const SUBMIT_FAQ_CHANGE_COMPLETION = "SUBMIT_FAQ_CHANGE_COMPLETION";

// ***************** VERBIAGE TYPES ***************** //
export const GET_VERBIAGE_REQUEST = "GET_VERBIAGE_REQUEST";
export const GET_VERBIAGE_SUCCESS = "GET_VERBIAGE_SUCCESS";
export const GET_VERBIAGE_DEFINITION_SUCCESS =
  "GET_VERBIAGE_DEFINITION_SUCCESS";
export const SUBMIT_VERBIAGE_CHANGE_REQUEST = "SUBMIT_VERBIAGE_CHANGE_REQUEST";
export const SUBMIT_VERBIAGE_CHANGE_COMPLETION =
  "SUBMIT_VERBIAGE_CHANGE_COMPLETION";

// ***************** SHARED THUNK ***************** //
export const GET_SETTINGS_DATA_REQUEST = "GET_SETTINGS_DATA_REQUEST";

// ***************** FONTS TYPES ***************** //
export const GET_ALL_TENANT_FONTS_REQUEST = "GET_ALL_TENANT_FONTS_REQUEST";
export const GET_ALL_TENANT_FONTS_SUCCESS = "GET_ALL_TENANT_FONTS_SUCCESS";
export const CREATE_NEW_FONT_REQUEST = "CREATE_NEW_FONT_REQUEST";
export const CREATE_NEW_FONT_COMPLETION = "CREATE_NEW_FONT_COMPLETION";
export const GET_FONT_DETAILS_REQUEST = "GET_FONT_DETAILS_REQUEST";
export const GET_FONT_DETAILS_SUCCESS = "GET_FONT_DETAILS_SUCCESS";
export const UPDATE_FONT_REQUEST = "UPDATE_FONT_REQUEST";
export const UPDATE_FONT_SUCCESS = "UPDATE_FONT_SUCCESS";
export const UPDATE_FONT_ERROR = "UPDATE_FONT_ERROR";
export const UPDATE_FONT_FACE_REQUEST = "UPDATE_FONT_FACE_REQUEST";
export const UPDATE_FONT_FACE_COMPLETION = "UPDATE_FONT_FACE_COMPLETION";
export const UPDATE_FONT_FACE_ERROR = "UPDATE_FONT_FACE_ERROR";
export const UPLOAD_FONT_FACE_FILE_REQUEST = "UPLOAD_FONT_FACE_FILE_REQUEST";
export const UPLOAD_FONT_FACE_FILE_SUCCESS = "UPLOAD_FONT_FACE_FILE_SUCCESS";
export const UPLOAD_FONT_FACE_FILE_ERROR = "UPLOAD_FONT_FACE_FILE_ERROR";
export const DELETE_FONT_FACE_REQUEST = "DELETE_FONT_FACE_REQUEST";
export const DELETE_FONT_FACE_SUCCESS = "DELETE_FONT_FACE_SUCCESS";

export const GET_CUSTOM_FONTS_LIST_REQUEST = "GET_CUSTOM_FONTS_LIST_REQUEST";
export const GET_CUSTOM_FONTS_LIST_COMPLETION =
  "GET_CUSTOM_FONTS_LIST_COMPLETION";

export const RESET_SETTINGS = "RESET_SETTINGS";

export const resetSettings = () => ({
  type: RESET_SETTINGS,
});

//=========Thunks===========
// ***************** TENANT SETTINGS ENDPOINTS ***************** //
export const getTenantSettings = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANT_SETTINGS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/tenant/${tenant}/config`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_SETTINGS_SUCCESS,
        payload: response.data,
      });
      dispatch(getDefaultMenuItems(tenant, response.data));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateTenantSettings = (tenant, config, type) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANT_SETTINGS_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/tenant/${tenant}/config`,
    data: config,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      if (type === "outcomes") {
        dispatch(showNotification("Outcome saved successfully", "success"));
      }
      dispatch({
        type: GET_TENANT_SETTINGS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getDefaultMenuItems = (tenant, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANT_SETTINGS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/tenant/menu-items`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      let newData = Object.assign({}, data);
      if (data && data.menuItems) {
        let newMenuItems = {};
        let currentMenuKeys = Object.keys(data.menuItems);
        let defaultMenuKeys = Object.keys(response.data);
        for (let i = 0; i < defaultMenuKeys.length; i++) {
          newMenuItems[defaultMenuKeys[i]] = currentMenuKeys.includes(
            defaultMenuKeys[i]
          )
            ? data["menuItems"][defaultMenuKeys[i]]
            : true;
        }
        newData["menuItems"] = newMenuItems;
      } else if (data && !data.menuItems) {
        newData["menuItems"] = response.data;
      }
      dispatch(updateTenantSettings(tenant, newData, "defaultMenu"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** TEMPLATE ENDPOINTS ***************** //
export const getAllTemplates = (
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ALL_TENANT_TEMPLATES_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/comm-templates/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
      type: filter,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_ALL_TENANT_TEMPLATES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getTemplate = (tenant, id) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TEMPLATE_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TEMPLATE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getTemplateSettings = (tenant, id) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TEMPLATE_SETTINGS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/${id}/settings`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TEMPLATE_SETTINGS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const saveTemplate = (tenant, templateId, tpl) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: SAVE_TEMPLATE_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/${templateId}`,
    data: tpl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SAVE_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("Template successfully saved", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: SAVE_TEMPLATE_ERROR,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const testTemplate = (tenant, templateId, email) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: TEST_TEMPLATE_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/${templateId}/test`,
    params: {
      email,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: TEST_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("Template successfully sent", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: TEST_TEMPLATE_FAILURE,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const saveTemplateSettings = (tenant, id, settings) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TEMPLATE_SETTINGS_REQUEST });
  dispatch({ type: SAVE_TEMPLATE_SETTINGS_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/${id}/settings`,
    data: settings,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({ type: SAVE_TEMPLATE_SETTINGS_SUCCESS });
      dispatch({
        type: GET_TEMPLATE_SETTINGS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        showNotification("Template settings successfully saved", "success")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({ type: SAVE_TEMPLATE_SETTINGS_ERROR });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** FAQ ENDPOINTS ***************** //
export const getFaqs = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_FAQ_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/faq/${tenant}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_FAQ_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateFaq = (tenant, faq, type) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_FAQ_REQUEST });
  dispatch({ type: SUBMIT_FAQ_CHANGE_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/faq/${tenant}`,
    data: faq,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_FAQ_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: SUBMIT_FAQ_CHANGE_COMPLETION });
      if (type && type === "faqDelete") {
        dispatch(showNotification("Successfully deleted FAQ", "success"));
      } else {
        dispatch(showNotification("Successfully updated FAQs", "success"));
      }
    })
    .catch((error) => {
      console.log("ERROR", error);

      dispatch({ type: SUBMIT_FAQ_CHANGE_COMPLETION });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** VERBIAGE ENDPOINTS ***************** //
export const getVerbiage = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_VERBIAGE_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/verbiage/${tenant}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_VERBIAGE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getVerbiageDefinitions = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/verbiage/${tenant}/definition`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_VERBIAGE_DEFINITION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateVerbiage = (tenant, verbiage, type) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_VERBIAGE_REQUEST });
  dispatch({ type: SUBMIT_VERBIAGE_CHANGE_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/verbiage/${tenant}`,
    data: verbiage,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_VERBIAGE_SUCCESS,
        payload: response.data,
      });

      dispatch({ type: SUBMIT_VERBIAGE_CHANGE_COMPLETION });
      if (type && type === "verbiageDelete") {
        dispatch(showNotification("Verbiage successfully deleted.", "success"));
      } else {
        dispatch(showNotification("Verbiage successfully updated.", "success"));
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({ type: SUBMIT_VERBIAGE_CHANGE_COMPLETION });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const archiveVerbiageConsent = (tenant, verbiageKey) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/consent/${tenant}/archive`,
    params: {
      verbiageKey,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Related consent successfully archived.", "success")
      );
      return response.data;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const previewVerbiage = (tenant, verbiage) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/verbiage/${tenant}`,
    data: verbiage,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
// ***************** TENANT ENDPOINTS ***************** //
export const updateTenant = (tenant, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SETTINGS_DATA_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/tenant/${tenant}`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(setCurrentTenant(response.data));
      dispatch(getMyTenants());
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateRootTemplateTenant = (tenant, type) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SETTINGS_DATA_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/tenant/${tenant}/${type}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(setCurrentTenant(response.data));
      dispatch(getMyTenants());
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** FONTS ENDPOINTS ***************** //
export const getAllFonts = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ALL_TENANT_FONTS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      let reversed = response.data && response.data.length ? response.data : [];

      dispatch({
        type: GET_ALL_TENANT_FONTS_SUCCESS,
        payload: {
          items: reversed.reverse(),
          total: reversed.length,
        },
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const addNewFont = (tenant, name, fallBackFonts) => async (
  dispatch,
  getState
) => {
  const { tenantFonts } = getState().settings;
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: CREATE_NEW_FONT_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId`,
    data: {
      name,
      fallBackFonts,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      const updatedFontsList =
        tenantFonts && tenantFonts.items ? tenantFonts.items : [];
      updatedFontsList.unshift(response.data);

      dispatch({
        type: CREATE_NEW_FONT_COMPLETION,
        payload: {
          items: updatedFontsList,
          total: updatedFontsList.length,
        },
      });
      dispatch(showNotification("New font saved", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);

      dispatch({ type: CREATE_NEW_FONT_COMPLETION });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getFontDetails = (tenant, fontId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_FONT_DETAILS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_FONT_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateFontData = (tenant, fontId, name, fallBackFonts) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPDATE_FONT_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}`,
    data: {
      name,
      fallBackFonts,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_FONT_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("Font successfully saved", "success"));

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({ type: UPDATE_FONT_ERROR });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      return false;
    });
};

export const deleteFont = (tenant, fontId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      const fontsListLink = `/${tenant}/settings/configuration/assets/fonts`;
      dispatch(showNotification("Font deleted", "success"));

      if (window.location.pathname === fontsListLink) {
        dispatch(getAllFonts(tenant));
      } else {
        history.push(fontsListLink);
      }
    })
    .catch((error) => {
      console.log("ERROR", error);

      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateFontFace = (
  tenant,
  fontId,
  fontFaceId,
  weight,
  style
) => async (dispatch, getState) => {
  const { fontDetails } = getState().settings;
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  fontFaceId = fontFaceId ? `/${fontFaceId}` : "";

  dispatch({ type: UPDATE_FONT_FACE_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}/face${fontFaceId}`,
    data: {
      weight,
      style,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      const updatedFontFacesList = Object.assign({}, fontDetails);
      const updatedFontFaceId = Object.keys(response.data)[0];
      updatedFontFacesList.faces[updatedFontFaceId] =
        response.data[updatedFontFaceId];

      dispatch({
        type: UPDATE_FONT_FACE_COMPLETION,
        payload: updatedFontFacesList,
      });
      dispatch(
        showNotification(
          `Font-face successfully ${!fontFaceId ? "added" : "updated"}`,
          "success"
        )
      );
      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);

      dispatch({ type: UPDATE_FONT_FACE_ERROR });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const deleteFontFace = (tenant, fontId, fontFaceId) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({
    type: DELETE_FONT_FACE_REQUEST,
  });

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}/face/${fontFaceId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: DELETE_FONT_FACE_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification(`Font-face deleted`, "success"));
      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);

      // dispatch({ type: UPDATE_FONT_FACE_COMPLETION });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const uploadFontFaceFile = (
  tenant,
  fontId,
  fontFaceId,
  fileData
) => async (dispatch, getState) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: UPLOAD_FONT_FACE_FILE_REQUEST });

  const formData = new FormData();
  formData.append("fontFile", fileData);

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/byId/${fontId}/face/${fontFaceId}/file`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPLOAD_FONT_FACE_FILE_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification(`File successfully uploaded`, "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({ type: UPLOAD_FONT_FACE_FILE_ERROR });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getCustomFontsList = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_CUSTOM_FONTS_LIST_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/preferences/${tenant}/fonts/list`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CUSTOM_FONTS_LIST_COMPLETION,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const exportZIPTemplates = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/comm-templates/${tenant.id}/export`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "blob",
  })
    .then((response) => {
      let tenantName = tenant && tenant.title.replace(/ /g, "_");
      FileDownload(response.data, `${tenantName}.smt`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Unable to download system messages. Please try again later.",
          "error"
        )
      );
    });
};

export const importZIPTemplates = (tenant, fileData) => async (
  dispatch,
  getState
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  // dispatch({ type: UPLOAD_FONT_FACE_FILE_REQUEST });

  const formData = new FormData();
  formData.append("zip", fileData);

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/comm-templates/${tenant}/import`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification(`System messages successfully imported`, "success")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
