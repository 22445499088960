import React, { useEffect, useState } from "react";

import NavBarStyles from "../../assets/jss/components/NavBarStyles";
import { withStyles } from "@material-ui/core/styles";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { Typography } from "@material-ui/core";

const TenantDropdown = (props) => {
  const {
    classes,
    currentTenant,
    handleChangeTenant,
    allTenantsLoading,
    allTenants,
    account,
  } = props;
  const [sortedTenants, setSortedTenants] = useState("");

  useEffect(() => {
    if (!allTenantsLoading && allTenants && allTenants.items) {
      let array = [...allTenants.items];
      let sorted = array.sort((a, b) => {
        let tenantA = a.title.toUpperCase();
        let tenantB = b.title.toUpperCase();
        return tenantA < tenantB ? -1 : tenantA > tenantB ? 1 : 0;
      });
      sorted.map((t, i) => {
        if (t.root) {
          sorted.splice(i, 1);
          sorted.unshift(t);
        } else if (t.template) {
          sorted.splice(i, 1);
          sorted.push(t);
        }
      });
      setSortedTenants(sorted);
    }
  }, [allTenants, allTenantsLoading]);

  if (
    currentTenant &&
    (!account ||
      account.accountType === "SINGLE_TENANT" ||
      (allTenants && allTenants.total === 1))
  ) {
    return (
      <Typography>
        {currentTenant && currentTenant.title
          ? currentTenant.title
          : allTenants.items[0].title}
      </Typography>
    );
  }

  return (
    <PopupState variant="popover" popupId="dropdown-menu">
      {(popupState) => (
        <div style={{ margin: "auto" }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ textTransform: "none", width: "max-content" }}
            {...bindTrigger(popupState)}
          >
            {currentTenant && currentTenant.title
              ? currentTenant.title
              : "Select Tenant"}
            <ArrowDropDownRoundedIcon
              className={classes.arrowIcon}
              fontSize="small"
            />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disableScrollLock={true}
          >
            <Box p={2} onClick={() => popupState.close()}>
              {!allTenantsLoading &&
                sortedTenants &&
                sortedTenants.length > 0 &&
                sortedTenants.map((tenant) => (
                  <MenuItem
                    key={tenant.id}
                    value={tenant.id}
                    className={
                      (tenant.root && classes.rootTenant) ||
                      (tenant.template && classes.templateTenant)
                    }
                    onClick={() => handleChangeTenant(tenant.id)}
                  >
                    {tenant.title}
                  </MenuItem>
                ))}
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default withStyles(NavBarStyles)(TenantDropdown);
