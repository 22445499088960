import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_ALLERGY_REQUEST = "GET_ALLERGY_REQUEST";
export const GET_ALLERGY_SUCCESS = "GET_ALLERGY_SUCCESS";
export const GET_CONDITION_REQUEST = "GET_CONDITION_REQUEST";
export const GET_CONDITION_SUCCESS = "GET_CONDITION_SUCCESS";
export const GET_MEDICATION_REQUEST = "GET_MEDICATION_REQUEST";
export const GET_MEDICATION_SUCCESS = "GET_MEDICATION_SUCCESS";
export const GET_PRESCRIPTION_REQUEST = "GET_PRESCRIPTION_REQUEST";
export const GET_PRESCRIPTION_SUCCESS = "GET_PRESCRIPTION_SUCCESS";
export const GET_IMMUNIZATION_REQUEST = "GET_IMMUNIZATION_REQUEST";
export const GET_IMMUNIZATION_SUCCESS = "GET_IMMUNIZATION_SUCCESS";
export const GET_VITAL_REQUEST = "GET_VITAL_REQUEST";
export const GET_VITAL_SUCCESS = "GET_VITAL_SUCCESS";
export const GET_PROCEDURE_REQUEST = "GET_PROCEDURE_REQUEST";
export const GET_PROCEDURE_SUCCESS = "GET_PROCEDURE_SUCCESS";
export const GET_ACCESSTOKEN_REQUEST = "GET_ACCESSTOKEN_REQUEST";
export const GET_ACCESSTOKEN_SUCCESS = "GET_ACCESSTOKEN_SUCCESS";
export const VIEW_CCD_REQUEST = "VIEW_CCD_REQUEST";
export const VIEW_CCD_SUCCESS = "VIEW_CCD_SUCCESS";
export const POST_ALLERGY_REQUEST = "POST_ALLERGY_REQUEST";
export const POST_CONDITION_REQUEST = "POST_CONDITION_REQUEST";
export const POST_MEDICATION_REQUEST = "POST_MEDICATION_REQUEST";
export const POST_IMMUNIZATION_REQUEST = "POST_IMMUNIZATION_REQUEST";
export const POST_VITAL_REQUEST = "POST_VITAL_REQUEST";
export const POST_PROCEDURE_REQUEST = "POST_PROCEDURE_REQUEST";
export const POST_PATIENT_REQUEST = "POST_PATIENT_REQUEST";
export const POST_PATIENT_SUCCESS = "POST_PATIENT_SUCCESS";
export const POST_ALLERGY_DRFIRST_SUCCESS = "POST_ALLERGY_DRFIRST_SUCCESS";
export const POST_ALLERGY_DRFIRST_REQUEST = "POST_ALLERGY_DRFIRST_REQUEST";
export const POST_CONDITION_DRFIRST_REQUEST = "POST_CONDITION_DRFIRST_REQUEST";
export const POST_CONDITION_DRFIRST_SUCCESS = "POST_CONDITION_DRFIRST_SUCCESS";
export const POST_MEDICATION_DRFIRST_REQUEST =
  "POST_MEDICATION_DRFIRST_REQUEST";
export const POST_MEDICATION_DRFIRST_SUCCESS =
  "POST_MEDICATION_DRFIRST_SUCCESS";
export const PULL_IMMUNIZATION_REQUEST = "PULL_IMMUNIZATION_REQUEST";
export const PULL_IMMUNIZATION_SUCCESS = "PULL_IMMUNIZATION_SUCCESS";
export const PUSH_IMMUNIZATION_REQUEST = "PUSH_IMMUNIZATION_REQUEST";
export const PUSH_IMMUNIZATION_SUCCESS = "PUSH_IMMUNIZATION_SUCCESS";

//=========Thunks===========

export const getAllergyList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ALLERGY_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Allergy/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_ALLERGY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getConditionList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_CONDITION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Condition/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CONDITION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getMedicationList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_MEDICATION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Medication/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_MEDICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getPrescriptionList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_PRESCRIPTION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Prescription/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PRESCRIPTION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getImmunizationList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_IMMUNIZATION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Vaccination/getlist/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_IMMUNIZATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getVitalList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_VITAL_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Vital/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_VITAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getProcedureList = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_PROCEDURE_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Procedure/list/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PROCEDURE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getAccessToken = (safeEMRId, tenantId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ACCESSTOKEN_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/synchealthrecord/getaccesstokenfromadminconsole/${tenantId}/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_ACCESSTOKEN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getCCDViewer = (safeEMRId, tenantId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: VIEW_CCD_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/patient-record/Patient/emrid/getCCDJson/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: VIEW_CCD_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// POST Patient record apis

export const postAllergyList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_ALLERGY_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Allergy/managedetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Allergy Details Successfully Sent", "success")
      );
      dispatch(getAllergyList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postConditionList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_CONDITION_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Condition/managedetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Condition Details Successfully Sent", "success")
      );
      dispatch(getConditionList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postMedicationList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_MEDICATION_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Medication/managedetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Medication Details Successfully Sent", "success")
      );
      dispatch(getMedicationList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postImmunizationList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_IMMUNIZATION_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Vaccination/managevaccinationdetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Immunization Details Successfully Sent", "success")
      );
      dispatch(getImmunizationList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postVitalList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_IMMUNIZATION_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Vital/managedetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Vital Details Successfully Sent", "success"));
      dispatch(getVitalList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postProcedureList = (safeEMRId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_PROCEDURE_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/patient-record/Procedure/managedetails`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Procedure Details Successfully Sent", "success")
      );
      dispatch(getProcedureList(safeEMRId));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postPatient = (data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_PATIENT_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/dr-first/Patient/sendpatient`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: POST_PATIENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postAllergyToDrfirst = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_ALLERGY_DRFIRST_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/dr-first/Allergy/sendallergy?id=${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Drfirst sync Successfull", "success"));
      dispatch({
        type: POST_ALLERGY_DRFIRST_SUCCESS,
        payload: true,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postConditionToDrfirst = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_CONDITION_DRFIRST_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/dr-first/Problem/sendproblem?id=${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Drfirst sync Successfull", "success"));
      dispatch({
        type: POST_CONDITION_DRFIRST_SUCCESS,
        payload: true,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const postMedicationToDrfirst = (safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_MEDICATION_DRFIRST_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/dr-first/Medication/sendmedication?id=${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Drfirst sync Successfull", "success"));
      dispatch({
        type: POST_MEDICATION_DRFIRST_SUCCESS,
        payload: true,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const pullImmunization = (tenant, safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: PULL_IMMUNIZATION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/immunization/pullimmunization/${tenant}/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Immunization Pull Successfull", "success"));
      dispatch({
        type: PULL_IMMUNIZATION_SUCCESS,
        payload: true,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const pushImmunization = (tenant, safeEMRId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: PUSH_IMMUNIZATION_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/immunization/pushimmunization/${tenant}`,
    data: { safeEMRId: safeEMRId },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("Push Immunization Successfull", "success"));
      dispatch({
        type: PUSH_IMMUNIZATION_SUCCESS,
        payload: true,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
