import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import history from "../Shared/Redux/history";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_QUESTIONNAIRES_REQUEST = "GET_QUESTIONNAIRES_REQUEST";
export const GET_QUESTIONNAIRES_SUCCESS = "GET_QUESTIONNAIRES_SUCCESS";
export const GET_QUESTIONNAIRES_FAILURE = "GET_QUESTIONNAIRES_FAILURE";

export const GET_SEGMENTS_REQUEST = "GET_SEGMENTS_REQUEST";
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS";
export const GET_SEGMENTS_FAILURE = "GET_SEGMENTS_FAILURE";

//=========Thunks===========

export const getQuestionnaires = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_QUESTIONNAIRES_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/virtual-consult/${tenant}/questionnaires`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_QUESTIONNAIRES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_QUESTIONNAIRES_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserSegments = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SEGMENTS_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user-segment/${tenant}`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_SEGMENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_SEGMENTS_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const createUserSegment = (tenant, segment) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SEGMENTS_REQUEST });

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user-segment/${tenant}`,
    withCredentials: true,
    crossorigin: true,
    data: segment,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(getUserSegments(tenant));

      dispatch(
        showNotification("User Segment successfully created", "success")
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_SEGMENTS_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const deleteUserSegment = (tenant, segmentId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SEGMENTS_REQUEST });

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/user-segment/${tenant}/${segmentId}`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(getUserSegments(tenant));
      dispatch(
        showNotification("User Segment successfully deleted", "success")
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_SEGMENTS_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const updateUserSegment = (tenant, segment, editPoint) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_SEGMENTS_REQUEST });

  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/user-segment/${tenant}/${segment.id}`,
    withCredentials: true,
    crossorigin: true,
    data: segment,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      if (editPoint === "settings") {
        await dispatch(getUserSegments(tenant));
      } else {
        dispatch({
          type: GET_SEGMENTS_SUCCESS,
          payload: response.data,
        });
        history.push(`/${tenant}/virtual-consult/user-segments`);
      }

      dispatch(
        showNotification("User Segment successfully updated", "success")
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_SEGMENTS_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};
