import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANT_ALERTS_REQUEST = "GET_TENANT_ALERTS_REQUEST";
export const GET_TENANT_ALERT_SUCCESS = "GET_TENANT_ALERT_SUCCESS";

//=========Thunks===========

export const getAlerts = (
  tenant,
  type,
  page,
  pageSize,
  query,
  sort,
  direction
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({
    type: GET_TENANT_ALERTS_REQUEST,
    payload: {
      type: type,
    },
  });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/alert/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_ALERT_SUCCESS,
        payload: {
          data: response.data,
          type: type,
        },
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const readAllAlerts = (tenant, pageSize) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/alert/${tenant}/read`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        getAlerts(tenant, "menuAlerts", 0, pageSize, "", "time", "DESC")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};
