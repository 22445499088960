import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import history from "../Shared/Redux/history";
import { handleError } from "../Shared/Content/APIUtils";
import { submitSuccess, submitFailed } from "../Shared/Forms/FormActions";
import { updateCurrentTenant } from "../Shared/SharedActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANT_NETWORKS_REQUEST = "GET_TENANT_NETWORKS_REQUEST";
export const GET_TENANT_NETWORKS_SUCCESS = "GET_TENANT_NETWORKS_SUCCESS";

export const GET_TENANT_NETWORK_REQUEST = "GET_TENANT_NETWORK_REQUEST";
export const GET_TENANT_NETWORK_SUCCESS = "GET_TENANT_NETWORK_SUCCESS";

export const ADD_NETWORK_REQUEST = "ADD_NETWORK_REQUEST";
export const ADD_NETWORK_SUCCESS = "ADD_NETWORK_SUCCESS";

export const UPDATE_NETWORK_REQUEST = "UPDATE_NETWORK_REQUEST";

export const GET_NETWORK_MESSAGES_REQUEST = "GET_NETWORK_MESSAGES_REQUEST";
export const GET_NETWORK_MESSAGES_SUCCESS = "GET_NETWORK_MESSAGES_SUCCESS";

export const GET_METADATA_LIST_REQUEST = "GET_METADATA_LIST_REQUEST";
export const SET_METADATA_LIST_SUCCESS = "SET_METADATA_LIST_SUCCESS";

export const SET_CHECKER_SCREEN_PARAM = "SET_CHECKER_SCREEN_PARAM";

//=========Thunks===========

export const getNetworks = (
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_TENANT_NETWORKS_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/networks`,
    params: {
      tenantID: tenant,
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
      permission: filter,
    },
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: GET_TENANT_NETWORKS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_TENANT_NETWORKS_SUCCESS,
          payload: {
            data: [],
            total: 0,
          },
        });

        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const getNetwork = (tenant, id) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_TENANT_NETWORK_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/network/` + id,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: GET_TENANT_NETWORK_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const getNetworkByTenantId = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_TENANT_NETWORK_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/network/for/tenant/` + tenant.id,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant.id}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: GET_TENANT_NETWORK_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const addNetwork = (tenant, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: ADD_NETWORK_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/content/create/network`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: ADD_NETWORK_SUCCESS,
          payload: response.data,
        });
        dispatch(showNotification("Network Successfully Added", "success"));
        history.push(`/${tenant}/networks`);
      } else {
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const updateNetwork = (tenant, data, isNetworkSettings) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: UPDATE_NETWORK_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/content/update/network`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        // dispatch({
        // 	type: GET_TENANT_NETWORK_SUCCESS,
        // 	payload: response.data,
        // });
        dispatch(
          updateCurrentTenant(
            data.Data.appThemeColor,
            data.Data.networkLogoInThemeColor,
            data.Data.name
          )
        );
        dispatch(getNetworkByTenantId({ id: tenant }));
        dispatch(submitSuccess());
        dispatch(showNotification("Successfully Updated", "success"));
      } else {
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(submitFailed());
      dispatch(handleError(error));
    });
};

export const getNetworkMessages = (
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");
  dispatch({ type: GET_NETWORK_MESSAGES_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/network/messages`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
      permission: filter,
    },
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: GET_NETWORK_MESSAGES_SUCCESS,
          payload: [],
        });
      } else {
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_NETWORK_MESSAGES_SUCCESS,
        payload: [],
      });

      dispatch(handleError(error));
    });
};

export const getMetaDataList = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: ADD_NETWORK_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/metadata/${tenant}`,
    params: {
      size: 100,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_METADATA_LIST_SUCCESS,
        payload:
          response.data && response.data.totalElements
            ? response.data.content
            : [],
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const addMetaDataKey = (tenant, newMetaDataKey, forceAdd) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/metadata/${tenant}/byKey/${newMetaDataKey}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(getMetaDataList(tenant));

      if (!forceAdd) {
        await dispatch(
          showNotification("MetaData Keyword Successfully Added", "success")
        );
      }

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const updateMetaDataKey = (
  tenant,
  editingKeyword,
  newMetaDataKey
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/metadata/${tenant}/byKey/${editingKeyword}/raname/${newMetaDataKey}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(getMetaDataList(tenant));
      await dispatch(
        showNotification("MetaData Keyword Successfully Updated", "success")
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const updateMetaDataKeyEnabling = (tenant, selectedKeyword) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/metadata/${tenant}/byKey/${selectedKeyword}/enable/true`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(
        showNotification("MetaData Keyword Successfully Enabled", "success")
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const getCheckerScreenParam = (tenant) => (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/metadata/${tenant}/checkerScreen`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_CHECKER_SCREEN_PARAM,
        payload: response.data.metadataOnCheckerScreenEnabled,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const enableDisplayOnCheckerScreen = (tenant, enableVal) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/metadata/${tenant}/checkerScreen/${enableVal}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      await dispatch(
        showNotification(
          `Displaying user metadata on checker result screen ${
            enableVal ? "enabled" : "disabled"
          }`,
          "success"
        )
      );

      return true;
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      return false;
    });
};

export const deleteMetaDataKey = (tenant, metaDataKey) => async (
  dispatch,
  getState
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  // const { metaDataList } = getState().networks;

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/metadata/${tenant}/byKey/${metaDataKey}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then(async (response) => {
      if (response.data.success) {
        // const updatedMetaDataList = metaDataList.filter(item => item.key !== metaDataKey);
        // dispatch({
        //   type: SET_METADATA_LIST_SUCCESS,
        //   payload: updatedMetaDataList,
        // });
        await dispatch(getMetaDataList(tenant));
        dispatch(showNotification("MetaData Keyword deleted", "success"));

        return true;
      }

      dispatch(
        showNotification(
          "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
