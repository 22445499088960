export const FORM_INITIALIZE = "FORM_INITIALIZE";
export const FORM_ADD_DATA = "FORM_ADD_DATA";
export const FORM_RESET = "FORM_RESET";
export const FORM_SUBMIT = "FORM_SUBMIT";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_FAILED = "FORM_SUBMIT_FAILED";
export const FORM_DELETE = "FORM_DELETE";
export const FORM_DELETE_FAILED = "FORM_DELETE_FAILED";

export const initialize = (initialValues) => async (dispatch) => {
  dispatch({ type: FORM_INITIALIZE, values: initialValues });
};

export const addData = (name, value) => async (dispatch) => {
  dispatch({ type: FORM_ADD_DATA, name, value });
};

export const reset = () => async (dispatch) => {
  dispatch({ type: FORM_RESET });
};

export const submit = () => async (dispatch) => {
  dispatch({ type: FORM_SUBMIT });
};

export const submitSuccess = () => async (dispatch) => {
  dispatch({ type: FORM_SUBMIT_SUCCESS });
};

export const submitFailed = () => async (dispatch) => {
  dispatch({ type: FORM_SUBMIT_FAILED });
};

export const remove = () => async (dispatch) => {
  dispatch({ type: FORM_DELETE });
};

export const removeFailed = () => async (dispatch) => {
  dispatch({ type: FORM_DELETE_FAILED });
};
