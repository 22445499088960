import * as types from "./DiagnosticReportActions";

//reducer for shared actions
export const diagnosticReport = (state = [], action) => {
  switch (action.type) {
    case types.GET_DIAGNOSTIC_REPORT_REQUEST:
      return {
        ...state,
        diagnosticReportInfoLoading: true,
      };

    case types.GET_DIAGNOSTIC_REPORT_SUCCESS:
      return {
        ...state,
        diagnosticReportInfo: action.payload,
        diagnosticReportInfoLoading: false,
      };

    default:
      return state;
  }
};
