import "react-app-polyfill/ie11";
import React from "react";
import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Components
import Auth from "./Auth";

render(<Auth />, document.getElementById("root"));

serviceWorker.register();
