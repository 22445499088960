import axios from "axios";
import { showNotification } from "../../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_CONSENTS_REQUEST = "GET_CONSENTS_REQUEST";
export const GET_CONSENTS_SUCCESS = "GET_CONSENTS_SUCCESS";
export const GET_CONSENTS_FAILURE = "GET_CONSENTS_FAILURE";

//=========Thunks===========

export const getConsents = (
  page,
  pageSize,
  sort,
  direction,
  username,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_CONSENTS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/consent/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort: sort,
      direction: direction,
      username: username,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CONSENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_CONSENTS_FAILURE,
        error: error,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
