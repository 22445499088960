import * as types from "./ReportingActions";

//reducer for reporting actions
export const reports = (state = [], action) => {
  switch (action.type) {
    case types.GET_REPORTS_REQUEST:
      return {
        ...state,
        reportsLoading: !action.noLoad && true,
      };

    case types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        reportsLoading: false,
      };

    default:
      return state;
  }
};
