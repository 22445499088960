import { showNotification } from "../../Notifications/NotificationsActions";

export const handleError = (error) => (dispatch) => {
  if (error) {
    console.log("ERROR", error);
    if (
      error.response &&
      error.response.data &&
      (error.response.data.message || error.response.data.errorMessage)
    ) {
      dispatch(
        showNotification(
          error.response.data.message ||
            error.response.data.errorMessage ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    } else {
      dispatch(
        showNotification(
          "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    }
  } else {
    console.log("ERROR");
    dispatch(
      showNotification("Oops! Something went wrong. Please try again.", "error")
    );
  }
};
