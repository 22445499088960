import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import history from "../Shared/Redux/history";

// imports
import SidebarStyles from "../../assets/jss/components/SidebarStyles";
import { pages, settingsPages } from "./SidebarPages";
import AccessControl from "../Shared/AccessControl";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//material-ui icons
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import LocalHospitalRoundedIcon from "@material-ui/icons/LocalHospitalRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WidgetsRoundedIcon from "@material-ui/icons/WidgetsRounded";
import PowerSettingsNewRoundedIcon from "@material-ui/icons/PowerSettingsNewRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import { Button } from "@material-ui/core";

const icons = [
  <HomeRoundedIcon />,
  <PersonRoundedIcon />,
  <PeopleAltRoundedIcon />,
  <AssignmentRoundedIcon />,
  <FolderRoundedIcon />,
  <LocalHospitalRoundedIcon />,
  <QuestionAnswerRoundedIcon />,
  <AssessmentRoundedIcon />,
  <WidgetsRoundedIcon />,
];

export const Sidebar = (props) => {
  const {
    classes,
    currentTenant,
    onMouseLeave,
    open,
    type,
    handleChangeTenant,
    allTenants,
    allTenantsLoading,
    logout,
    tenantSettings,
    userAccountPermission,
  } = props;
  const [openSettings, setOpenSettings] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [windowLocation, setWindowLocation] = useState("");
  const { isAuthenticated, user } = useAuth0();
  let accountAdminAccess =
    userAccountPermission &&
    userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR";

  useEffect(() => {
    if (!windowLocation) {
      setWindowLocation(window.location.pathname);
    }
    history.listen((location) => {
      setWindowLocation(location.pathname);
    });
  }, [windowLocation]);

  useEffect(() => {
    if (type !== "mobile" && !open) {
      setOpenSettings(false);
      setOpenContent(false);
      setOpenAdmin(false);
    }
  }, [type, open]);

  const handleOpen = (dropdown) => {
    if (dropdown === "settings") {
      setOpenSettings(!openSettings);
      setOpenContent(false);
      setOpenAdmin(false);
    } else if (dropdown === "content") {
      setOpenContent(!openContent);
      setOpenSettings(false);
      setOpenAdmin(false);
    } else if (dropdown === "admin") {
      setOpenAdmin(!openAdmin);
      setOpenContent(false);
      setOpenSettings(false);
    }
  };

  const handleMouseLeave = () => {
    setOpenSettings(false);
    setOpenContent(false);
    setOpenAdmin(false);
    onMouseLeave();
  };

  const listItemLink = (
    link,
    key,
    className,
    className2,
    icon,
    text,
    allowedFor
  ) => {
    // if route in menuItems set to false, do not render
    let route = link.split("/").slice(2).join("/");
    // check if user has admin permissions to render account settings
    let accountAdminAccess = route.includes("account");
    let accountAdmin =
      accountAdminAccess &&
      userAccountPermission &&
      userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR";
    // check if item is not in menuItems list (can always view), if item is true in menuItems, or if admin route is permitted
    let conditionalRender =
      (!accountAdminAccess &&
        tenantSettings &&
        !(route in tenantSettings.menuItems)) ||
      (tenantSettings && tenantSettings.menuItems[route]) ||
      accountAdmin;
    // render only true conditional render items
    if (conditionalRender) {
      return allowedFor ? (
        <AccessControl allowedPermissions={allowedFor}>
          <Link
            to={link}
            key={key}
            className={className}
            onClick={handleMouseLeave}
          >
            <ListItem
              button
              className={className2}
              selected={windowLocation === link}
            >
              {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        </AccessControl>
      ) : (
        <Link
          to={link}
          key={key}
          className={className}
          onClick={handleMouseLeave}
        >
          <ListItem
            button
            className={className2}
            selected={windowLocation === link}
          >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={text} />
          </ListItem>
        </Link>
      );
    }
  };

  const listItemButton = (name, icon, text, openList) => {
    let accountAdminAccess =
      userAccountPermission &&
      userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR";
    let conditionalRender =
      name !== "admin" || (name === "admin" && accountAdminAccess);
    if (conditionalRender) {
      return (
        <ListItem button onClick={() => handleOpen(name)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      );
    }
  };

  if (
    (!isAuthenticated ||
      !currentTenant ||
      !tenantSettings ||
      !tenantSettings.menuItems) &&
    currentTenant !== "None"
  ) {
    return null;
  }

  return (
    <List
      className={type === "mobile" && classes.mobileSidebar}
      style={{
        backgroundColor: "#fff",
        zIndex: "999",
        overflowX: "hidden",
      }}
    >
      {type === "mobile" && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentTenant.id || ""}
          onChange={(e) => handleChangeTenant(e.target.value)}
          variant={"outlined"}
          className={classes.mobileTenant}
          displayEmpty
        >
          <MenuItem dense value="" disabled>
            Select Tenant
          </MenuItem>
          {!allTenantsLoading &&
            allTenants &&
            allTenants.items &&
            allTenants.items.map((tenant) => (
              <MenuItem dense key={tenant.id} value={tenant.id}>
                {tenant.title}
              </MenuItem>
            ))}
        </Select>
      )}
      {currentTenant !== "None" && (
        <AccessControl
          allowedPermissions={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
          renderNoAccess={() => <div></div>}
        >
          {pages(currentTenant, user).map((page, i) => {
            // if not mobile view, remove "Profile" from sidebar
            let conditionalRender =
              (type !== "mobile" && page.name !== "Profile") ||
              type === "mobile";
            if (conditionalRender) {
              return listItemLink(
                page.link,
                page.key,
                classes.link,
                null,
                icons[i],
                page.name,
                page.allowedPermissions
              );
            }
          })}
          {listItemButton(
            "settings",
            <SettingsRoundedIcon />,
            "Settings",
            openSettings
          )}
          <Collapse
            in={openSettings}
            timeout="auto"
            unmountOnExit
            className={classes.dropdown}
          >
            <List component="div">
              {settingsPages(currentTenant).map((page, i) =>
                listItemLink(
                  page.link,
                  page.key,
                  classes.link,
                  classes.nested,
                  null,
                  <Typography variant="body2">{page.name}</Typography>
                )
              )}
            </List>
          </Collapse>
        </AccessControl>
      )}
      {type === "mobile" && (
        <List component="div">
          {accountAdminAccess && (
            <Link
              to={`/${currentTenant && currentTenant.id}/account`}
              className={classes.link}
            >
              <ListItem button>
                <ListItemIcon>
                  <LockOpenRoundedIcon
                    className={classes.icon}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2">Account Settings</Typography>
                  }
                />
              </ListItem>
            </Link>
          )}
          <div className={classes.logout}>
            <ListItem
              button
              onClick={logout}
              disableGutters
              style={{ marginBottom: "-20px", zIndex: "999" }}
            >
              <ListItemText
                style={{ marginBottom: "-10px" }}
                primary={
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    className={classes.logoutBtn}
                    fullWidth
                    disableElevation
                    startIcon={<PowerSettingsNewRoundedIcon />}
                  >
                    Logout
                  </Button>
                }
              />
            </ListItem>
            <ListItem
              className={classes.mobileVersion}
              disableGutters
              style={{ padding: "0" }}
            >
              <ListItemText
                style={{ textAlign: "center", maxWidth: "225px" }}
                primary={<Typography variant="caption">v2.1.1</Typography>}
              />
            </ListItem>
          </div>
        </List>
      )}
    </List>
  );
};

export function mapStateToProps(state) {
  return {
    currentTenant: state.shared.currentTenant,
    tenantSettings: state.settings.tenantSettings,
    userAccountPermission: state.users.userAccountPermission,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(SidebarStyles)(Sidebar));
