import React, { useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import SidebarList from "./SidebarList";

//styling imports
import SidebarStyles from "../../assets/jss/components/SidebarStyles";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Divider, Typography, Grid } from "@material-ui/core";

const Sidebar = (props) => {
  const { classes, currentTenant } = props;
  const { isAuthenticated } = useAuth0();
  const [open, setOpen] = useState(false);

  const handleMouseOver = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  if (!isAuthenticated || !currentTenant || currentTenant === "None") {
    return <div />;
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Divider />
      <Grid container justify={"space-between"} style={{ height: "100%" }}>
        <Grid item xs={12}>
          <SidebarList onMouseLeave={handleMouseLeave} open={open} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginBlockStart: "auto", marginBottom: "40px" }}
        >
          <Typography variant="caption" className={classes.version}>
            v2.1.1
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export function mapStateToProps(state) {
  return {
    currentTenant: state.shared.currentTenant,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(SidebarStyles)(Sidebar));
