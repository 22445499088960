import moment from "moment/moment";

const utils = {
  formatDate: (date) => {
    return moment.utc(date).format("L");
  },
  formatDateNoLocale: (date) => {
    return moment(date).format("YYYY-MM-DD");
  },
  formatDateTime: (date) => {
    return moment(date).format("L HH:mm");
  },
  formatDateTimeLocale: (date) => {
    return moment(date).local().format("MM/DD/YYYY HH:mm");
  },
  formatDateTimeNoLocale: (date) => {
    return moment(date).utc().format("MM/DD/YYYY HH:mm");
  },
  capitalizeString: (string) => {
    string = string.toLowerCase().split(" ");
    for (let i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(" ");
  },
  removeUnderscores: (string) => {
    return string.split("_").join(" ");
  },
  removeUnderscoresTitleCase: (string) => {
    let s = string
      .split("_")
      .join(" ")
      .split("-")
      .join(" ")
      .toLowerCase()
      .split(" ");
    for (let i = 0; i < s.length; i++) {
      s[i] = s[i][0].toUpperCase() + s[i].slice(1);
    }
    return s.join(" ");
  },
  splitCamelcaseString: (string) => {
    return string.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  },
  camelToSnake: (str) =>
    str.replace(
      /([a-z][A-Z])/g,
      (group) =>
        `${group.toLowerCase().substring(0, 1)}-${group
          .toLowerCase()
          .substring(1)}`
    ),
  snakeToCamel: (str) =>
    str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    ),
  rgbToHex: (a) => {
    a = a.replace(/[^\d,]/g, "").split(",");
    return (
      "#" +
      ((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1)
    );
  },
  toUserFriendlySize: (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
  },
  getPropertyByName: (path, obj, separator = ".") => {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  },
  flattenKeys: (prefix, obj) =>
    obj &&
    Object.keys(obj).reduce((accum, key) => {
      return {
        ...accum,
        [`${prefix}__${key}`]: obj[key],
      };
    }, {}),
  unFlattenKeys: (prefix, obj) =>
    Object.keys(obj)
      .filter((key) => key.startsWith(`${prefix}__`))
      .reduce((accum, key) => {
        return {
          ...accum,
          [key.replace(`${prefix}__`, "")]: obj[key],
        };
      }, {}),
  extractField: (list, field) => list.map((i) => i[field]),
  distinctValues: (list) =>
    list.reduce((accum, value) => {
      if (!accum.includes(value)) accum.push(value);
      return accum;
    }, []),
};

export default utils;
