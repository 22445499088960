import * as types from "./DashboardActions";

//reducer for shared actions
export const dashboard = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TENANT_POPULATION_REQUEST:
      return {
        ...state,
        population: {
          loading: true,
        },
      };
    case types.GET_TENANT_POPULATION_SUCCESS:
      return {
        ...state,
        population: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_TENANT_POPULATION_FAILURE:
      return {
        ...state,
        population: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_CLEARANCE_STATUS_REQUEST:
      return {
        ...state,
        clearanceStatus: {
          loading: true,
        },
      };
    case types.GET_CLEARANCE_STATUS_SUCCESS:
      return {
        ...state,
        clearanceStatus: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_CLEARANCE_STATUS_FAILURE:
      return {
        ...state,
        clearanceStatus: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_PATIENT_TEST_STATUS_REQUEST:
      return {
        ...state,
        patientTestStatus: {
          loading: true,
        },
      };
    case types.GET_PATIENT_TEST_STATUS_SUCCESS:
      return {
        ...state,
        patientTestStatus: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_PATIENT_TEST_STATUS_FAILURE:
      return {
        ...state,
        patientTestStatus: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_QUESTIONNAIRE_COMPLETED_REQUEST:
      return {
        ...state,
        questionnaireCompleted: {
          loading: true,
        },
      };
    case types.GET_QUESTIONNAIRE_COMPLETED_SUCCESS:
      return {
        ...state,
        questionnaireCompleted: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_QUESTIONNAIRE_COMPLETED_FAILURE:
      return {
        ...state,
        questionnaireCompleted: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_MAP_INFO_REQUEST:
      return {
        ...state,
        stateInfo: {
          loading: true,
        },
      };
    case types.GET_MAP_INFO_SUCCESS:
      return {
        ...state,
        stateInfo: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_MAP_INFO_FAILURE:
      return {
        ...state,
        stateInfo: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_SYMPTOMS_REQUEST:
      return {
        ...state,
        symptoms: {
          loading: true,
        },
      };
    case types.GET_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptoms: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_SYMPTOMS_FAILURE:
      return {
        ...state,
        symptoms: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_PATIENT_TEST_STATUS_MONTH_REQUEST:
      return {
        ...state,
        patientTestStatusMonth: {
          loading: true,
        },
      };
    case types.GET_PATIENT_TEST_STATUS_MONTH_SUCCESS:
      return {
        ...state,
        patientTestStatusMonth: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_PATIENT_TEST_STATUS_MONTH_FAILURE:
      return {
        ...state,
        patientTestStatusMonth: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_CLEARANCE_STATUS_LAST_MONTH_REQUEST:
      return {
        ...state,
        clearanceStatusLastMonth: {
          loading: true,
        },
      };
    case types.GET_CLEARANCE_STATUS_LAST_MONTH_SUCCESS:
      return {
        ...state,
        clearanceStatusLastMonth: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_CLEARANCE_STATUS_LAST_MONTH_FAILURE:
      return {
        ...state,
        clearanceStatusLastMonth: {
          loading: false,
          error: action.error,
        },
      };

    case types.GET_USER_SUBMISSIONS_REQUEST:
      return {
        ...state,
        userSubmissions: {
          loading: true,
        },
      };
    case types.GET_USER_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        userSubmissions: {
          loading: false,
          data: action.payload,
        },
      };
    case types.GET_USER_SUBMISSIONS_FAILURE:
      return {
        ...state,
        userSubmissions: {
          loading: false,
          error: action.error,
        },
      };

    case types.UPDATE_METADATA_FILTERS_LIST:
      return {
        ...state,
        metadataFiltersList: action.payload,
      };

    default:
      return state;
  }
};
