import axios from "axios";
import FileDownload from "js-file-download";
import { showNotification } from "../Notifications/NotificationsActions";
import { submitFailed } from "../Shared/Forms/FormActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_LAB_REQUEST = "GET_LAB_REQUEST";
export const GET_LAB_SUCCESS = "GET_LAB_SUCCESS";

export const GET_TENANT_LAB_REQUEST = "GET_TENANT_LAB_REQUEST";
export const GET_TENANT_LAB_SUCCESS = "GET_TENANT_LAB_SUCCESS";

export const UPDATE_LAB_REQUEST = "UPDATE_LAB_REQUEST";
export const UPDATE_LAB_SUCCESS = "UPDATE_LAB_SUCCESS";

export const CREATE_LAB_REQUEST = "CREATE_LAB_REQUEST";
export const CREATE_LAB_SUCCESS = "CREATE_LAB_SUCCESS";

export const GET_USER_SCAN_REQUEST = "GET_USER_SCAN_REQUEST";
export const GET_USER_SCAN_SUCCESS = "GET_USER_SCAN_SUCCESS";

export const RENEW_TEST_REQUEST = "RENEW_TEST_REQUEST";
export const RENEW_TEST_SUCCESS = "RENEW_TEST_SUCCESS";

export const DOWNLOAD_TEST_RESULTS_REQUEST = "DOWNLOAD_TEST_RESULTS_REQUEST";
export const DOWNLOAD_TEST_RESULTS_SUCCESS = "DOWNLOAD_TEST_RESULTS_SUCCESS";

export const DOWNLOAD_TEST_REQUISITION_REQUEST =
  "DOWNLOAD_TEST_REQUISITION_REQUEST";
export const DOWNLOAD_TEST_REQUISTION_SUCCESS =
  "DOWNLOAD_TEST_REQUISITION_SUCCESS";

export const DOWNLOAD_TEST_LABEL_REQUEST = "DOWNLOAD_TEST_LABEL_REQUEST";
export const DOWNLOAD_TEST_LABEL_SUCCESS = "DOWNLOAD_TEST_LABEL_SUCCESS";

export const GET_MASTER_LAB_TEST_REQUEST = "GET_MASTER_LAB_TEST_REQUEST";
export const GET_MASTER_LAB_TEST_SUCCESS = "GET_MASTER_LAB_TEST_SUCCESS";
export const GET_MASTER_LAB_TEST_FAILURE = "GET_MASTER_LAB_TEST_FAILURE";

export const FIND_PROVIDERS_REQUEST = "FIND_PROVIDERS_REQUEST";
export const FIND_PROVIDERS_SUCCESS = "FIND_PROVIDERS_SUCCESS";
export const FIND_PROVIDERS_FAILURE = "FIND_PROVIDERS_FAILURE";

//=========Thunks===========

export const getTenantLabTests = (
  page,
  pageSize,
  sort,
  direction,
  searchValue,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANT_LAB_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/byTenant/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort: sort,
      direction: direction,
      q: searchValue,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_LAB_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getLabTests = (
  page,
  pageSize,
  sort,
  direction,
  username,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_LAB_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/byUser/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort: sort,
      direction: direction,
      username: username,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_LAB_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateLabTest = (testId, status, result) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPDATE_LAB_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/laboratory-test/${testId}`,
    params: {
      status,
      result,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_LAB_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const createLabOrder = (
  userToTest,
  source,
  type,
  tenant,
  data
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: CREATE_LAB_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/laboratory-test/create/${tenant}/${source}`,
    params: {
      userToTest,
    },
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: CREATE_LAB_SUCCESS,
        payload: {
          data: response.data,
          type: type,
        },
      });
      dispatch(showNotification("Lab Order Successfully Created", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const createBulkLabOrder = (
  usersToTest,
  source,
  type,
  tenant,
  data,
  onlyForNewUser
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: CREATE_LAB_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/laboratory-test/bulk-create/${tenant}/${source}`,
    params: {
      usersToTest,
      onlyForNewUser,
    },
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      // dispatch({
      //   type: CREATE_LAB_SUCCESS,
      //   payload: {
      //     data: response.data,
      //     type: type,
      //   },
      // });
      // dispatch(getLabTests(0, 10, "lastUpdated", 'DESC', "", tenant))
      dispatch(
        showNotification(
          "Lab Order Successfully Created. Sync table for order updates.",
          "success"
        )
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserScanHistory = (
  page,
  pageSize,
  sort,
  direction,
  username,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  sort = sort === "overriddenOn" ? "outcome.overriddenOn" : sort;

  dispatch({ type: GET_USER_SCAN_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/byUser/${tenant}/scan`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort,
      direction,
      username,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SCAN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const renewLabTest = (testId, userId, tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: RENEW_TEST_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/laboratory-test/${testId}/renew`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Renew Test Email Successfully Sent", "success")
      );
      dispatch(getLabTests(0, 10, "created", "DESC", userId));
      dispatch(getTenantLabTests(0, 10, "created", "DESC", "", tenant));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const downloadResults = (testId, name) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: DOWNLOAD_TEST_RESULTS_REQUEST, testId });

  axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/${testId}/results`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "blob",
  })
    .then((response) => {
      FileDownload(response.data, `${name}.pdf`);
      dispatch({ type: DOWNLOAD_TEST_RESULTS_SUCCESS, testId });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Impossible to get the results for this patient test.",
          "error"
        )
      );
    });
};

export const downloadRequisition = (testId, name) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: DOWNLOAD_TEST_REQUISITION_REQUEST, testId });

  axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/${testId}/requisition`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "blob",
  })
    .then((response) => {
      FileDownload(response.data, `${name}.pdf`);
      dispatch({ type: DOWNLOAD_TEST_REQUISTION_SUCCESS, testId });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Impossible to get the requisition for this patient test.",
          "error"
        )
      );
    });
};

export const downloadLabel = (testId, name) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: DOWNLOAD_TEST_LABEL_REQUEST, testId });

  axios({
    method: "GET",
    url: `${API_ROOT}/admin/laboratory-test/${testId}/label-data`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "blob",
  })
    .then((response) => {
      FileDownload(response.data, `${name}.pdf`);
      dispatch({ type: DOWNLOAD_TEST_LABEL_SUCCESS, testId });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Impossible to get the label document for this patient test.",
          "error"
        )
      );
    });
};

export const getMasterLabTests = (type, endpoint) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_MASTER_LAB_TEST_REQUEST, testType: type });

  return axios({
    method: "GET",
    url: `${API_ROOT}/diagnostic-builder/${endpoint}`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_MASTER_LAB_TEST_SUCCESS,
        payload: response.data.filter((d) => d.isActive),
        testType: type,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_MASTER_LAB_TEST_FAILURE,
        error: error.response.data.message,
        testType: type,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const findProvider = (query, skip = 0, limit = 20) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: FIND_PROVIDERS_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/provider/admin/providers/directory`,
    withCredentials: true,
    crossorigin: true,
    params: {
      q: query,
      skip,
      limit,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: FIND_PROVIDERS_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: FIND_PROVIDERS_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
