import { combineReducers } from "redux";
import notification from "../../Notifications/NotificationsReducers";
import confirmation from "../../Confirmation/ConfirmationReducers";
import { users } from "../../Users/UsersReducer";
import { shared } from "../../Shared/SharedReducer";
import { alerts } from "../../Alerts/AlertReducer";
import { settings } from "../../Settings/SettingsReducer";
import { orders } from "../../Orders/OrdersReducer";
import { networks, network } from "../../Networks/NetworksReducer";
import { content } from "../../Content/ContentsReducer";
import { tenants } from "../../Account/TenantManagement/TenantsReducer";
import { account } from "../../Account/AccountsReducer";
import { labTests } from "../../MedicalCenters/MedicalCentersReducer";
import { typeahead } from "../Forms/KendoFields/Typeahead/TypeaheadReducer";
import { tenantLabTests } from "../../MedicalCenters/MedicalCentersTenantReducer";
import { virtualConsult } from "../../VirtualConsult/VirtualConsultReducer";
import { form } from "../Forms/FormReducer";
import { accountPermissions } from "../../Account/Permissions/PermissionReducer";
import { dashboard } from "../../Dashboard/DashboardReducer";
import { reports } from "../../Reporting/ReportingReducer";
import { diagnosticReport } from "../../DiagnosticReport/DiagnosticReportReducer";
import {
  allergyList,
  conditionList,
  medicationList,
  prescriptionList,
  immunizationList,
  vitalList,
  procedureList,
  ccdViewer,
  ePrescribe,
  accessToken,
} from "../../ClinicalInformation/ClinicalInformationReducer";
import { consents } from "../Consent/ConsentReducer";
import {
  recordRequest,
  providerList,
} from "../../RecordRequest/RecordRequestReducer";

//Root Reducer
const index = combineReducers({
  notification,
  confirmation,
  users,
  shared,
  alerts,
  orders,
  settings,
  networks,
  network,
  content,
  tenants,
  account,
  labTests,
  tenantLabTests,
  form,
  virtualConsult,
  accountPermissions,
  dashboard,
  reports,
  diagnosticReport,
  allergyList,
  conditionList,
  medicationList,
  prescriptionList,
  immunizationList,
  vitalList,
  procedureList,
  consents,
  ccdViewer,
  recordRequest,
  typeahead,
  ePrescribe,
  providerList,
  accessToken,
});

export default index;
