import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANTS_REQUEST = "GET_TENANTS_REQUEST";
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";

export const SET_CURRENT_TENANT = "SET_CURRENT_TENANT";
export const UPDATE_CURRENT_TENANT = "UPDATE_CURRENT_TENANT";

export const setCurrentTenant = (tenant) => ({
  type: SET_CURRENT_TENANT,
  tenant,
});

export const updateCurrentTenant = (color, logo, name) => ({
  type: UPDATE_CURRENT_TENANT,
  color,
  logo,
  name,
});

//=========Thunks===========

export const getMyTenants = () => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANTS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/tenant/my`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
