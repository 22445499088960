import * as types from "./NetworksActions";

//reducer for networks actions
export const networks = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_NETWORKS_REQUEST:
      return {
        ...state,
        tenantNetworksLoading: true,
      };

    case types.GET_TENANT_NETWORKS_SUCCESS:
      return {
        ...state,
        tenantNetworks: action.payload,
        tenantNetworksLoading: false,
      };

    case types.GET_NETWORK_MESSAGES_REQUEST:
      return {
        ...state,
        networkMessagesLoading: true,
      };

    case types.GET_NETWORK_MESSAGES_SUCCESS:
      return {
        ...state,
        networkMessages: action.payload,
        networkMessagesLoading: false,
      };

    case types.GET_METADATA_LIST_REQUEST:
      return {
        ...state,
        metaDataListLoading: true,
      };

    case types.SET_METADATA_LIST_SUCCESS:
      return {
        ...state,
        metaDataList: action.payload,
        metaDataListLoading: false,
      };

    case types.SET_CHECKER_SCREEN_PARAM:
      return {
        ...state,
        checkerScreen: action.payload,
      };

    default:
      return state;
  }
};

//reducer for network actions
export const network = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_NETWORK_REQUEST:
      return {
        ...state,
        tenantNetworkLoading: true,
      };

    case types.GET_TENANT_NETWORK_SUCCESS:
      return {
        ...state,
        tenantNetwork: action.payload.data,
        tenantNetworkLoading: false,
      };

    default:
      return state;
  }
};
