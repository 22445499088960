import * as types from "./TypeaheadActions";

//reducer for shared actions
export const typeahead = (state = {}, action) => {
  switch (action.type) {
    case types.TYPEAHEAD_SEARCH_REQUEST:
      return {
        ...state,
        [action.id]: {
          loading: true,
          error: null,
        },
      };

    case types.TYPEAHEAD_SEARCH_SUCCESS:
      return {
        ...state,
        [action.id]: {
          loading: false,
          values: action.payload,
        },
      };
    case types.TYPEAHEAD_SEARCH_FAILURE:
      return {
        ...state,
        [action.id]: {
          loading: false,
          error: action.error,
        },
      };

    case types.TYPEAHEAD_RESET:
      delete state[action.id];
      return state;

    default:
      return state;
  }
};
