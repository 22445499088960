const AppStyles = (theme) => ({
  appWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
    },
  },
  sidebar: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
    },
  },
  loading: {
    marginTop: "100px",
  },
  breadcrumbs: {
    marginLeft: -26,
    marginTop: 3,
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      marginLeft: -26,
      marginTop: 45,
      marginBottom: 15,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: -26,
      marginTop: 5,
      marginBottom: 15,
    },
  },
});

export default AppStyles;
