import React from "react";
import { Link } from "react-router-dom";

//styling imports
import NavBarStyles from "../../assets/jss/components/NavBarStyles";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import PowerSettingsNewRoundedIcon from "@material-ui/icons/PowerSettingsNewRounded";
import AccessControl from "../Shared/AccessControl";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const DropdownBox = (props) => {
  const {
    classes,
    popupState,
    logout,
    user,
    currentTenant,
    userAccountPermission,
  } = props;
  let accountAdminAccess =
    userAccountPermission &&
    userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR";

  return (
    <Box p={2} onMouseLeave={() => popupState.close()}>
      <AccessControl
        allowedPermissions={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
        renderNoAccess={() => <div></div>}
      >
        <Link
          to={`/${currentTenant && currentTenant.id}/profile/${
            user && user.email
          }`}
          className={classes.innerLink}
        >
          <MenuItem className={classes.menuItem}>
            <PersonRoundedIcon className={classes.icon} fontSize="small" />
            My Profile
          </MenuItem>
        </Link>
        <Divider />
      </AccessControl>
      {accountAdminAccess && (
        <div>
          <Link
            to={`/${currentTenant && currentTenant.id}/account`}
            className={classes.innerLink}
          >
            <MenuItem className={classes.menuItem}>
              <LockOpenIcon className={classes.icon} fontSize="small" />
              Account Settings
            </MenuItem>
          </Link>
          <Divider />
        </div>
      )}
      <MenuItem onClick={logout} className={classes.menuItem}>
        <PowerSettingsNewRoundedIcon
          className={classes.icon}
          fontSize="small"
        />
        Logout
      </MenuItem>
    </Box>
  );
};

export default withStyles(NavBarStyles)(DropdownBox);
