import * as types from "./FormActions";

const initialState = {};

//reducer for shared actions
export const form = (state = initialState, action) => {
  switch (action.type) {
    case types.FORM_INITIALIZE:
      return {
        ...action.values,
      };

    case types.FORM_ADD_DATA:
      return {
        ...state,
        [action.name]: action.value,
      };
    case types.FORM_SUBMIT:
      return {
        ...state,
        submitting: true,
      };

    case types.FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case types.FORM_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      };

    case types.FORM_DELETE:
      return {
        ...state,
        deleting: true,
      };

    case types.FORM_DELETE_FAILED:
      return {
        ...state,
        deleting: false,
      };

    case types.FORM_RESET:
      return initialState;

    default:
      return state;
  }
};
