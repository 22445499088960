import { createStore, applyMiddleware, compose } from "redux";
import index from "../index";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";

const defaultState = {};

export const defaultStore = createStore(
  //config for AsyncStorage of redux store
  index,
  defaultState,
  compose(applyMiddleware(createDebounce(), thunk))
);
