import React, { useEffect, useState } from "react";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth.config";
import axios from "axios";

// Redux
import history from "./components/Shared/Redux/history";
import { Provider } from "react-redux";
import { defaultStore } from "./components/Shared/Redux/stores/defaultStore";
import NotFoundView from "./views/NotFoundView";

// Components
import App from "./App";

const Auth = () => {
  const [clientId, setClientId] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const API_ROOT = process.env.REACT_APP_API_ROOT;

  useEffect(() => {
    let domainCheck = [
      "setup.safe.health",
      "setup-dev.safe.health",
      "setup-staging.safe.health",
    ];
    let url = new URL(window.location.href);
    let accountUrl = url.host;
    let pathUrl = url.pathname.split("/", 2).join("/");
    let redirect = url.origin;
    if (!url.href.includes("error")) {
      if (domainCheck.includes(accountUrl)) {
        accountUrl = accountUrl + pathUrl;
        redirect = url.href.split("/", 4).join("/").replace(/\/?$/, "/");
      }
      setRedirectUrl(redirect);
      axios
        .get(`${API_ROOT}/admin/account/public?domain=${accountUrl}`)
        .then((response) => {
          setClientId(response.data.appId);
          localStorage.setItem("client_id", response.data.appId);
        })
        .catch((error) => {
          console.log("NOT A VALID DOMAIN", error);
          setClientId("error");
        });
    } else {
      let resp = url.search.split("&");
      let error = resp.find((r) => r.includes("error=")).split("=")[1];
      //gets error description and formats it
      let description = resp.find((r) => r.includes("error_description"));
      let desc = description
        ? description.split("=")[1].split("%20").join(" ")
        : "authentication has encountered an error";
      setClientId(`customAuth0Error=${error}: ${desc}`);
    }
  }, []);

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  if (!clientId) return <div />;

  if (clientId.includes("customAuth0Error=")) {
    return <NotFoundView message={clientId.split("=")[1]} />;
  }

  return (
    <div>
      {clientId !== "error" ? (
        <Auth0Provider
          domain={config.domain}
          client_id={clientId}
          audience={config.audience}
          redirect_uri={redirectUrl}
          onRedirectCallback={onRedirectCallback}
          response_type="token id_token"
          scope="openid email profile"
        >
          <Provider store={defaultStore}>
            <App />
          </Provider>
        </Auth0Provider>
      ) : (
        <NotFoundView />
        // <div>Oops! This is not a valid domain.</div>
      )}
    </div>
  );
};

export default Auth;
