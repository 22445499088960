import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import { submitFailed } from "../Shared/Forms/FormActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;
//const API_ROOT = "https://localhost:44305/v1/api";

//========Action Types=========

export const GET_ACCOUNT_INFO_REQUEST = "GET_ACCOUNT_INFO_REQUEST";
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_FAILURE = "GET_ACCOUNT_INFO_FAILURE";

export const UPDATE_ACCOUNT_INFO_REQUEST = "UPDATE_ACCOUNT_INFO_REQUEST";
export const UPDATE_ACCOUNT_INFO_SUCCESS = "UPDATE_ACCOUNT_INFO_SUCCESS";
export const UPDATE_ACCOUNT_INFO_FAILURE = "UPDATE_ACCOUNT_INFO_FAILURE";

//=========Thunks===========

export const getAccountDetails = () => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ACCOUNT_INFO_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/account`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_ACCOUNT_INFO_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: GET_ACCOUNT_INFO_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateAccount = (data) => (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPDATE_ACCOUNT_INFO_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/account`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_ACCOUNT_INFO_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("Successfully Updated", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: UPDATE_ACCOUNT_INFO_FAILURE,
        error: error.response.data.message,
      });

      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
