import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_RECORD_REQUESTS = "GET_RECORD_REQUESTS";
export const GET_RECORD_REQUESTS_SUCCESS = "GET_RECORD_REQUESTS_SUCCESS";

export const GET_RECORD_REQUEST = "GET_RECORD_REQUEST";
export const GET_RECORD_REQUEST_SUCCESS = "GET_RECORD_REQUEST_SUCCESS";

export const GET_CCD = "GET_CCD";
export const GET_CCD_SUCCESS = "GET_CCD_SUCCESS";

export const GET_DATA_FROM_FACILITY = "GET_DATA_FROM_FACILITY";
export const GET_DATA_FROM_FACILITY_SUCCESS = "GET_DATA_FROM_FACILITY_SUCCESS";

export const GET_DATA_FROM_STATE = "GET_DATA_FROM_STATE";
export const GET_DATA_FROM_STATE_SUCCESS = "GET_DATA_FROM_STATE_SUCCESS";

export const GET_DATA_FROM_ALL = "GET_DATA_FROM_ALL";
export const GET_DATA_FROM_ALL_SUCCESS = "GET_DATA_FROM_ALL_SUCCESS";

export const SEARCH_PROVIDER_REQUEST = "SEARCH_PROVIDER_REQUEST";
export const SEARCH_PROVIDER_SUCCESS = "SEARCH_PROVIDER_SUCCESS";

export const POST_PATIENTDEMOGRAPHICS_REQUEST =
  "POST_PATIENTDEMOGRAPHICS_REQUEST";
export const POST_PATIENTDEMOGRAPHICS_SUCCESS =
  "POST_PATIENTDEMOGRAPHICS_SUCCESS";

// ***************** GET USER DETAILS ENDPOINTS ***************** //
export const getAllRecordRequests = (tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_RECORD_REQUESTS });

  return axios({
    method: "GET",
    url: `${API_ROOT}/record-request/RecordRequest/list/${tenant}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_RECORD_REQUESTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getRecordRequest = (tenant, recordRequestId) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_RECORD_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/record-request/RecordRequest/${tenant}/${recordRequestId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_RECORD_REQUEST_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getCCD = (tenant, userId, encounterId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_CCD });

  return axios({
    method: "GET",
    url: `${API_ROOT}/sequoia/getccd/${tenant}/${userId}/${encounterId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CCD_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getdatafromspecificfacility = (
  tenant,
  userId,
  facilityId
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_DATA_FROM_FACILITY });

  return axios({
    method: "POST",
    url: `${API_ROOT}/sequoia/getdatafromspecificfacility/${tenant}/${userId}/${facilityId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_DATA_FROM_FACILITY_SUCCESS,
        payload: response.data.data,
      });

      dispatch(showNotification("Successfully Created", "success"));
      dispatch(getAllRecordRequests(tenant));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getdatafromallfacilitiesinthestate = (
  tenant,
  userId,
  state
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_DATA_FROM_STATE });

  return axios({
    method: "POST",
    url: `${API_ROOT}/sequoia/getdatafromallfacilitiesinthestate/${tenant}/${userId}/${state}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_DATA_FROM_STATE_SUCCESS,
        payload: response.data.data,
      });

      dispatch(showNotification("Successfully Created", "success"));
      dispatch(getAllRecordRequests(tenant));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getsequoiadatafromallfacilies = (tenant, userId) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_DATA_FROM_ALL });

  return axios({
    method: "POST",
    url: `${API_ROOT}/sequoia/getsequoiadatafromallfacilies/${tenant}/${userId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_DATA_FROM_ALL_SUCCESS,
        payload: response.data.data,
      });

      dispatch(showNotification("Successfully Created", "success"));
      dispatch(getAllRecordRequests(tenant));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const searchProvider = (providerData) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: SEARCH_PROVIDER_REQUEST });
  return axios({
    method: "POST",
    data: providerData,
    url: `${API_ROOT}/provider-directory/providerorganization/search`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SEARCH_PROVIDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const retrivePatientDemographis = (
  patientData,
  tenantId,
  safeEMRId
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: POST_PATIENTDEMOGRAPHICS_REQUEST });
  return axios({
    method: "POST",
    data: patientData,
    url: `${API_ROOT}/synchealthrecord/retrievepatientdemographicsfromadminconsole/${tenantId}/${safeEMRId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      console.log("test response ", response);
      if (response.data.success)
        dispatch(
          showNotification(
            "Patient Demographics synced successfully",
            "success"
          )
        );
    })
    .catch((error) => {
      console.log("Error", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
