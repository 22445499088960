import * as types from "./AlertActions";

//reducer for shared actions
export const alerts = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_ALERTS_REQUEST:
      return {
        ...state,
        [action.payload.type + "Loading"]: true,
      };

    case types.GET_TENANT_ALERT_SUCCESS:
      return {
        ...state,
        [action.payload.type]: action.payload.data,
        [action.payload.type + "Loading"]: false,
      };

    default:
      return state;
  }
};
