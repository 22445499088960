import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const TYPEAHEAD_SEARCH_REQUEST = "TYPEAHEAD_SEARCH_REQUEST";
export const TYPEAHEAD_SEARCH_SUCCESS = "TYPEAHEAD_SEARCH_SUCCESS";
export const TYPEAHEAD_SEARCH_FAILURE = "TYPEAHEAD_SEARCH_FAILURE";
export const TYPEAHEAD_RESET = "TYPEAHEAD_RESET";

//=========Thunks===========

export const search = (id, searchClinical, type, term, size) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: TYPEAHEAD_SEARCH_REQUEST, id });

  return axios({
    method: "POST",
    url: searchClinical
      ? `${API_ROOT}/mastercode/Typeahead/clinicaltables/search${type}code`
      : `${API_ROOT}/mastercode/Typeahead/search${type}code`,
    withCredentials: true,
    crossorigin: true,
    data: {
      term,
      top: size || 0,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: TYPEAHEAD_SEARCH_SUCCESS,
        id,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: TYPEAHEAD_SEARCH_FAILURE,
        id,
        error: error.response.data.message,
      });
    });
};

export const searchMaster = (id, type, term, size) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: TYPEAHEAD_SEARCH_REQUEST, id });

  return axios({
    method: "POST",
    url: `${API_ROOT}/mastercode/Typeahead/search${type}`,
    withCredentials: true,
    crossorigin: true,
    data: {
      term,
      top: size || 0,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: TYPEAHEAD_SEARCH_SUCCESS,
        id,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch({
        type: TYPEAHEAD_SEARCH_FAILURE,
        id,
        error: error.response.data.message,
      });
    });
};

export const reset = (id) => (dispatch) => {
  dispatch({ type: TYPEAHEAD_RESET, id });
};
