import * as types from "./SettingsActions";

//reducer for shared actions
export const settings = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_SETTINGS_REQUEST:
      return { ...state, tenantSettingsLoading: true };

    case types.GET_TENANT_SETTINGS_SUCCESS:
      return {
        ...state,
        tenantSettings: action.payload,
        tenantSettingsLoading: false,
      };

    case types.GET_ALL_TENANT_TEMPLATES_REQUEST:
      return { ...state, tenantTemplatesLoading: true };

    case types.GET_ALL_TENANT_TEMPLATES_SUCCESS:
      return {
        ...state,
        tenantTemplates: action.payload,
        tenantTemplatesLoading: false,
      };

    case types.GET_TEMPLATE_REQUEST:
      return { ...state, templateLoading: true };

    case types.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedTemplate: action.payload,
        templateLoading: false,
      };

    case types.GET_TEMPLATE_SETTINGS_REQUEST:
      return { ...state, templateSettingsLoading: true };

    case types.GET_TEMPLATE_SETTINGS_SUCCESS:
      return {
        ...state,
        selectedTemplateSettings: action.payload,
        templateSettingsLoading: false,
      };
    case types.SAVE_TEMPLATE_SETTINGS_REQUEST:
      return {
        ...state,
        submittingSettings: true,
      };
    case types.SAVE_TEMPLATE_SETTINGS_SUCCESS:
    case types.SAVE_TEMPLATE_SETTINGS_ERROR:
      return {
        ...state,
        submittingSettings: false,
      };

    case types.SAVE_TEMPLATE_REQUEST:
      return {
        ...state,
        submittingTemplate: true,
      };
    case types.SAVE_TEMPLATE_SUCCESS:
    case types.SAVE_TEMPLATE_ERROR:
      return {
        ...state,
        submittingTemplate: false,
      };

    case types.GET_FAQ_REQUEST:
      return { ...state, faqLoading: true };
    case types.SUBMIT_FAQ_CHANGE_REQUEST:
      return { ...state, faqSubmitting: true };
    case types.SUBMIT_FAQ_CHANGE_COMPLETION:
      return { ...state, faqSubmitting: false };

    case types.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        faqLoading: false,
      };

    case types.GET_VERBIAGE_REQUEST:
      return { ...state, verbiageLoading: true };
    case types.SUBMIT_VERBIAGE_CHANGE_REQUEST:
      return { ...state, verbiageSubmitting: true };
    case types.SUBMIT_VERBIAGE_CHANGE_COMPLETION:
      return { ...state, verbiageSubmitting: false };

    case types.GET_VERBIAGE_SUCCESS:
      return {
        ...state,
        verbiage: action.payload,
        verbiageLoading: false,
      };

    case types.GET_VERBIAGE_DEFINITION_SUCCESS:
      return {
        ...state,
        verbiageDefinition: action.payload,
      };

    case types.TEST_TEMPLATE_REQUEST:
      return {
        ...state,
        submittingTest: true,
      };
    case types.TEST_TEMPLATE_SUCCESS:
    case types.TEST_TEMPLATE_FAILURE:
      return {
        ...state,
        submittingTest: false,
      };

    case types.GET_ALL_TENANT_FONTS_REQUEST:
      return { ...state, tenantFontsLoading: true };

    case types.GET_ALL_TENANT_FONTS_SUCCESS:
      return {
        ...state,
        tenantFonts: action.payload,
        tenantFontsLoading: false,
      };

    case types.CREATE_NEW_FONT_REQUEST:
      return { ...state, createFontLoading: true };
    case types.CREATE_NEW_FONT_COMPLETION:
      return {
        ...state,
        tenantFonts: action.payload,
        createFontLoading: false,
      };

    case types.GET_FONT_DETAILS_REQUEST:
      return { ...state, fontDetailsLoading: true };
    case types.GET_FONT_DETAILS_SUCCESS:
      return {
        ...state,
        fontDetails: action.payload,
        fontDetailsLoading: false,
      };

    case types.UPDATE_FONT_REQUEST:
      return { ...state, updatingFont: true };
    case types.UPDATE_FONT_SUCCESS:
      return {
        ...state,
        fontDetails: action.payload,
        updatingFont: false,
      };
    case types.UPDATE_FONT_ERROR:
      return { ...state, updatingFont: false };

    case types.UPDATE_FONT_FACE_REQUEST:
      return { ...state, updateFontFaceLoading: true };
    case types.UPDATE_FONT_FACE_COMPLETION:
      return {
        ...state,
        fontDetails: action.payload,
        updateFontFaceLoading: false,
      };
    case types.UPDATE_FONT_FACE_ERROR:
      return { ...state, updateFontFaceLoading: false };

    case types.UPLOAD_FONT_FACE_FILE_REQUEST:
      return { ...state, uploadFontFaceFileLoading: true };
    case types.UPLOAD_FONT_FACE_FILE_SUCCESS:
      return {
        ...state,
        fontDetails: action.payload,
        uploadFontFaceFileLoading: false,
      };
    case types.UPLOAD_FONT_FACE_FILE_ERROR:
      return { ...state, uploadFontFaceFileLoading: true };

    case types.DELETE_FONT_FACE_REQUEST:
      return {
        ...state,
        deleteFontFaceLoading: true,
      };
    case types.DELETE_FONT_FACE_SUCCESS:
      return {
        ...state,
        fontDetails: action.payload,
        deleteFontFaceLoading: false,
      };

    case types.GET_CUSTOM_FONTS_LIST_REQUEST:
      return { ...state, customFontsListLoading: true };

    case types.GET_CUSTOM_FONTS_LIST_COMPLETION:
      return {
        ...state,
        customFontsList: action.payload,
        customFontsListLoading: false,
      };

    case types.RESET_SETTINGS:
      return {};

    default:
      return state;
  }
};
