export const pages = (currentTenant, user) => [
  {
    key: "d",
    link: `/${currentTenant && currentTenant.id}`,
    name: "Dashboard",
  },
  {
    key: "p",
    link: `/${currentTenant && currentTenant.id}/profile/${user && user.name}`,
    name: "Profile",
  },
  {
    key: "u",
    link: `/${currentTenant && currentTenant.id}/users`,
    name: "People",
  },
  {
    key: "o",
    link: `/${currentTenant && currentTenant.id}/diagnostics`,
    name: "Diagnostics",
    allowedPermissions: ["ADMINISTRATOR"],
  },
  {
    key: "r",
    link: `/${currentTenant && currentTenant.id}/record-requests`,
    name: "Record Requests",
    allowedPermissions: ["ADMINISTRATOR"],
  },
  // {
  // 	key: "pr",
  // 	link: `/${currentTenant && currentTenant.id}/providers`,
  // 	name: "Providers",
  // },
  {
    key: "mc",
    link: `/${currentTenant && currentTenant.id}/medical-center`,
    name: "Medical Center",
  },
  {
    key: "vcb",
    link: `/${currentTenant && currentTenant.id}/virtual-consult`,
    name: "Virtual Consult",
  },
  {
    key: "rp",
    link: `/${currentTenant && currentTenant.id}/reports`,
    name: "Reports",
    allowedPermissions: ["ADMINISTRATOR"],
  },
  {
    key: "c",
    link: `/${currentTenant && currentTenant.id}/content/help-center`,
    name: "Content",
  },
];

export const settingsPages = (currentTenant) => [
  {
    key: "n",
    link: `/${currentTenant && currentTenant.id}/settings/configuration`,
    name: "Configuration",
  },
  {
    key: "v",
    link: `/${currentTenant && currentTenant.id}/settings/verbiage/screens`,
    name: "Verbiage",
  },
  {
    key: "t",
    link: `/${
      currentTenant && currentTenant.id
    }/settings/system-messages/email-safe`,
    name: "System Messages",
  },
];
