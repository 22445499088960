import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_DIAGNOSTIC_REPORT_REQUEST = "GET_DIAGNOSTIC_REPORT_REQUEST";
export const GET_DIAGNOSTIC_REPORT_SUCCESS = "GET_DIAGNOSTIC_REPORT_SUCCESS";

//=========Thunks===========

export const getDiagnosticReport = (safeEMRId, encounterId, tenant) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_DIAGNOSTIC_REPORT_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/ehr/v2/Results/getDiagnosticReport/safeEMRId/${safeEMRId}/encounterid/${encounterId}/${tenant}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_DIAGNOSTIC_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
