import * as types from "./ContentActions";

//reducer for shared actions
export const content = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TENANT_CONTENT_REQUEST:
      return {
        // ...state,
        [action.contentType]: { ...state[action.contentType], loading: true },
      };

    case types.GET_TENANT_CONTENT_SUCCESS:
      return {
        // ...state,
        [action.contentType]: {
          ...state[action.contentType],
          list: {
            ...action.payload,
            page: action.page,
            pageSize: action.pageSize,
          },
          // list: action.payload,
          loading: false,
        },
      };

    case types.GET_TENANT_CONTENT_FAILURE:
      return {
        // ...state,
        [action.contentType]: {
          ...state[action.contentType],
          loading: false,
        },
      };

    case types.GET_CONTENT_REQUEST:
      return {
        ...state,
        [action.contentType]: {
          ...state[action.contentType],
          selected: undefined,
          loadingContent: true,
        },
      };

    case types.GET_CONTENT_SUCCESS:
      return {
        ...state,
        [action.contentType]: {
          ...state[action.contentType],
          selected: action.payload,
          loadingContent: false,
        },
      };

    default:
      return state;
  }
};
