import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import history from "../Shared/Redux/history";
import utils from "../Shared/Utils/utils";
import { handleError } from "../Shared/Content/APIUtils";
import {
  submitFailed,
  removeFailed,
  remove,
} from "../Shared/Forms/FormActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANT_CONTENT_REQUEST = "GET_TENANT_CONTENT_REQUEST";
export const GET_TENANT_CONTENT_SUCCESS = "GET_TENANT_CONTENT_SUCCESS";
export const GET_TENANT_CONTENT_FAILURE = "GET_TENANT_CONTENT_FAILURE";

export const GET_CONTENT_REQUEST = "GET_CONTENT_REQUEST";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";

export const ADD_CONTENT_REQUEST = "ADD_CONTENT_REQUEST";
export const ADD_CONTENT_SUCCESS = "ADD_ARTICLE_SUCCESS";

export const UPDATE_CONTENT_REQUEST = "UPDATE_CONTENT_REQUEST";
export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";

//=========Thunks===========

export const getContentList = (
  type,
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter
) => async (dispatch) => {
  dispatch({ type: GET_TENANT_CONTENT_REQUEST, contentType: type });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/${type}`,
    params: {
      tenantID: tenant,
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
      permission: filter,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      if (response.data.isOK) {
        dispatch({
          type: GET_TENANT_CONTENT_SUCCESS,
          payload: response.data,
          contentType: type,
          page: page,
          pageSize: pageSize,
        });
      } else {
        dispatch({
          type: GET_TENANT_CONTENT_FAILURE,
          contentType: type,
        });
        dispatch(
          showNotification(
            response.data.errorMessage ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const getContent = (type, tenant, id) => async (dispatch) => {
  dispatch({ type: GET_CONTENT_REQUEST, contentType: type });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");

  return axios({
    method: "GET",
    url: `${API_ROOT}/content/${type.substring(0, type.length - 1)}/` + id,
    withCredentials: true,
    crossorigin: true,
    params: {
      tenantID: tenant,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CONTENT_SUCCESS,
        payload: response.data,
        contentType: type,
      });
    })
    .catch((error) => {
      dispatch(handleError(error));
    });
};

export const addContent = (type, tenant, data) => async (dispatch) => {
  dispatch({ type: ADD_CONTENT_REQUEST, contentType: type });

  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");

  return axios({
    method: "POST",
    url: `${API_ROOT}/content/create/${type.substring(0, type.length - 1)}`,
    data: {
      ...data,
      tenantID: tenant,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      dispatch({
        type: ADD_CONTENT_SUCCESS,
        payload: response.data,
        contentType: type,
      });
      dispatch(showNotification("Successfully Added", "success"));

      if (type === "introWidgets") {
        history.push(
          `/${tenant}/settings/configuration/assets/${utils.camelToSnake(type)}`
        );
      } else {
        history.push(`/${tenant}/content/${utils.camelToSnake(type)}`);
      }

      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch(submitFailed());
      dispatch(handleError(error));
    });
};

export const updateContent = (type, tenant, data) => async (dispatch) => {
  dispatch({ type: UPDATE_CONTENT_REQUEST, contentType: type });

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");

  return axios({
    method: "PUT",
    url: `${API_ROOT}/content/update/${type.substring(0, type.length - 1)}`,
    data: {
      ...data,
      tenantID: tenant,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenant}/${user_id}`,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CONTENT_SUCCESS,
        payload: response.data,
        contentType: type,
      });
      dispatch(showNotification("Successfully Updated", "success"));

      if (type === "introWidgets") {
        history.push(
          `/${tenant}/settings/configuration/assets/${utils.camelToSnake(type)}`
        );
      } else {
        history.push(`/${tenant}/content/${utils.camelToSnake(type)}`);
      }
    })
    .catch((error) => {
      dispatch(submitFailed());
      dispatch(handleError(error));
    });
};

export const deleteContent = (type, tenantID, id) => async (dispatch) => {
  dispatch({ type: DELETE_CONTENT_REQUEST, contentType: type });
  dispatch(remove());

  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  const user_id = localStorage.getItem("user_id");

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/content/delete/${type.substring(
      0,
      type.length - 1
    )}/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
      userId: `${tenantID}/${user_id}`,
    },
  })
    .then((response) => {
      if (type === "introWidgets") {
        history.push(
          `/${tenantID}/settings/configuration/assets/${utils.camelToSnake(
            type
          )}`
        );
      } else {
        history.push(`/${tenantID}/content/${utils.camelToSnake(type)}`);
      }

      dispatch(showNotification("Successfully Deleted", "success"));
      dispatch({
        type: GET_CONTENT_SUCCESS,
        payload: response.data,
        contentType: type,
      });
    })
    .catch((error) => {
      dispatch(removeFailed());
      dispatch(handleError(error));
    });
};
