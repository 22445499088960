import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import FileDownload from "js-file-download";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

export const DOWNLOAD_REPORTS_REQUEST = "DOWNLOAD_REPORTS_REQUEST";
export const DOWNLOAD_REPORTS_SUCCESS = "DOWNLOAD_REPORTS_SUCCESS";
export const DOWNLOAD_REPORTS_FAILURE = "DOWNLOAD_REPORTS_FAILURE";

export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";

//=========Thunks===========

export const getReports = (
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter,
  noLoad,
  refresh
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_REPORTS_REQUEST, noLoad });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/reports/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort: sort,
      direction: direction,
      permission: filter,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_REPORTS_SUCCESS,
        payload: response.data,
      });
      if (refresh) {
        dispatch(showNotification("Reports successfully synced", "success"));
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const downloadReport = (tenant, reportId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: DOWNLOAD_REPORTS_REQUEST });

  axios({
    method: "GET",
    url: `${API_ROOT}/admin/reports/${tenant}/${reportId}/dl`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "blob",
  })
    .then((response) => {
      FileDownload(response.data, `${reportId}.csv`);
      dispatch({ type: DOWNLOAD_REPORTS_SUCCESS });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Unable to download report. Please try again later.",
          "error"
        )
      );
    });
};

export const getReportPreview = (tenant, reportId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/reports/${tenant}/${reportId}/dl`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json;charset=UTF-8",
      // Accept: "application/pdf",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
    responseType: "arraybuffer",
  })
    .then((response) => {
      return new TextDecoder().decode(response.data);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Unable to download report. Please try again later.",
          "error"
        )
      );
      return false;
    });
};

export const createReport = (
  tenant,
  reportType,
  page,
  pageSize,
  query,
  sort,
  direction,
  filter
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: CREATE_REPORT_REQUEST });

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/reports/${tenant}`,
    params: {
      reportType,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification(
          "Your report request has been received and will be available in this page shortly.",
          "info"
        )
      );
      dispatch(
        getReports(tenant, page, pageSize, query, sort, direction, filter, true)
      );
      dispatch({
        type: CREATE_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
