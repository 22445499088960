// src/App.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import { Offline } from "react-detect-offline";
import IdleTimer from "react-idle-timer";

// Redux
import { connect } from "react-redux";
import history from "./components/Shared/Redux/history";

import AppStyles from "./assets/jss/components/AppStyles";

// MUI
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { CircularProgress, Grid } from "@material-ui/core";

// Kendo
import "./styles/Kendo.scss";
import "./styles/index.css";

//components
import PrivateRoute from "./components/Shared/PrivateRoute";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Notification from "./components/Notifications/Notification";
import Confirmation from "./components/Confirmation/Confirmation";

const HomeView = React.lazy(() => import("./views/HomeView"));
const ImporterView = React.lazy(() => import("./views/ImporterView"));
const ImportStatusView = React.lazy(() => import("./views/ImportStatusView"));
const OrdersView = React.lazy(() => import("./views/OrdersView"));
// const ProvidersView = React.lazy(() => import("./views/ProvidersView"));
const VirtualConsultView = React.lazy(() =>
  import("./views/VirtualConsultView")
);
const VirtualConsultCreateView = React.lazy(() =>
  import("./views/VirtualConsultCreateView")
);
const DiagnosticReportView = React.lazy(() =>
  import("./views/DiagnosticReportView")
);
const MedicalCenterView = React.lazy(() => import("./views/MedicalCenterView"));
const MedicalCenterFormView = React.lazy(() =>
  import("./views/MedicalCenterFormView")
);
const AlertsView = React.lazy(() => import("./views/AlertsView"));
const UsersView = React.lazy(() => import("./views/UsersView"));
const UserCreateView = React.lazy(() => import("./views/UserCreateView"));
const NetworkView = React.lazy(() => import("./views/NetworkView"));
const ProfileView = React.lazy(() => import("./views/ProfileView"));
const NotFoundView = React.lazy(() => import("./views/NotFoundView"));
const SettingsView = React.lazy(() => import("./views/SettingsView"));
const ContentListView = React.lazy(() => import("./views/ContentListView"));
const ContentCreateView = React.lazy(() => import("./views/ContentCreateView"));
const ContentEditView = React.lazy(() => import("./views/ContentEditView"));
const AssetsCreateView = React.lazy(() => import("./views/AssetsCreateView"));
const AssetsEditView = React.lazy(() => import("./views/AssetsEditView"));
const TenantFormView = React.lazy(() => import("./views/TenantFormView"));
const AccountView = React.lazy(() => import("./views/AccountView"));
const ReportingView = React.lazy(() => import("./views/ReportingView"));
const RecordRequestsView = React.lazy(() =>
  import("./views/RecordRequestsView")
);
const RecordRequestDetailView = React.lazy(() =>
  import("./views/RecordRequestDetailView")
);

const App = (prop) => {
  const { classes, currentTenant, currentNetwork } = prop;
  const [theme, setTheme] = useState({
    palette: {
      primary: {
        main: "#2E5266",
        contrastText: "#fff",
      },
      secondary: {
        main: "#CED0CE",
        contrastText: "#fff",
      },
    },
  });
  const idleTimer = useRef(null);
  const { loading, logout } = useAuth0();

  useEffect(() => {
    if (currentTenant) {
      setTheme({
        palette: {
          primary: {
            main: currentTenant.color || "#2E5266",
            contrastText: "#fff",
          },
          secondary: {
            main: "#CED0CE",
            contrastText: "#fff",
          },
        },
        overrides: {
          MuiButton: {
            root: {
              borderRadius:
                currentNetwork && currentNetwork.buttonStyle === 0 ? 5 : 50,
            },
          },
        },
      });
    }
  }, [currentTenant, currentNetwork]);

  const onIdle = useCallback(
    (e) => {
      logout();
    },
    [idleTimer, logout]
  );

  if (loading) {
    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <Grid container justify={"center"}>
          <Grid item className={classes.loading}>
            <CircularProgress color={"primary"} />
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }

  if (sessionStorage.getItem("isLoggedIn") === null) {
    logout();
    sessionStorage.setItem("isLoggedIn", "true");
  }

  return (
    <div className="App">
      <IdleTimer
        ref={idleTimer}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={
          1000 *
          60 *
          (currentNetwork && currentNetwork.timeoutDuration
            ? currentNetwork.timeoutDuration
            : 10)
        }
      />
      <Router history={history}>
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <CssBaseline />
          <Notification />
          <Offline>
            <Notification offline />
          </Offline>
          <Confirmation />
          <Navbar />
          <Sidebar />
          <React.Suspense fallback={null}>
            <Grid
              container
              justify={"space-around"}
              className={classes.appWrapper}
            >
              <Grid item xs={11} className={classes.sidebar}>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/"
                    component={HomeView}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant"
                    render={(props) => (
                      <HomeView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users"
                    render={(props) => (
                      <UsersView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/import"
                    render={(props) => (
                      <ImporterView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/import/:id"
                    render={(props) => (
                      <ImportStatusView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/create"
                    render={(props) => (
                      <UserCreateView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/assessment"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/testing"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/scans"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/messages"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/consents"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/condition"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/medication"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/prescription"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/immunization"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/vital"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/procedure"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/ccd"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/quickconnect"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/users/details/:userId/clinical/eprescribe"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/diagnostics"
                    render={(props) => (
                      <OrdersView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/content/:type/details/:contentId"
                    render={(props) => (
                      <ContentEditView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/content/:type/create"
                    render={(props) => (
                      <ContentCreateView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/content/:type"
                    render={(props) => (
                      <ContentListView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/profile/:userId"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"profile"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/profile/:userId/assessment"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"profile"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/profile/:userId/testing"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"profile"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/profile/:userId/scans"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"profile"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/profile/:userId/messages"
                    render={(props) => (
                      <ProfileView
                        {...props}
                        currentTenant={currentTenant}
                        type={"profile"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/alerts"
                    render={(props) => (
                      <AlertsView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/account"
                    render={(props) => (
                      <AccountView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/account/details"
                    render={(props) => (
                      <AccountView
                        {...props}
                        currentTenant={currentTenant}
                        type={"accountDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/account/tenants"
                    render={(props) => (
                      <AccountView
                        {...props}
                        currentTenant={currentTenant}
                        type={"tenantManagement"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/account/permissions"
                    render={(props) => (
                      <AccountView
                        {...props}
                        currentTenant={currentTenant}
                        type={"permissions"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/account/tenants/create"
                    render={(props) => (
                      <TenantFormView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/system-messages/:tabType"
                    render={(props) => (
                      <SettingsView
                        {...props}
                        currentTenant={currentTenant}
                        type={"templates"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/system-messages/:tabType/edit/:id"
                    render={(props) => (
                      <SettingsView
                        {...props}
                        currentTenant={currentTenant}
                        type={"editor"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/verbiage/:tabType"
                    render={(props) => (
                      <SettingsView
                        {...props}
                        currentTenant={currentTenant}
                        type={"verbiage"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/configuration"
                    render={(props) => (
                      <NetworkView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/configuration/:type"
                    render={(props) => (
                      <NetworkView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/configuration/:type/:assetsType"
                    render={(props) => (
                      <NetworkView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/configuration/:type/:assetsType/details/:contentId"
                    render={(props) => (
                      <AssetsEditView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/settings/configuration/:type/:assetsType/create"
                    render={(props) => (
                      <AssetsCreateView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  {/* <PrivateRoute
									exact
									path="/:tenant/providers"
									render={(props) => (
										<ProvidersView {...props} currentTenant={currentTenant} />
									)}
									allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
								/>
								<PrivateRoute
									exact
									path="/:tenant/providers/providers"
									render={(props) => (
										<ProvidersView
											{...props}
											currentTenant={currentTenant}
											type={"providers"}
										/>
									)}
									allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
								/>
								<PrivateRoute
									exact
									path="/:tenant/providers/practitioners"
									render={(props) => (
										<ProvidersView
											{...props}
											currentTenant={currentTenant}
											type={"practitioners"}
										/>
									)}
									allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
								/>
								<PrivateRoute
									exact
									path="/:tenant/providers/provider-organizations"
									render={(props) => (
										<ProvidersView
											{...props}
											currentTenant={currentTenant}
											type={"organizations"}
										/>
									)}
									allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
								/>
								<PrivateRoute
									exact
									path="/:tenant/providers/unverified-providers"
									render={(props) => (
										<ProvidersView
											{...props}
											currentTenant={currentTenant}
											type={"unverified"}
										/>
									)}
									allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
								/> */}

                  <PrivateRoute
                    exact
                    path="/:tenant/medical-center"
                    render={(props) => (
                      <MedicalCenterView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/medical-center/test-orderable"
                    render={(props) => (
                      <MedicalCenterView
                        {...props}
                        currentTenant={currentTenant}
                        type={"testOrderable"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/medical-center/test-panels"
                    render={(props) => (
                      <MedicalCenterView
                        {...props}
                        currentTenant={currentTenant}
                        type={"testPanels"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/medical-center/:type/create"
                    render={(props) => (
                      <MedicalCenterFormView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/medical-center/:type/details/:id"
                    render={(props) => (
                      <MedicalCenterFormView
                        {...props}
                        currentTenant={currentTenant}
                        edit
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult"
                    render={(props) => (
                      <VirtualConsultView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/questionnaires"
                    render={(props) => (
                      <VirtualConsultView
                        {...props}
                        currentTenant={currentTenant}
                        type={"questionnaire"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/questions"
                    render={(props) => (
                      <VirtualConsultView
                        {...props}
                        currentTenant={currentTenant}
                        type={"question"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/user-segments"
                    render={(props) => (
                      <VirtualConsultView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userSegments"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/user-segments/create"
                    render={(props) => (
                      <VirtualConsultCreateView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userSegment"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/user-segments/details/:id"
                    render={(props) => (
                      <VirtualConsultCreateView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userSegment"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/virtual-consult/questionnaire-outcomes"
                    render={(props) => (
                      <VirtualConsultView
                        {...props}
                        currentTenant={currentTenant}
                        type={"questionnaireOutcomes"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/reports"
                    render={(props) => (
                      <ReportingView {...props} currentTenant={currentTenant} />
                    )}
                    allowed={["ADMINISTRATOR"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/diagnostic-report/:nationalHealthId/:encounterId/:userId"
                    render={(props) => (
                      <DiagnosticReportView
                        {...props}
                        currentTenant={currentTenant}
                        type={"diagnosticReport"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests"
                    render={(props) => (
                      <RecordRequestsView
                        {...props}
                        currentTenant={currentTenant}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/condition"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/medication"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/prescription"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/immunization"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/vital"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/procedure"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/ccd"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/quickconnect"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:tenant/record-requests/details/:userEmail/:rrId/clinical/eprescribe"
                    render={(props) => (
                      <RecordRequestDetailView
                        {...props}
                        currentTenant={currentTenant}
                        type={"userDetails"}
                      />
                    )}
                    allowed={["ADMINISTRATOR", "ADMINISTRATOR_RESTRICTED"]}
                  />
                  <Route
                    path="*"
                    component={() => (
                      <NotFoundView currentTenant={currentTenant} />
                    )}
                  />
                </Switch>
              </Grid>
            </Grid>
          </React.Suspense>
        </MuiThemeProvider>
      </Router>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    currentTenant: state.shared.currentTenant,
    currentNetwork: state.network.tenantNetwork,
  };
}

export default connect(mapStateToProps)(withStyles(AppStyles)(App));
