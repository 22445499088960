import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import history from "../Shared/Redux/history";
import { submitFailed } from "../Shared/Forms/FormActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_TENANT_USERS_REQUEST = "GET_TENANT_USERS_REQUEST";
export const GET_TENANT_USERS_SUCCESS = "GET_TENANT_USERS_SUCCESS";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const GET_CURRENT_USER_PERMISSION_REQUEST =
  "GET_CURRENT_USER_PERMISSION_REQUEST";
export const GET_CURRENT_USER_PERMISSION_SUCCESS =
  "GET_CURRENT_USER_PERMISSION_SUCCESS";
export const GET_USER_PERMISSION_REQUEST = "GET_USER_PERMISSION_REQUEST";
export const GET_USER_PERMISSION_SUCCESS = "GET_USER_PERMISSION_SUCCESS";

export const GET_USER_PIC_REQUEST = "GET_USER_PIC_REQUEST";
export const GET_USER_PIC_SUCCESS = "GET_USER_PIC_SUCCESS";

export const UPLOAD_USER_PIC_REQUEST = "UPLOAD_USER_PIC_REQUEST";
export const UPLOAD_USER_PIC_SUCCESS = "UPLOAD_USER_PIC_SUCCESS";

export const GET_OTHER_USER_PIC_REQUEST = "GET_OTHER_USER_PIC_REQUEST";
export const GET_OTHER_USER_PIC_SUCCESS = "GET_OTHER_USER_PIC_SUCCESS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const GENERATE_USER_NHI = "GENERATE_USER_NHI";

export const GET_USER_Q_REQUEST = "GET_USER_Q_REQUEST";
export const GET_USER_Q_SUCCESS = "GET_USER_Q_SUCCESS";

export const GET_USER_MESSAGES_REQUEST = "GET_USER_MESSAGES_REQUEST";
export const GET_USER_MESSAGES_SUCCESS = "GET_USER_MESSAGES_SUCCESS";

export const GET_OTHER_USER_QR_REQUEST = "GET_OTHER_USER_QR_REQUEST";
export const GET_OTHER_USER_QR_SUCCESS = "GET_OTHER_USER_QR_SUCCESS";

export const GET_USER_ACCOUNT_PERMISSION_REQUEST =
  "GET_USER_ACCOUNT_PERMISSION_REQUEST";
export const GET_USER_ACCOUNT_PERMISSION_SUCCESS =
  "GET_USER_ACCOUNT_PERMISSION_SUCCESS";

export const BULK_UPLOAD_USERS_REQUEST = "BULK_UPLOAD_USERS_REQUEST";
export const BULK_UPLOAD_USERS_PREVIEW = "BULK_UPLOAD_USERS_PREVIEW";
export const BULK_UPLOAD_USERS_SUCCESS = "BULK_UPLOAD_USERS_SUCCESS";
export const BULK_UPLOAD_USERS_FAILURE = "BULK_UPLOAD_USERS_FAILURE";
export const RESET_BULK_UPLOAD_ERROR = "RESET_BULK_UPLOAD_ERROR";
export const RESET_UPLOAD_USERS_PREVIEW = "RESET_UPLOAD_USERS_PREVIEW";

export const BULK_UPLOAD_INFO_REQUEST = "BULK_UPLOAD_INFO_REQUEST";
export const BULK_UPLOAD_INFO_SUCCESS = "BULK_UPLOAD_INFO_SUCCESS";
export const BULK_UPLOAD_INFO_FAILURE = "BULK_UPLOAD_INFO_FAILURE";

export const BULK_USER_UPLOADS_INFO_REQUEST = "BULK_USER_UPLOADS_INFO_REQUEST";
export const BULK_USER_UPLOADS_INFO_SUCCESS = "BULK_USER_UPLOADS_INFO_SUCCESS";
export const BULK_USER_UPLOADS_INFO_FAILURE = "BULK_USER_UPLOADS_INFO_FAILURE";

export const RESET_USERS = "RESET_USERS";

export const resetUsers = () => ({
  type: RESET_USERS,
});

//=========Thunks===========

// ***************** GET USER DETAILS ENDPOINTS ***************** //
export const getUsers = (
  tenant,
  page,
  pageSize,
  query,
  sort,
  direction,
  permission,
  gender,
  userSegment
) => {
  const thunk = (dispatch, getState) => {
    const access_token = localStorage.getItem("access_token");
    const id_token = localStorage.getItem("id_token");
    dispatch({ type: GET_TENANT_USERS_REQUEST });

    axios({
      method: "GET",
      url: `${API_ROOT}/admin/user/${tenant}`,
      params: {
        skip: page * pageSize,
        limit: pageSize,
        q: query,
        sort: sort,
        direction: direction,
        permission,
        gender,
        userSegment,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token,
      },
    })
      .then((response) => {
        dispatch({
          type: GET_TENANT_USERS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log("ERROR", error);
        dispatch(
          showNotification(
            error.response.data.message ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      });
  };

  thunk.meta = {
    debounce: {
      time: 500,
      key: "search-users",
    },
  };
  return thunk;
};

export const getCurrentUser = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_CURRENT_USER_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user`,
    params: {
      id: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUser = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_USER_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user`,
    params: {
      id: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const generateUserNhi = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GENERATE_USER_NHI });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/user/nationalHealthId?id=${userId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
      dispatch(
        showNotification("Successfully Generated National Health ID", "success")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserQRCode = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: GET_OTHER_USER_QR_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/code`,
    params: {
      id: userId,
      width: 130,
    },
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      let url = URL.createObjectURL(response.data);
      let img = new Image();
      img.src = url;
      img.alt = "user qr code";

      dispatch({
        type: GET_OTHER_USER_QR_SUCCESS,
        payload: { img, userId },
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const resetUserPassword = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/reset-password`,
    params: {
      id: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("Reset Password Email Sent Successfully", "success")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** USER ACCOUNT ENDPOINTS ***************** //
export const getUserAccountPermission = () => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_USER_ACCOUNT_PERMISSION_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/account/permission`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_ACCOUNT_PERMISSION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const deleteUserAccount = (id, tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/user`,
    params: {
      id,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("User successfully deleted from account.", "success")
      );
      history.push(`/${tenant}/users`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** USER TENANT PERMISSION ENDPOINTS ***************** //
export const inviteUser = (tenant, permission, uId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/${tenant}/${permission}`,
    params: {
      uId,
      checkExistingPermission: true,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(showNotification("User invite successfully sent.", "success"));
      return Promise.resolve(response.status);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      if (error.response.status !== 404) {
        dispatch(
          showNotification(
            error.response.data && error.response.data.message,
            "error"
          )
        );
      }
      return Promise.resolve(error.response.status);
    });
};

export const deleteUserTenant = (uId, tenant) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/user/${tenant}/permission`,
    params: {
      uId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(
        showNotification("User successfully removed from tenant.", "success")
      );
      history.push(`/${tenant}/users`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserPermissions = (tenant, userId, currentUser) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  if (currentUser) {
    dispatch({ type: GET_CURRENT_USER_PERMISSION_REQUEST });
  } else {
    dispatch({ type: GET_USER_PERMISSION_REQUEST });
  }

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/${tenant}/permission`,
    params: {
      id: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      if (currentUser) {
        dispatch({
          type: GET_CURRENT_USER_PERMISSION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_USER_PERMISSION_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateUserPermissions = (tenant, permission, uId) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  // dispatch({type: GET_USER_PERMISSION_REQUEST});

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/${tenant}/${permission}`,
    params: {
      uId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_PERMISSION_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("Permission successfully updated.", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const toggleAnalyticsField = (tenant, userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/user/${tenant}/permission/analytics?id=${userId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_PERMISSION_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("User Successfully Updated", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** USER PROFILE PICTURE ENDPOINTS ***************** //
export const getUserPic = (userId, currentUser) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  if (currentUser) {
    dispatch({ type: GET_USER_PIC_REQUEST });
  } else {
    dispatch({ type: GET_OTHER_USER_PIC_REQUEST });
  }

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/pic`,
    params: {
      username: userId,
    },
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      let url = URL.createObjectURL(response.data);
      let img = new Image();
      img.src = url;
      img.alt = "user profile avatar";

      if (currentUser) {
        dispatch({
          type: GET_USER_PIC_SUCCESS,
          payload: img,
        });
      } else {
        dispatch({
          type: GET_OTHER_USER_PIC_SUCCESS,
          payload: { img, userId },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const uploadUserPic = (file) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPLOAD_USER_PIC_REQUEST });

  const formData = new FormData();
  formData.append("image", file);

  let url = URL.createObjectURL(file);
  let img = new Image();
  img.src = url;
  img.alt = "user profile avatar";

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/pic`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: UPLOAD_USER_PIC_SUCCESS,
        payload: img,
      });
      dispatch(
        showNotification("Profile Picture Successfully Uploaded", "success")
      );
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const addUser = (tenant, permission, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: ADD_USER_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/${tenant}`,
    data,
    params: {
      permission,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("User Successfully Added", "success"));
      dispatch(getUsers(tenant, 0, 10, "", "email", "ASC", null));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const updateUser = (data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: UPDATE_USER_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/user`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("User Successfully Updated", "success"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** USER DETAIL TAB ENDPOINTS ***************** //
export const getUserQuestionnaire = (
  page,
  pageSize,
  sort,
  direction,
  username,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_USER_Q_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/questionnaire/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort,
      direction,
      username,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_Q_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserMessages = (
  page,
  pageSize,
  sort,
  direction,
  username,
  tenant
) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_USER_MESSAGES_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/message/${tenant}`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort,
      direction,
      username,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_MESSAGES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

// ***************** BULK USER IMPORT ENDPOINTS ***************** //
export const bulkImport = (
  tenant,
  data,
  forceUpload = false,
  preview = false
) => (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: BULK_UPLOAD_USERS_REQUEST });

  const formData = new FormData();
  formData.append("dataset", data.files[0]);

  if (forceUpload) formData.append("force", String(forceUpload));
  if (preview) formData.append("preview", String(preview));

  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/user/${tenant.id}/bulk`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch(submitFailed());

      if (preview) {
        dispatch({ type: BULK_UPLOAD_USERS_PREVIEW, payload: response.data });
      } else {
        dispatch({ type: BULK_UPLOAD_USERS_SUCCESS, payload: response.data });
        history.push(`/${tenant.id}/users/import/${response.data.id}`);
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(submitFailed());
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({ type: BULK_UPLOAD_USERS_FAILURE, error: error.response.data });
    });
};

export const getBulkInfo = (tenant, id) => (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: BULK_UPLOAD_INFO_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/${tenant.id}/bulk/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "*/*",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({ type: BULK_UPLOAD_INFO_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({ type: BULK_UPLOAD_INFO_FAILURE, error: error.response.data });
    });
};

export const getUserBulks = (tenant, email, skip, limit, sort, direction) => (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  dispatch({ type: BULK_USER_UPLOADS_INFO_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/user/${tenant.id}/bulk`,
    params: {
      skip: skip || 0,
      limit: limit || 5,
      sort,
      direction,
      light: true,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "*/*",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: BULK_USER_UPLOADS_INFO_SUCCESS,
        payload: response.data,
        userId: email,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({
        type: BULK_USER_UPLOADS_INFO_FAILURE,
        error: error.response.data,
      });
    });
};

export const getMetadataValues = (tenant, metadataKey) => async (dispatch) => {
  // HEADER INFO
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");

  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/dashboard/${tenant}/metadata-values/${metadataKey}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      let values = response.data;
      if (metadataKey === "User Segment") {
        let sorted =
          (response.data.length &&
            response.data.sort((a, b) => {
              let segmentA = a.toUpperCase();
              let segmentB = b.toUpperCase();
              return segmentA < segmentB ? -1 : segmentA > segmentB ? 1 : 0;
            })) ||
          [];
        if (sorted.includes("Base User Segment")) {
          values = sorted;
        } else {
          values = [...sorted, "Base User Segment"];
        }
      }
      return values;
    })
    .catch((error) => {
      console.log(error.response.data);
      return null;
    });
};
