const NoAccessViewStyles = (theme) => ({
  noAccess: {
    marginTop: "50px",
    marginLeft: -25,
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
      marginLeft: 0,
    },
  },
  card: {
    padding: "50px",
    textAlign: "center",
    fontSize: "4vw",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
      "& h5": {
        fontSize: "5vw",
      },
    },
  },
});

export default NoAccessViewStyles;
