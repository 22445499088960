import axios from "axios";
import { showNotification } from "../../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;
//const API_ROOT = "https://localhost:44305/v1/api";

//========Action Types=========

export const GET_PERMISSION_LIST_REQUEST = "GET_PERMISSION_LIST_REQUEST";
export const GET_PERMISSION_LIST_SUCCESS = "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_FAILURE = "GET_PERMISSION_LIST_FAILURE";

export const SET_PERMISSION_LIST_REQUEST = "SET_PERMISSION_LIST_REQUEST";
export const SET_PERMISSION_LIST_SUCCESS = "SET_PERMISSION_LIST_SUCCESS";
export const SET_PERMISSION_LIST_FAILURE = "SET_PERMISSION_LIST_FAILURE";
export const SET_PERMISSION_LIST_RESET = "SET_PERMISSION_LIST_RESET";

export const REMOVE_PERMISSION_REQUEST = "REMOVE_PERMISSION_REQUEST";
export const REMOVE_PERMISSION_SUCCESS = "REMOVE_PERMISSION_SUCCESS";
export const REMOVE_PERMISSION_FAILURE = "REMOVE_PERMISSION_FAILURE";

//=========Thunks===========

export const getList = (page, pageSize, sort, direction) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({
    type: GET_PERMISSION_LIST_REQUEST,
  });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/account/permissions`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort: sort,
      direction: direction,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_PERMISSION_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({
        type: GET_PERMISSION_LIST_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const setPermission = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({
    type: SET_PERMISSION_LIST_REQUEST,
  });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/admin/account/permission`,
    params: {
      uId: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_PERMISSION_LIST_SUCCESS,
      });
      dispatch(showNotification("Permission successfully set", "info"));
      dispatch(getList(0, 10, "userId", "ASC"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({
        type: SET_PERMISSION_LIST_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const resetForm = () => async (dispatch) => {
  dispatch({ type: SET_PERMISSION_LIST_RESET });
};

export const removePermission = (userId) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({
    type: REMOVE_PERMISSION_REQUEST,
  });
  return axios({
    method: "DELETE",
    url: `${API_ROOT}/admin/account/permission`,
    params: {
      uId: userId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: REMOVE_PERMISSION_SUCCESS,
      });
      dispatch(showNotification("Permission successfully removed", "info"));
      dispatch(getList(0, 10, "userId", "ASC"));
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      dispatch({
        type: REMOVE_PERMISSION_FAILURE,
        error: error.response.data.message,
      });
    });
};
