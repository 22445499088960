import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { connect } from "react-redux";

//styling imports
import NoAccessViewStyles from "../assets/jss/components/NoAccessViewStyles";

//material-UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

const NoAccessView = (props) => {
  const { classes, currentUserPermissionsLoading, currentTenant } = props;
  const { isAuthenticated } = useAuth0();

  if (!currentTenant || currentUserPermissionsLoading || !isAuthenticated)
    return <div />;
  return (
    <Grid container justify="center" className={classes.noAccess}>
      <Grid item xs={10}>
        <Card variant="outlined" className={classes.card}>
          <WarningRoundedIcon color="secondary" style={{ fontSize: 100 }} />
          <CardContent>
            <Typography variant="h5">
              We're sorry... You do not have permission to access this page.
            </Typography>
            <Typography variant="h5" style={{ marginTop: "25px" }}>
              Please contact your administrator if you believe you received this
              message in error.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    currentUserPermissions: state.users.currentUserPermissions,
    currentUserPermissionsLoading: state.users.currentUserPermissionsLoading,
    currentTenant: state.shared.currentTenant,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(NoAccessViewStyles)(NoAccessView));
