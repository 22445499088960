import axios from "axios";
import { showNotification } from "../../Notifications/NotificationsActions";
import history from "../../Shared/Redux/history";
import { getMyTenants } from "../../Shared/SharedActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const GET_ALL_TENANTS_REQUEST = "GET_ALL_TENANTS_REQUEST";
export const GET_ALL_TENANTS_SUCCESS = "GET_ALL_TENANTS_SUCCESS";
export const GET_ALL_TENANTS_FAILURE = "GET_ALL_TENANTS_FAILURE";

export const GET_TENANT_REQUEST = "GET_TENANT_REQUEST";
export const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS";
export const GET_TENANT_FAILURE = "GET_TENANT_FAILURE";

export const ADD_TENANT_REQUEST = "ADD_TENANT_REQUEST";
export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
export const ADD_TENANT_FAILURE = "ADD_TENANT_FAILURE";

//=========Thunks===========

export const getTenants = (page, pageSize, sort, direction) => async (
  dispatch
) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_ALL_TENANTS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/tenant`,
    params: {
      skip: page * pageSize,
      limit: pageSize,
      sort: sort,
      direction: direction,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_ALL_TENANTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);

      if (error.response.status !== 401) {
        dispatch(
          showNotification(
            error.response.data.message ||
              "Oops! Something went wrong. Please try again.",
            "error"
          )
        );
      }

      dispatch({
        type: GET_ALL_TENANTS_FAILURE,
        error:
          error.response.data.message ||
          "Oops! Something went wrong. Please try again.",
      });
    });
};

export const getTenant = (id) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: GET_TENANT_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/admin/tenant/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      dispatch({
        type: GET_TENANT_FAILURE,
        error:
          error.response.data.message ||
          "Oops! Something went wrong. Please try again.",
      });
    });
};

export const addTenant = (tenantId, data) => async (dispatch) => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  dispatch({ type: ADD_TENANT_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/admin/tenant`,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TENANT_SUCCESS,
        payload: response.data,
      });

      dispatch(showNotification("Tenant Successfully Created", "success"));
      dispatch(getMyTenants());
      history.push(`/${tenantId}/account/tenants`);
    })
    .catch((error) => {
      console.log("ERROR", error);
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );

      dispatch({
        type: GET_TENANT_FAILURE,
        error:
          error.response.data.message ||
          "Oops! Something went wrong. Please try again.",
      });
    });
};

export const getChannels = () => async () => {
  const access_token = localStorage.getItem("access_token");
  const id_token = localStorage.getItem("id_token");
  return axios({
    method: "GET",
    url: `${API_ROOT}/content/ChannelPartner`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};
