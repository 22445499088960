import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import NotificationStyles from "../../assets/jss/components/NotificationStyles";

//redux additions
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "./NotificationsActions";

//material-ui components
import {
  Backdrop,
  Snackbar,
  SnackbarContent,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import CancelIcon from "@material-ui/icons/Cancel";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export const Notification = (props) => {
  const { classes, notification, snackbar, actions, offline, network } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = (event, reason) => {
    //closing notification
    // if (event) {
    //   event.preventDefault();
    // }
    if (reason === "clickaway") {
      return;
    }
    actions.hideNotification();
  };

  const handleUpdate = async (event) => {
    //update service worker
    if (event) {
      event.preventDefault();
    }
    if (navigator && navigator.serviceWorker) {
      const waitingServiceWorker = await navigator.serviceWorker.ready;

      if (waitingServiceWorker.waiting) {
        waitingServiceWorker.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    }
    actions.hideNotification();
  };

  const getBackgroundColor = () => {
    switch (notification.notificationType) {
      case "error":
        return network.tenantNetwork && network.tenantNetwork.errorColor
          ? network.tenantNetwork.errorColor
          : "#FF6464";
      case "success":
        return network.tenantNetwork && network.tenantNetwork.successColor
          ? network.tenantNetwork.successColor
          : "#76D873";
      case "warning":
        return network.tenantNetwork && network.tenantNetwork.warningColor
          ? network.tenantNetwork.warningColor
          : "#ffc12a";
      default:
        return network.tenantNetwork && network.tenantNetwork.warningColor
          ? network.tenantNetwork.warningColor
          : "#304075";
    }
  };

  return (
    <div>
      {!notification.update && (
        <Snackbar
          open={notification.visible}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
          {...snackbar}
        >
          <SnackbarContent
            className={classNames(
              classes.snackBar,
              classes[notification.notificationType]
            )}
            classes={{
              action: classes.contentAction,
              message: classes.contentMessage,
              root: classes.root,
            }}
            style={{ backgroundColor: getBackgroundColor() }}
            aria-describedby="client-snackbar"
            message={
              <span
                id="client-snackbar"
                className={
                  classes[
                    (`message-${notification.notificationType}`, "message-text")
                  ]
                }
              >
                {notification.message}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes[`closeIcon`]}
                onClick={handleClose}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      )}
      {notification.update && (
        <Snackbar
          open={notification.visible}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          {...snackbar}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            className={classNames(
              classes.snackBar,
              classes[notification.notificationType]
            )}
            message={
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    variant={"body2"}
                    className={classes.updateMessage}
                    id="client-snackbar"
                  >
                    {notification.message}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"body2"}
                    onClick={handleUpdate}
                    className={classes.updateNow}
                  >
                    Update Now
                  </Typography>
                </Grid>
              </Grid>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes[`closeIcon`]}
                onClick={handleClose}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      )}
      {offline && (
        <Backdrop open={true} style={{ zIndex: 5000 }}>
          <Snackbar
            open={true}
            onClose={handleClose}
            anchorOrigin={{
              vertical: matches ? "center" : "bottom",
              horizontal: "center",
            }}
            {...snackbar}
          >
            <SnackbarContent
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.updateMessage}>
                  <WifiOffIcon className={classes.icon} />
                  Internet connection lost. Check network and try again.
                </span>
              }
            />
          </Snackbar>
        </Backdrop>
      )}
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object,
};

Notification.defaultProps = {
  notification: {
    message: null,
    visible: false,
    notificationType: "info",
    update: false,
  },
};

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(notificationActions, dispatch) };
}

//middleware
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NotificationStyles)(Notification));
