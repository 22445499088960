import * as types from "./TenantActions";

//reducer for shared actions
export const tenants = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ALL_TENANTS_REQUEST:
      return { ...state, loading: true, error: null };

    case types.GET_ALL_TENANTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case types.GET_ALL_TENANTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.GET_TENANT_REQUEST:
      return {
        ...state,
        selected: undefined,
        loadingTenant: true,
        tenantError: undefined,
      };

    case types.GET_TENANT_SUCCESS:
      return {
        ...state,
        loadingTenant: false,
        addTenantLoading: false,
        selected: action.payload,
      };

    case types.GET_TENANT_FAILURE:
      return {
        ...state,
        loadingTenant: false,
        addTenantLoading: false,
        tenantError: action.error,
      };

    case types.ADD_TENANT_REQUEST:
      return {
        ...state,
        addTenantLoading: true,
      };

    default:
      return state;
  }
};
