import { connect } from "react-redux";

// check allowed permissions against both tenant and account permissions for access
const checkPermissions = (
  currentUserPermissions,
  allowedPermissions,
  deniedPermissions,
  userAccountPermission
) => {
  if (
    (allowedPermissions.length === 0 && deniedPermissions.length === 0) ||
    !userAccountPermission
  ) {
    return true;
  }

  if (deniedPermissions.length > 0) {
    return !!!deniedPermissions.includes(
      (currentUserPermissions && currentUserPermissions.permission) ||
        (userAccountPermission && userAccountPermission.permission)
    );
  }

  return allowedPermissions.includes(
    (currentUserPermissions && currentUserPermissions.permission) ||
      (userAccountPermission && userAccountPermission.permission)
  );
};

// check restricted pages against tenant settings and account admin permissions for "admin" access
const checkRestrictedPages = (
  currentPage,
  tenantSettings,
  userAccountPermission
) => {
  if (!tenantSettings || !currentPage || !userAccountPermission) {
    return true;
  }
  let pages = Object.keys(tenantSettings.menuItems);
  let accountAdminAccess = currentPage.split("/")[0] === "account";
  let accountAdmin =
    accountAdminAccess &&
    userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR";
  return (
    (!accountAdminAccess && !pages.includes(currentPage)) ||
    tenantSettings.menuItems[currentPage] ||
    accountAdmin
  );
};

const AccessControl = (props) => {
  const {
    currentUserPermissions,
    allowedPermissions,
    deniedPermissions,
    currentPage,
    tenantSettings,
    children,
    renderNoAccess,
    userAccountPermission,
  } = props;
  const permitted = checkPermissions(
    currentUserPermissions,
    allowedPermissions,
    deniedPermissions,
    userAccountPermission
  );
  const pageShow = checkRestrictedPages(
    currentPage,
    tenantSettings,
    userAccountPermission
  );
  if (permitted && pageShow) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  deniedPermissions: [],
  currentUserPermissions: [],
  renderNoAccess: () => null,
};

export function mapStateToProps(state) {
  return {
    tenantSettings: state.settings.tenantSettings,
    currentUserPermissions: state.users.currentUserPermissions,
    userAccountPermission: state.users.userAccountPermission,
  };
}

// Compose AccessControl component with redux
export default connect(mapStateToProps, null)(AccessControl);
