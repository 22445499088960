import * as types from "./ClinicalInformationAction";

//reducer for shared actions
export const allergyList = (state = [], action) => {
  switch (action.type) {
    case types.POST_ALLERGY_REQUEST:
      return {
        ...state,
        allergyListLoading: true,
      };
    case types.GET_ALLERGY_REQUEST:
      return {
        ...state,
        allergyListLoading: true,
      };

    case types.GET_ALLERGY_SUCCESS:
      return {
        ...state,
        allergyList: action.payload,
        allergyListLoading: false,
      };
    case types.POST_ALLERGY_DRFIRST_REQUEST:
      return {
        ...state,
        allergyListLoading: true,
      };
    case types.POST_ALLERGY_DRFIRST_SUCCESS:
      return {
        ...state,
        allergyListLoading: false,
      };

    default:
      return state;
  }
};

export const conditionList = (state = [], action) => {
  switch (action.type) {
    case types.POST_CONDITION_REQUEST:
      return {
        ...state,
        conditionListLoading: true,
      };
    case types.GET_CONDITION_REQUEST:
      return {
        ...state,
        conditionListLoading: true,
      };

    case types.GET_CONDITION_SUCCESS:
      return {
        ...state,
        conditionList: action.payload,
        conditionListLoading: false,
      };
    case types.POST_CONDITION_DRFIRST_REQUEST:
      return {
        ...state,
        conditionListLoading: true,
      };
    case types.POST_CONDITION_DRFIRST_SUCCESS:
      return {
        ...state,
        conditionListLoading: false,
      };
    default:
      return state;
  }
};

export const medicationList = (state = [], action) => {
  switch (action.type) {
    case types.POST_MEDICATION_REQUEST:
      return {
        ...state,
        medicationListLoading: true,
      };
    case types.GET_MEDICATION_REQUEST:
      return {
        ...state,
        medicationListLoading: true,
      };

    case types.GET_MEDICATION_SUCCESS:
      return {
        ...state,
        medicationList: action.payload,
        medicationListLoading: false,
      };
    case types.POST_MEDICATION_DRFIRST_REQUEST:
      return {
        ...state,
        medicationListLoading: true,
      };
    case types.POST_MEDICATION_DRFIRST_SUCCESS:
      return {
        ...state,
        medicationListLoading: false,
      };
    default:
      return state;
  }
};

export const prescriptionList = (state = [], action) => {
  switch (action.type) {
    case types.GET_PRESCRIPTION_REQUEST:
      return {
        ...state,
        prescriptionListLoading: true,
      };

    case types.GET_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        prescriptionList: action.payload,
        prescriptionListLoading: false,
      };

    default:
      return state;
  }
};

export const immunizationList = (state = [], action) => {
  switch (action.type) {
    case types.GET_IMMUNIZATION_REQUEST:
      return {
        ...state,
        immunizationListLoading: true,
      };

    case types.GET_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        immunizationList: action.payload,
        immunizationListLoading: false,
      };

    default:
      return state;
  }
};

export const vitalList = (state = [], action) => {
  switch (action.type) {
    case types.GET_VITAL_REQUEST:
      return {
        ...state,
        vitalListLoading: true,
      };

    case types.GET_VITAL_SUCCESS:
      return {
        ...state,
        vitalList: action.payload,
        vitalListLoading: false,
      };

    default:
      return state;
  }
};

export const procedureList = (state = [], action) => {
  switch (action.type) {
    case types.GET_PROCEDURE_REQUEST:
      return {
        ...state,
        procedureListLoading: true,
      };

    case types.GET_PROCEDURE_SUCCESS:
      return {
        ...state,
        procedureList: action.payload,
        procedureListLoading: false,
      };

    default:
      return state;
  }
};

export const accessToken = (state = [], action) => {
  switch (action.type) {
    case types.GET_ACCESSTOKEN_REQUEST:
      return {
        ...state,
        accessTokenLoading: true,
      };

    case types.GET_ACCESSTOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload,
        accessTokenLoading: false,
      };

    default:
      return state;
  }
};

export const ccdViewer = (state = [], action) => {
  switch (action.type) {
    case types.VIEW_CCD_REQUEST:
      return {
        ...state,
        ccdViewLoading: true,
      };

    case types.VIEW_CCD_SUCCESS:
      return {
        ...state,
        ccdView: action.payload,
        ccdViewLoading: false,
      };

    default:
      return state;
  }
};

export const ePrescribe = (state = [], action) => {
  switch (action.type) {
    case types.POST_PATIENT_REQUEST:
      return {
        ...state,
        ePrescribeLoading: true,
      };

    case types.POST_PATIENT_SUCCESS:
      return {
        ...state,
        ePrescribe: action.payload,
        ePrescribeLoading: false,
      };
    default:
      return state;
  }
};
