import * as types from "./OrdersActions";

//reducer for shared actions
export const orders = (state = [], action) => {
  switch (action.type) {
    case types.GET_TENANT_LAB_REQUEST:
      return { ...state, tenantLabOrdersLoading: true };

    case types.GET_TENANT_LAB_SUCCESS:
      return {
        ...state,
        tenantLabOrders: action.payload,
        tenantLabOrdersLoading: false,
      };

    case types.GET_LAB_REQUEST:
      return { ...state, labOrdersLoading: true };

    case types.GET_LAB_SUCCESS:
      return {
        ...state,
        labOrders: action.payload,
        labOrdersLoading: false,
      };

    case types.UPDATE_LAB_SUCCESS: {
      const updatedLab = state.labOrders.items.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return { ...state, labOrders: { ...state.labOrders, items: updatedLab } };
    }

    case types.CREATE_LAB_REQUEST:
      return { ...state, createOrderLoading: true };

    case types.CREATE_LAB_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          total: state[action.payload.type].total + 1,
          items: [action.payload.data, ...state[action.payload.type].items],
        },
        createOrderLoading: false,
      };

    case types.GET_USER_SCAN_REQUEST:
      return { ...state, scanHistoryLoading: true };

    case types.GET_USER_SCAN_SUCCESS:
      return {
        ...state,
        scanHistory: action.payload,
        scanHistoryLoading: false,
      };

    case types.RENEW_TEST_REQUEST:
      return { ...state, renewTestLoading: true };

    case types.GET_MASTER_LAB_TEST_REQUEST:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          loading: true,
          error: null,
        },
      };

    case types.GET_MASTER_LAB_TEST_SUCCESS:
      return {
        ...state,
        [action.testType]: {
          list: action.payload,
          loading: false,
        },
      };

    case types.GET_MASTER_LAB_TEST_FAILURE:
      return {
        ...state,
        [action.testType]: {
          ...state[action.testType],
          error: action.error,
          loading: false,
        },
      };
    case types.FIND_PROVIDERS_REQUEST:
      return {
        ...state,
        providers: {
          loading: true,
          error: null,
        },
      };

    case types.FIND_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: {
          loading: false,
          list: action.payload,
        },
      };

    case types.FIND_PROVIDERS_FAILURE:
      return {
        ...state,
        providers: {
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
