import * as types from "./VirtualConsultActions";

//reducer for shared actions
export const virtualConsult = (state = [], action) => {
  switch (action.type) {
    case types.GET_QUESTIONNAIRES_REQUEST:
      return {
        ...state,
        loadingQuestionnaires: false,
      };

    case types.GET_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        questionnaires: action.payload,
        loadingQuestionnaires: false,
      };

    case types.GET_QUESTIONNAIRES_FAILURE:
      return {
        ...state,
        questionnaires: action.payload,
        loadingQuestionnaires: false,
      };

    case types.GET_SEGMENTS_REQUEST:
      return {
        ...state,
        loadingSegments: false,
      };

    case types.GET_SEGMENTS_SUCCESS:
      return {
        ...state,
        segments: action.payload,
        loadingSegments: false,
      };

    case types.GET_SEGMENTS_FAILURE:
      return {
        ...state,
        segments: action.payload,
        loadingSegments: false,
      };

    default:
      return state;
  }
};
