import React from "react";
import { connect } from "react-redux";

//component and styling imports
import NavBarStyles from "../../assets/jss/components/NavBarStyles";
import DropdownBox from "./DropdownBox";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Avatar from "@material-ui/core/Avatar";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const DesktopMenu = (props) => {
  const {
    classes,
    user,
    logout,
    currentTenant,
    profilePicture,
    userAccountPermission,
  } = props;
  let fullName = user ? user.firstName + " " + user.lastName : "";

  return (
    <PopupState variant="popover" popupId="dropdown-menu">
      {(popupState) => (
        <div style={{ margin: "auto" }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
          >
            <Avatar
              alt="Profile Picture"
              src={profilePicture && profilePicture.src}
              className={classes.avatar}
            />
            {fullName}
            <ArrowDropDownRoundedIcon
              className={classes.arrowIcon}
              fontSize="small"
            />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            disableScrollLock={true}
          >
            <DropdownBox
              logout={logout}
              user={user}
              popupState={popupState}
              currentTenant={currentTenant}
              userAccountPermission={userAccountPermission}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export function mapStateToProps(state) {
  return {
    profilePicture: state.users.profilePicture,
    userAccountPermission: state.users.userAccountPermission,
  };
}

export default connect(mapStateToProps)(withStyles(NavBarStyles)(DesktopMenu));
