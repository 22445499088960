import React from "react";

//material-UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import NotFoundViewStyles from "../assets/jss/components/NotFoundViewStyles";
import history from "../components/Shared/Redux/history";
import Button from "@material-ui/core/Button";

export const NotFoundView = (props) => {
  const { classes, currentTenant, message } = props;

  return (
    <Grid container justify={"center"}>
      <Grid item className={classes.cardContainer}>
        <Typography variant="h1" className={classes.errorCode}>
          404
        </Typography>

        <Typography variant="h3" className={classes.errorHeader}>
          Oops!
        </Typography>

        <Typography variant="h3" className={classes.errorDescription}>
          {message || "We can’t seem to find the page you are looking for."}
        </Typography>

        {currentTenant && currentTenant.id ? (
          <Button
            color={"primary"}
            variant="contained"
            style={{ marginTop: "55px", borderRadius: "5px" }}
            onClick={() => history.push(`/${currentTenant.id}`)}
          >
            Go Home
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(NotFoundViewStyles)(NotFoundView);
