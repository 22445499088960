import React, { useState } from "react";

//component and styling imports
import NavBarStyles from "../../assets/jss/components/NavBarStyles";
import SidebarList from "../Sidebar/SidebarList";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuRounded from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const MobileMenu = (props) => {
  const [open, handleOpen] = useState(false);
  const {
    classes,
    pages,
    logout,
    currentTenant,
    handleChangeTenant,
    allTenantsLoading,
    allTenants,
  } = props;

  return (
    <div>
      <IconButton
        color="inherit"
        className={classes.link}
        onClick={() => handleOpen(true)}
      >
        <MenuRounded fontSize="large" />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => handleOpen(true)}
        onClose={() => handleOpen(false)}
      >
        <SidebarList
          type={"mobile"}
          onMouseLeave={handleOpen}
          currentTenant={currentTenant}
          handleChangeTenant={handleChangeTenant}
          allTenantsLoading={allTenantsLoading}
          allTenants={allTenants}
          logout={logout}
          pages={pages}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default withStyles(NavBarStyles)(MobileMenu);
